<template>
<div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-striped custom-table mb-0 datatable" id="taskreports">
                  <thead>
                    <tr>  
                      <th>Employee</th>
                      <th>Client Name</th>
                      <th>Task Name</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Total Day</th>
                      <th>Total Hours</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in GetScheduleData" :key="item.id">
                      <td  >{{ item.FirstName +" "+item.LastName }} </td>
                      <td>{{clientGetir( item.clientID) }}</td>
                      <td>{{DesignationsGetir(item.DesignationID) }}</td>
                      <td>{{ item.startDate.toDate()
                            | moment("DD/MM/YYYY") }}</td>
                      <td>{{ item.endDate.toDate()
                            | moment("DD/MM/YYYY") }}</td>
                      <td>{{ item.isGunu }}</td>
                      <td>{{ Math.ceil(item.totalHours)  }}</td>
                    
                      
                    </tr>
                 
                  </tbody>
                </table>
              </div>
            </div>
          </div>
</template>
<script>
// import dailyreporttable from '../../../../assets/json/dailyreporttable.json';
// import util from '../../../../assets/utils/util'
/* eslint-disable */  
import jsPDF from 'jspdf' 
import autoTable from 'jspdf-autotable'
import { mapActions,mapGetters } from "vuex";
  export default {
    data() {
    return {
       
      
    }
  },
    components: {
      jsPDF,
      autoTable
    },
    
    methods: {
    ...mapActions(["fetchdata"]),  
    DesignationsGetir(g) {
      try {
    
        return this.$store.getters.GetDesignation.find((m) => m.id == g)
          .DesignationName;
      } catch (error) {
        return g;
     
      }
  
    },
    clientGetir(e){
try{
return this.$store.getters.Getclient.find((a)=>a.id==e).ClientName;

}
catch(error){
  return e;
}
    },


  },
    computed:
    {
    ...mapGetters(["GetCompany","GetDailySchedule","Getclient"]),
GetScheduleData(){
return this.GetDailySchedule
},
GetComp(){
return this.GetCompany
},
GetClientData(){
return this.Getclient
},
},
    
    name: 'activities'
  }
</script>