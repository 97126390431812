<template>
  <div class="goaltracking">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
      <!-- Page Wrapper -->
      <div class="page-wrapper">
      
        <!-- Page Content -->
        <div class="content container-fluid">
        
        <headertracking />
          
        <trackingcontent />

        </div>
        <!-- /Page Content -->

        <modaltracking />
      
        </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
   
  export default {
    components: {
   
    },
    mounted() {

    },
    name: 'goaltracking'
  }
</script>