<template>
  <div>
    <!-- <sidebar> -->
    <div class="sidebar" id="sidebar">
      <div class="sidebar-inner" id="style-15">
        <perfect-scrollbar class="scroll-area" :settings="settings" @ps-scroll-y="scrollHanle">
          <div id="sidebar-menu" class="sidebar-menu">
            <ul>
              <li class="menu-title">
                <span>{{ $t("Main") }} </span>
              </li>
              <li class="submenu" v-if="sayfaYetki('employee-dashboard')">
                <router-link to="/employee-dashboard">
                  <a href="#"><i class="la la-dashboard"></i> <span> {{ $t("Dashboard") }}</span></a>
                </router-link>

              </li>
              <li class="submenu" v-if="sayfaYetki('user-dashboard')">
                <router-link to="/user-dashboard">
                  <a href="#"><i class="la la-briefcase"></i> <span> {{ $t("Jobs") }}</span></a>
                </router-link>

              </li>

              <li class="menu-title" v-if="sayfaYetki('employees')">
                <span>{{ $t("Employees") }}</span>
              </li>
              <li class="submenu" v-if="sayfaYetki('employees')">
                <a href="#"
                   class="active"><i
                    class="la la-user"></i> <span> {{ $t("Employees") }}</span> <span class="menu-arrow"></span></a>
                <ul style="display: none;">
                  <li>
                    <router-link v-bind:class="{ 'active': currentPath == 'employees-list' }" to="/employees">{{
                      $t("All Employees")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="/holidays">{{ $t("Holidays") }}</router-link>
                  </li>
                  <li>
                    <router-link to="/leaves">{{ $t("Leaves") }}</router-link>
                  </li>
                  <!-- <li><router-link to="/leaves-employee">Leaves (Employee)</router-link></li> -->
                  <!-- <li><router-link to="/leave-settings">Leave Settings</router-link></li> -->
                  <!-- <li>
                    <router-link to="/attendance">{{ $t("Attendance") }}</router-link>
                  </li> -->
                  <!-- <li><router-link to="/attendance-employee">Attendance (Employee)</router-link></li> -->
                  <li>
                    <router-link to="/departments">{{ $t("Departments") }}</router-link>
                  </li>
                  <li>
                    <router-link to="/designations">{{ $t("Designations") }}</router-link>
                  </li>
                  <!-- <li>
                    <router-link to="/timesheet">Timesheet</router-link>
                  </li> -->
                  <li>
                    <router-link to="/shift-scheduling">{{ $t("Shift&Schedule") }} </router-link>
                  </li>
                  <!-- <li>
                    <router-link to="/overtime">{{ $t("Overtime") }} </router-link>
                  </li> -->
                </ul>
              </li>
              <li v-if="sayfaYetki('clients')">
                <router-link to="/clients" v-bind:class="{ 'active': currentPath == 'clients-list' }"><i
                    class="la la-users"></i> <span>{{ $t("Clients") }}</span></router-link>
              </li>

              <!-- <li class="menu-title" v-if="$store.getters.GetAuthPage['pages'].indexOf('clients')!=-1">
                <span>HR</span>
              </li> -->

              <li class="submenu" v-if="sayfaYetki('employee-reports')">
                <a href="#"><i class="la la-pie-chart"></i> <span> {{ $t("Reports") }} </span> <span
                    class="menu-arrow"></span></a>
                <ul style="display: none;">
                  <!-- <li>
                    <router-link to="/daily-reports">{{ $t("Daily Report") }} </router-link>
                  </li>
                  <li>
                    <router-link to="/employee-reports"> {{ $t("Employee Report") }} </router-link>
                  </li> -->
                  <li>
                    <router-link to="/client-reports"> {{ $t("Client Report") }} </router-link>
                  </li>
                  <li>
                    <router-link to="/leave-reports"> {{ $t("Leave Report") }} </router-link>
                  </li>
                  <li>
                    <router-link to="/task-reports"> {{ $t("Task Report") }} </router-link>
                  </li>  
                  <!-- <li><router-link to="/payslip-reports"> Payslip Report </router-link></li>
                 
                  <li>
                    <router-link to="/attendance-reports"> {{ $t("Attendance Report") }} </router-link> 
                  </li> -->
                 
                </ul>
              </li>
              <li class="menu-title" v-if="sayfaYetki('training')">
                <span> {{ $t("Employee Tracking") }}</span>
              </li>

              <li class="submenu" v-if="sayfaYetki('training')">
                <a href="#"><i class="la la-edit"></i> <span> {{ $t("Training") }} </span> <span class="menu-arrow"></span></a>
                <ul style="display: none;">
                  <li>
                    <router-link to="/training">{{ $t("Training List") }} </router-link>
                  </li>
                  <li>
                    <router-link to="/trainers"> {{ $t("Trainers") }} </router-link>
                  </li>
                  <li>
                    <router-link to="/training-type">{{ $t("Training Type") }} </router-link>
                  </li>
                </ul>
              </li>

            </ul>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
    <!-- </sidebar> -->
  </div>
</template>
<script>
import store from "@/store/index";
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import 'bootstrap/dist/css/bootstrap.css'
export default {
  
  components: {
    PerfectScrollbar,
  },
  mounted() {
    $('#sidebar-menu a').on('click', function (e) {
        if ($(this).parent().hasClass('submenu')) {
          e.preventDefault();
        }
        if (!$(this).hasClass('subdrop')) {
          $('ul', $(this).parents('ul:first')).slideUp(350);
          $('a', $(this).parents('ul:first')).removeClass('subdrop');
          $(this).next('ul').slideDown(350);
          $(this).addClass('subdrop');
        } else if ($(this).hasClass('subdrop')) {
          $(this).removeClass('subdrop');
          $(this).next('ul').slideUp(350);
        }
      });
      $('#sidebar-menu ul li.submenu a.active').parents('li:last').children('a:first').addClass('active').trigger('click');
   

    //this.uid=JSON.parse(localStorage.getItem("storedData")).CompanyUid


  },


  computed: {
    currentPath() {
      return this.$route.name;
    }


  },
  data() {
    return {
      settings: {
        suppressScrollX: true,
      },
      activeClass: 'active',
      uid: "",
    };
    //  isactive : true
  },
  methods: {
    scrollHanle(evt) {

    },

    sayfaYetki(adres) {
      if (this.$store.getters.GetAuthPage['pages']) {

        if (this.$store.getters.GetAuthPage['pages'].indexOf(adres) != -1) return true
      }

      return false
      //  return true

    }
  },

}

</script>
<style>
.scroll-area {
  position: relative;
  margin: auto;
  height: calc(100vh - 60px);
  background-color: transparent !important;
}

.sidebar-menu li a.active {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
}
</style>
