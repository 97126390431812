<template>
  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table table-striped custom-table mb-0 datatable" id="traintable">
          <thead>
            <tr>
              <th style="width: 30px">#</th>
              <th>{{$t("Type")}}</th>
              <th>{{$t("Description")}}</th>
              <th>{{$t("Status")}}</th>
              <th class="text-end">{{$t("Action")}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in GetTrainingType" :key="item.id">
              <td>{{ index + 1 }}</td>
              <td>{{ item.Type }}</td>
              <td>{{ item.Description }}</td>
              <td>
                <div class="dropdown action-label">
                  <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <i class="fa fa-dot-circle-o text-danger"></i>
                    {{ item.Status }}
                  </a>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-success"></i> {{$t("Active")}}</a>
                    <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-danger"></i>
                      {{$t("Inactive")}}</a>
                  </div>
                </div>
              </td>
              <td class="text-end">
                <div class="dropdown dropdown-action">
                  <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i
                      class="material-icons">more_vert</i></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="#" @click="editTrainingType(item)" data-bs-toggle="modal"
                      data-bs-target="#edit_type"><i class="fa fa-pencil m-r-5"></i> {{$t("Edit")}}</a>
                    <a class="dropdown-item" href="#" @click="$emit('deleteTrainingType',item)" data-bs-toggle="modal"
                      data-bs-target="#delete_type"><i class="fa fa-trash-o m-r-5"></i> {{$t("Delete")}}</a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import traintable from "../../../../assets/json/traintable.json";
import util from "../../../../assets/utils/util";
import Vue from "vue";
/* eslint-disable */
export default {

  data() {
    return {

      trainingType: [],

    };
  },
  components: {},

  methods: {
    ...mapActions(["AddData", "EditData", "DeleteData", "getTrainingType", "fetchdata"]),
    fetchdataa() {
      this.fetchdata({ db: "TrainingType", mt: "SetTrainingType" }).then(
        (res) => {
          this.$emit("trType", this.trainingType);
        }
      );
    },
    editTrainingType(d) {
      this.$emit("EditData", d);
    },

  },
  computed: {
    ...mapGetters(["GetTrainingType"]),
    GetTr() {
      return this.GetTrainingType;
    },
  },
  mounted() {
    if (this.GetTrainingType.length == 0) { this.fetchdata(); }
    this.fetchdataa();
  },
};
</script>