<template>
  <div class="employeeslist">



    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
      <!-- Page Wrapper -->
      <div class="page-wrapper">

        <!-- Page Content -->
        <div class="content container-fluid">

          <employeeheader />

          <employeefilter @userFilter="userFilter" />

          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">


                <employeelistcontent @user="GetUser"
                  :personelData="(userFilterData.length == 0 ? GetEmployee : userFilterData)" />
              </div>
            </div>
          </div>
        </div>
        <!-- /Page Content -->

        <employeemodal :userdata="userdata" />

      </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {


  data() {
    return {
      userdata: {},
      userFilterData: []
    }
  },
  components: {

  },
  methods: {

    userFilter(value) {
      this.userFilterData = value;

    },
    GetUser(value) {
      console.log("GETUSER", value)
      this.userdata = value
    },



  },

  computed: {
    ...mapGetters(["GetEmployes"]),
    GetEmployee() {

      return this.GetEmployes
    }

  },
  mounted() {

  },
  name: 'employeeslist'
}
</script>