 <template>


   
 



 <li class="nav-item dropdown has-arrow main-drop">
            <a href="#" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
              <span class="user-img me-1"><img :src="GetCompany[0].Picture"  alt="">
              <span class="status online"></span></span>
              <span class="adminclass">{{ GetCompany[0].CompanyName}}</span>
            </a>
            <div class="dropdown-menu">
               <!-- <router-link class="dropdown-item" to="/profile">My Profile</router-link>   -->
              <router-link class="dropdown-item" to="/settings">{{$t("Settings")}}</router-link>
              <a class="dropdown-item" @click="Logout()">{{$t("Logout")}}</a>
              
               
            </div>
   </li> 

</template>
<script>
import {mapGetters,mapActions} from 'vuex'
export default {

         methods:{
        ...mapActions(["fetchdata","EditData","Tanimlamalar","sorgu"]),
        fetchdat(){

        },
        Logout(){
     
          localStorage.removeItem("storedData")
          
           
          this.$router.push({ name: 'login', query: { redirect: '/path' } });  
        } 
    },
    computed:{
        ...mapGetters(["GetCompany"]),

    },
        mounted(){
        const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
    
        //await  this.GetComp(){ query: ["CompanyUid", "==", uid] }
     //this.Tanimlamalar()
     //await this.Tanimlamalar()
    }
}
</script>


<style scoped>
.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}
</style>