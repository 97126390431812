<template>
<div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-striped custom-table mb-0 datatable" id="maintrtable">
                  <thead>
                    <tr>
                    
                      <th style="width: 30px;">#</th>
                      <th>{{$t("Training Type")}}</th>
                      <th>{{$t("Trainer")}}</th>
                      <th>{{$t("Employee")}}</th>
                      <th>{{$t("Time Duration")}}</th>
                      <th>{{$t("Description")}} </th>
                      <th>{{$t("Cost")}} </th>
                      <th>{{$t("Status")}} </th>
                      <th class="text-end">{{$t("Action")}}</th>
                    </tr>
                  </thead>
                  <tbody> 
                    <tr v-for="(item,index) in GetTraining" :key="item.id">
                      <td>{{index+1}}</td>
                      <td>{{item.TrainingType}}</td>
                      <td>
                        <h2 class="table-avatar">
                          <router-link to="/profile" class="avatar"><img alt="" src="../../../../assets/img/profiles/avatar-02.jpg"></router-link>
                          <router-link to="/profile">{{item.Trainer}} </router-link>
                        </h2>
                      </td>
                      <td>
                        <ul class="team-members">
                          <li>
                            <a href="#" title="" data-bs-toggle="tooltip"><img alt="" src="../../../../assets/img/profiles/avatar-10.jpg"></a>
                          </li>
                        </ul>
                      </td>
                    
                      <td>{{item.StartDate}} - {{item.EndDate}}</td>
                      <td>{{item.Description}}</td> 
                      <td>{{item.TrainingCost}}</td>
                      <td>
                        <div class="dropdown action-label">
                          <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-dot-circle-o text-success"></i>{{$t(" Active")}}
                          </a>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-success"></i> {{$t("Active")}}</a>
                            <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-danger"></i> {{$t("Inactive")}}</a>
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_training"><i class="fa fa-pencil m-r-5"></i> {{$t("Edit")}}</a>
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_training"><i class="fa fa-trash-o m-r-5"></i> {{$t("Delete")}}</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
</template>
<script>
import util from '../../../../assets/utils/util'
import {mapActions,mapGetters} from "vuex";
import Select2 from 'v-select2-component';
import Vue from "vue";
   /* eslint-disable */
  export default {
    data() {
    return {
    training:[],
    classList:{},
    }
    },
    components: {
      Select2,
    },
    methods:{
...mapActions(["AddData", "EditData", "DeleteData","fetchdata","GetTrainingData"]),
fetchdataa()
{
 
this.fetchdata({ db: "Training", mt: "SetTraining" }).then(
        (res) => {
          this.$emit("trainingList", this.training);
        }
      )
},
},
computed:{
  ...mapGetters(["GetTraining"]),
  getTrainingData()
  {
return this.GetTraining;
 
  },
},
     
    mounted() {

      if(this.GetTrainingData.length==0){this.fetchdata();}
      this.fetchdataa();
     
    },
    name: 'activities'
  }
</script>