<template>
  <div class="row staff-grid-row">

    <div class="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3" v-for="item in personelData" :key="item.id">
      <div class="profile-widget">

        <div class="profile-img">
          <router-link :to="{ name: 'profile', params: { data: item.id } }" class="avatar">
            <img class="avatar" :src="item.picture" alt="" />

          </router-link>


        </div>
        <div class="dropdown profile-action">
          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i
              class="material-icons">more_vert</i></a>
          <div class="dropdown-menu dropdown-menu-right">
            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_employee"
              @click="$emit('user', item)"><i class="fa fa-pencil m-r-5"></i>{{ $t("Edit") }} </a>
            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_employee"
              @click="$emit('user', item)"><i class="fa fa-trash-o m-r-5"></i>{{ $t("Delete") }} </a>
          </div>
        </div>
        <h4 class="user-name m-t-10 mb-0 text-ellipsis">
          <router-link to="/profile">{{ item.FirstName }} {{ item.LastName }}</router-link>
        </h4>
        <div  class="small text-danger"><h4>{{ DepartmentName(item) }}</h4></div>
      </div>
    </div>
  </div>
</template>
<script>
import employee from '../../../../assets/json/employee.json';
/* eslint-disable */
const images = require.context('../../../../assets/img/profiles', false, /\.png$|\.jpg$/)

import { mapActions, mapGetters } from 'vuex'
export default {
  props: ["personelData"],
  data() {
    return {

      items: [],
      personelDepartment: []
    }
  },

  methods: {
    ...mapActions(["sorguDoc"]),


     DepartmentName(item) {

     let tempitem={...item}

      if(tempitem.DepartmentID){
      
        try {
          tempitem["Department"]=this.GetDepartment.find(t=>t.id==tempitem.DepartmentID[0].id)["DepartmentName"]
        } catch (error) {
         // item["Department"]=""
        }
       
       
     
 
      }

      return  tempitem["Department"]
   

     

    }

  },
  computed: {

    ...mapGetters(["GetDepartment"])


  },
  mounted() {

    // try {


    //   this.personelDepartment = [...this.personelData]
    // for (var item of this.personelDepartment) {
    //   if (item.DepartmentID) {
    //     try {

    //       console.log("PERSONEL DEPARMAN", item)
    //     } catch (error) {

    //     }


    //   }




    //}
    //  item=  this.sorguDoc(this.personelData[0].DepartmentID[0])
    //  console.log("PERSONEL DEPARMAN",item)
    // } catch (error) {

    // }


  }


}
</script>