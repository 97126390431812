<template>
  <div class="leaves">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
      <!-- Page Wrapper -->
      <div class="page-wrapper">

        <!-- Page Content -->
        <div class="content container-fluid">

          <headerleave @editLeave="editLeaves" />

          <leavewidget />

          <leavefilter />

          <leavecontent @editLeave="editLeaves" />

        </div>
        <!-- /Page Content -->

        <modalleave :editLeave="editLeave" />

      </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {

  data() {
    return {
      // Leave: null,
      editLeave: {},
      // deleteLeave: null

    }
  },
  components: {

  },
  methods: {
    ...mapActions(["tumPersonel", "leavesGetir"]),
    editLeaves(e) {
      this.editLeave = e
      
      var start = document.getElementById('select3');
      var end = document.getElementById('select4');

      start.addEventListener('change', function () {
        if (start.value)
          end.min = start.value;
      }, false);
      end.addEventLiseter('change', function () {
        if (end.value)
          start.max = end.value;
      }, false);


    }

  },
  mounted() {
    this.tumPersonel();
   // this.leavesGetir();
  },

  name: 'leaves'
}
</script>