<template>
  <div class="attendance">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>

      <!-- Page Wrapper -->
      <div class="page-wrapper">
        <div class="content container-fluid">
          <attendanceheader />
          <attendancefilter @seciliGun="gun" />
          <div class="row">
            <div class="col-lg-12">
              <div class="table-responsive">
                <table class="table table-striped custom-table table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th>{{ $t("Personel") }}</th>
                      <th v-for="item in gunler.Day" :key="item">{{ item }}</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in GetAttendance" :key="item.id">
                      <td>
                        <h2 class="table-avatar">
                          {{ item.Personel }}
                        </h2>
                      </td>
                      <td v-for="gun in gunler.Day" :key="gun"><a href="javascript:void(0);" data-bs-toggle="modal"
                          data-bs-target="#attendance_info"><i
                            v-if="item.Day.indexOf(gun.toString()) != -1 && item.Year == gunler.selectedYear && item.Month == gunler.selectedMonth"
                            class="fa fa-check text-success"></i> <i v-else class="fa fa-check text-danger"></i></a>
                      </td>
                      <!-- <td>
                        <h2 class="table-avatar">
                          <router-link class="avatar avatar-xs" to="/profile"><img alt="" :src="loadImg(item.image)">
                          </router-link>
                          <router-link to="/profile">{{ item.name }}</router-link>
                        </h2>
                      </td>
                      <td><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#attendance_info"><i
                            class="fa fa-check text-success"></i></a></td>
                      <td><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#attendance_info"><i
                            class="fa fa-check text-success"></i></a></td>
                      <td><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#attendance_info"><i
                            class="fa fa-check text-success"></i></a></td>
                      <td><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#attendance_info"><i
                            class="fa fa-check text-success"></i></a></td>
                      <td><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#attendance_info"><i
                            class="fa fa-check text-success"></i></a></td>
                      <td><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#attendance_info"><i
                            class="fa fa-check text-success"></i></a></td>
                      <td>
                        <div class="half-day">
                          <span class="first-off"><a href="javascript:void(0);" data-bs-toggle="modal"
                              data-bs-target="#attendance_info"><i class="fa fa-check text-success"></i></a></span>
                        </div>
                      </td>
                      <td><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#attendance_info"><i
                            class="fa fa-check text-success"></i></a></td>
                      <td><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#attendance_info"><i
                            class="fa fa-check text-success"></i></a></td>
                      <td><i class="fa fa-close text-danger"></i> </td>
                      <td><i class="fa fa-close text-danger"></i> </td>
                      <td><i class="fa fa-close text-danger"></i> </td>
                      <td><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#attendance_info"><i
                            class="fa fa-check text-success"></i></a></td>
                      <td><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#attendance_info"><i
                            class="fa fa-check text-success"></i></a></td>
                      <td><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#attendance_info"><i
                            class="fa fa-check text-success"></i></a></td>
                      <td><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#attendance_info"><i
                            class="fa fa-check text-success"></i></a></td>
                      <td><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#attendance_info"><i
                            class="fa fa-check text-success"></i></a></td>
                      <td><i class="fa fa-close text-danger"></i> </td>
                      <td><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#attendance_info"><i
                            class="fa fa-check text-success"></i></a></td>
                      <td><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#attendance_info"><i
                            class="fa fa-check text-success"></i></a></td>
                      <td>
                        <div class="half-day">
                          <span class="first-off"><a href="javascript:void(0);" data-bs-toggle="modal"
                              data-bs-target="#attendance_info"><i class="fa fa-check text-success"></i></a></span>
                        </div>
                      </td>
                      <td><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#attendance_info"><i
                            class="fa fa-check text-success"></i></a></td>
                      <td><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#attendance_info"><i
                            class="fa fa-check text-success"></i></a></td>
                      <td><i class="fa fa-close text-danger"></i> </td>
                      <td><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#attendance_info"><i
                            class="fa fa-check text-success"></i></a></td>
                      <td><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#attendance_info"><i
                            class="fa fa-check text-success"></i></a></td>
                      <td><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#attendance_info"><i
                            class="fa fa-check text-success"></i></a></td>
                      <td><i class="fa fa-close text-danger"></i> </td>
                      <td><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#attendance_info"><i
                            class="fa fa-check text-success"></i></a></td>
                      <td><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#attendance_info"><i
                            class="fa fa-check text-success"></i></a></td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- /Page Content -->
        <attendancemodal />
      </div>
      <!-- Page Wrapper -->
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import attendance from '../../../../assets/json/attendance.json';
/* eslint-disable */
const images = require.context('../../../../assets/img/profiles', false, /\.png$|\.jpg$/)

export default {
  data() {
    return {
      attendance: attendance,
      gunler: 0

    }
  },
  components: {

  },
  computed: {
    ...mapGetters(["GetAttendance"])

  }
  ,
  mounted() {
    this.sorgu({ tablo: "Attendance", guncelle: "SetAttendance" })


    // this.$store.dispatch("GetWeek").then(t=>{
    //   console.log("TARİH",t)
    //   // var myDate=t.split("-")
    //   // console.log("HAFTA", new Date( myDate[2], myDate[1] - 1, myDate[0]))
    // })
  },
  methods: {
    ...mapActions(["sorgu"]),
    gun(e) {
      console.log("GELEN GÜN", e)

      this.gunler = e
    },
    loadImg(imgPath) {
      return images('./' + imgPath).default
    },
  },
  name: 'attendance'
}
</script>