import {
  initializeApp
} from 'firebase/app';

import { getFunctions } from "firebase/functions";

import {
  getFirestore
} from 'firebase/firestore';

import resim from 'firebase/storage'
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBZTKdr7FsuZ0lyqfeDODjgS6z4Qe1PXkk",
  authDomain: "iwmmeschide.firebaseapp.com",
  databaseURL: "https://iwmmeschide-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "iwmmeschide",
  storageBucket: "iwmmeschide.appspot.com",
  messagingSenderId: "129130044035",
  appId: "1:129130044035:web:600af2e090aba768c599b6"
};

// Initialize firebase and then firestore of that instance
export const conf = initializeApp(firebaseConfig);
export const db = getFirestore(conf);
export const storage = resim;
export const firebaseFnk=getFunctions(conf);