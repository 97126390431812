<template>
<div class="row">
            <div class="col-md-6 col-sm-6 col-lg-4 col-xl-4">
              <div class="card dash-widget">
                <div class="card-body">
                  <span class="dash-widget-icon"><i class="fa fa-file-text-o"></i></span>
                  <div class="dash-widget-info">
                    <h3>{{allJobs.length}} / {{ complateJob.length }}</h3>
                    <span @click="$store.commit('SetSearchFilter',{})">{{$t("All Jobs")}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-4 col-xl-4">
              <div class="card dash-widget">
                <div class="card-body">
                  <span class="dash-widget-icon"><i class="fa fa-clipboard"></i></span>
                  <div class="dash-widget-info">
                    <h3>{{ activeJob.length }}</h3>
                    <span >{{$t("Active Job")}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-4 col-xl-4">
              <div class="card dash-widget">
                <div class="card-body">
                  <span class="dash-widget-icon"><i class="fa fa-retweet"></i></span>
                  <div class="dash-widget-info">
                    <h3>{{ pendingJob.length }}</h3>
                    <span>{{$t("Pending Job")}}</span>
                  </div>
                </div>
              </div>
            </div>
       
          </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  methods: {
    ...mapActions(["sorguTable"]),
    
  },
  computed:{
    ...mapGetters(["GetEmployes","GetDailySchedule","GetDepartment","GetDesignation"])

  },
  data () {
    return {
      allJobs:0,
      activeJob:0,
      pendingJob:0,
      complateJob:0
    }
  },
 
  async mounted() {

    const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
   
        await this.$store.dispatch("sorgu", ({ tablo: "Employee", guncelle: "SetEmployee", kosul: [{ query: ["EmploeeID", "==", uid] }] }))       
        await this.$store.dispatch("sorgu", ({ tablo: "DailyScheduling", guncelle: "SetDailyScheduling", kosul: [{ query: ["employeID", "==", this.GetEmployes[0].id] }] }))
        await this.$store.dispatch("sorgu", ({ tablo: "Department", guncelle: "SetDepartment" }))
        await this.$store.dispatch("sorgu", ({ tablo: "Designation", guncelle: "SetDesignation" }))
        await this.$store.dispatch("sorgu", ({ tablo: "Client", guncelle: "SetClient" }))
    

    this.allJobs = this.GetDailySchedule
    this.pendingJob=this.allJobs.filter(t => t["workStart"]===undefined )
    this.activeJob=this.allJobs.filter(t => t["workStart"]!==undefined &&  t["workEnd"]===undefined)
    this.complateJob=this.allJobs.filter(t => t["workStart"]!==undefined && t["workEnd"]!==undefined)

  }
}
</script>