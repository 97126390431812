<template>
  <div>

    <!-- Add Holiday Modal -->
    <div class="modal custom-modal fade" id="add_holiday" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t("Add") }} {{ $t("Holiday") }}</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label>{{ $t("Holiday") }} {{ $t("Name") }} <span class="text-danger">*</span></label>
                <input v-model="holidayData.Title" class="form-control" type="text" />
              </div>
              <div class="form-group" hidden>
                <label>{{ $t("Date") }} <span class="text-danger">*</span></label>
                <input disabled class="form-control" v-model="holidayData.Date" type="text" name="Date" />
              </div>
              <div class="form-group">
                <label>{{ $t("Holiday") }} {{ $t("Start Date") }} <span class="text-danger">*</span></label>
                <input type="date" id="starts" class="form-control" @change="getDifferenceInDays()"
                  v-model="holidayData.startDate" />

              </div>
              <div class="form-group">
                <label>{{ $t("Holiday") }} {{ $t("End Date") }} <span class="text-danger">*</span></label>
                <input type="date" id="ends" class="form-control" @change="getDifferenceInDays()"
                  v-model="holidayData.endDate" />

              </div>

              <div class="custom-control form-check">
                <input type="checkbox" v-model="holidayData.checked" class="form-check-input" id="customCheck1" />
                <label class="form-check-label" for="customCheck1">Recurring Holiday</label>
              </div>
              <div class="submit-section">
                <button @click.prevent="addHoliday" v-if="durum == 0" class="btn btn-primary submit-btn">
                  {{ $t("Submit") }}
                </button>
                <div v-if="durum != 0" class="alert alert-danger alert-dismissible fade show" role="alert">
                  <strong>{{ durum }} iş var</strong>
                  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Holiday Modal -->

    <!-- Edit Holiday Modal -->
    <div class="modal custom-modal fade" id="edit_holiday" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t("Edit") }} {{ $t("Holiday") }}</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">


            <form @submit.prevent="editHoliday">
              <input type="hidden" name="id" v-model="holidayData.id">
              <div class="form-group">
                <label>{{ $t("Holiday") }} {{ $t("Name") }} <span class="text-danger">*</span></label>
                <input class="form-control" v-model="holidayData.Title" type="text" name="Title" />
              </div>
              <div class="form-group" hidden>
                <label>{{ $t("Date") }} <span class="text-danger">*</span></label>
                <input disabled class="form-control" v-model="holidayData.Date" type="text" name="Date" />
              </div>
              <div class="form-group">
                <label>{{ $t("Holiday") }} {{ $t("Start Date") }} <span class="text-danger">*</span></label>
                <input type="date" id="start" class="form-control" @change="getDifferenceInDays()"
                  v-model="holidayData.startDate" />

              </div>
              <div class="form-group">
                <label>{{ $t("Holiday") }} {{ $t("End Date") }} <span class="text-danger">*</span></label>
                <input type="date" id="end" class="form-control" @change="getDifferenceInDays()"
                  v-model="holidayData.endDate" />

              </div>
              <div class="custom-control form-check">
                <input type="checkbox" v-model="holidayData.checked" :v-bind="holidayData.checked"
                  class="form-check-input" id="customCheck1" />
                <label class="form-check-label" for="customCheck1">Recurring Holiday</label>
              </div>
              <div class="submit-section">
                <button v-if="durum == 0" class="btn btn-primary submit-btn">
                  {{ $t("Save") }}
                </button>
                <div v-if="durum != 0" class="alert alert-danger alert-dismissible fade show" role="alert">
                  <strong>{{ durum }} iş var</strong>
                  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Holiday Modal -->

    <!-- Delete Holiday Modal -->
    <div class="modal custom-modal fade" id="delete_holiday" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>{{ $t("Delete") }} {{ $t("Holiday") }}</h3>
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{{ $t("Are you sure want to delete") }}?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6" v-if="durum == 0" @click.prevent="deleteHoliday">
                  <a class="btn btn-primary continue-btn">{{ $t("Delete") }}</a>
                </div>
                <div class="col-6">
                  <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-primary cancel-btn">{{ $t("Cancel")
                  }}</a>
                </div>
                <div v-if="durum != 0" class="alert alert-danger alert-dismissible fade show" role="alert">
                  <strong>{{ durum }} iş var</strong>
                  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Holiday Modal -->
</template>
<script>
import DatePicker from "vue2-datepicker";
import moment from 'moment'
import { mapActions } from "vuex";
const currentDate = new Date();
//const currentDate1 = new Date();
/* eslint-disable */
export default {
  components: {
    DatePicker,
  },
  props: {
    holidays: {
      type: Array,
    },
    editHolidays: {
      type: Object,
    },
    deleteHolidays: {
      type: Object,
    },
  },

  data() {
    return {
      holidayData: {
        CompanyUid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,

      },
      durum: 0


    };
  },

  methods: {
    ...mapActions(["AddData", "EditData", "DeleteData", "sorgu"]),

    addHoliday() {

      console.log("HOLİDAYS", this.holidayData)
      const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
      this.holidayData.startDate = new Date(this.holidayData.startDate);
      this.holidayData.endDate = new Date(this.holidayData.endDate);
      this.holidayData.CompanyUid = uid;


      this.AddData({

        db: "Holidays",
        mt: "SetHolidays",
        veri: this.holidayData,
      }).then(() => {
        this.holidayData = {}

        this.sorgu({ tablo: "Holidays", guncelle: "SetHolidays", kosul: [{ query: ["CompanyUid", "==", uid] }] })

        $(".close").click()

      });

    },
    getDifferenceInDays() {
      if (this.holidayData.startDate && this.holidayData.endDate) {
        this.$store.dispatch("CalisanKontrol", { startDate: this.holidayData.startDate, endDate: this.holidayData.endDate }).then(p => {

          if (p.length > 0) {
            this.durum = p.length;

            console.log("ÇALIŞAN SONUÇ", p.length);
          } else { this.durum = 0; }
        })
        if (this.holidayData.startDate == this.holidayData.endDate) {
          this.holidayData.Date = +1;
        }
        else {
          this.holidayData.Date = Math.abs(new Date(this.holidayData.startDate) - new Date(this.holidayData.endDate)) / (1000 * 60 * 60 * 24);
          // console.log("HOLİDAY",this.holidayData)

        }

      }

    },

    deleteHoliday() {
      console.log(this.deleteHolidays.id);
      this.DeleteData({ db: "Holidays", mt: "DeleteHoli", data: this.deleteHolidays }).then(() => {

        const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
        this.sorgu({ tablo: "Holidays", guncelle: "SetHolidays", kosul: [{ query: ["CompanyUid", "==", uid] }] })

        $(".close").click()

      });
    },
    async editHoliday(e) {

      this.holidayData.startDate = new Date(this.holidayData.startDate);
      this.holidayData.endDate = new Date(this.holidayData.endDate);

      await this.EditData({ db: "Holidays", data: this.holidayData, id: this.editHolidays.id })
      this.holidayData = {}
      const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
      this.sorgu({ tablo: "Holidays", guncelle: "SetHolidays", kosul: [{ query: ["CompanyUid", "==", uid] }] })

      $(".close").click();

    },
  },

  watch: {
    editHolidays(value) {

      this.holidayData = value
      this.holidayData.startDate = moment(value.startDate.toDate()).format('YYYY-MM-DD')
      this.holidayData.endDate = moment(value.endDate.toDate()).format('YYYY-MM-DD')



    },
    holidayData(val) {
      this.holidayData = val
    }

  },
  mounted() { },
};



</script>
 
 