<template><!-- Add Designation Modal -->
  <div>
    <div id="add_designation" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t("Add") }} {{ $t("Designation") }}</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="SendData">
              <div class="form-group">
                <label>{{ $t("Designation Name") }} <span class="text-danger">*</span></label>
                <input v-model="designation.DesignationName" class="form-control" type="text">
              </div>
              <div class="form-group">
                <label>{{ $t("Department") }} <span class="text-danger">*</span></label>
                <Select2 v-model="designation.DepartmentID"
                  :options="$store.getters.GetDepartment.map((t) => { return { 'text': t.DepartmentName, 'id': t.id } })" />
              </div>
              <div class="submit-section">
                <button class="btn btn-primary submit-btn">{{ $t("Submit") }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Designation Modal -->

    <!-- Edit Designation Modal -->
    <div id="edit_designation" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t("Edit") }} {{ $t("Designation") }}</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="EditDat">
              <div class="form-group">
                <label>{{ $t("Designation Name") }} <span class="text-danger">*</span></label>
                <input class="form-control" name="DesignationName" v-model="designation.DesignationName" type="text">
              </div>
              <div class="form-group">
                <label>{{ $t("Department") }} <span class="text-danger">*</span></label>
                <Select2 name="DepartmentID" v-model="designation.DepartmentID"
                  :options="$store.getters.GetDepartment.map((t) => { return { 'text': t.DepartmentName, 'id': t.id } })" />
              </div>
              <div class="submit-section">
                <button class="btn btn-primary submit-btn">{{ $t("Save") }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Designation Modal -->

    <!-- Delete Designation Modal -->
    <div class="modal custom-modal fade" id="delete_designation" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>{{$t("Delete Designation")}}</h3>
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"></button>
              <p>{{$t("Are you sure want to delete")}}?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a href="javascript:void(0);" class="btn btn-primary continue-btn" @click="DelDat">{{ $t("Delete") }}</a>
                </div>
                <div class="col-6">
                  <a  data-bs-dismiss="modal" class="btn btn-primary cancel-btn">{{$t("Cancel")}}</a>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<!-- /Delete Designation Modal --></template>
<script>

import Select2 from 'v-select2-component'
import { mapActions, mapGetters } from 'vuex'
/* eslint-disable */
export default {
  props: ["datas"],
  data() {
    return {
      designation: {
        CompanyUid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
        DepartmentID: "",
        DesignationName: ""
      }
    }
  },
  components: {
    Select2
  },

  watch: {
    datas(value) {

      this.designation = value
    }
  },
  methods: {
    ...mapActions(["sorgu", "AddData", "EditData", "DeleteData","sorguTable","sorguRef"]),

    SendData() {
      const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
      this.AddData({ db: "Designation", mt: "SetDesignation", veri: this.designation }).then(p => {
        $(".close").click();
        
  
        this.designation={}

        this.sorgu({ tablo: "Designation", guncelle: "SetDesignation", kosul: [{ query: ["CompanyUid", "==", uid] }] })
      })
    },
    EditDat(e) {
      const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;

      this.EditData({ db: "Designation", data: this.designation, id: this.designation.id })
      $(".close").click();
      this.designation={}
      this.sorgu({ tablo: "Designation", guncelle: "SetDesignation", kosul: [{ query: ["CompanyUid", "==", uid] }] })

    },
    async DelDat() {
      const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
      // this.DeleteData({ db: "Designation", mt: "DeleteDest", data: this.datas })
      // $(".close").click()
      // this.sorgu({ tablo: "Designation", guncelle: "SetDesignation", kosul: [{ query: ["CompanyUid", "==", uid] }] })



      let DesignationRef = await this.sorguRef({ tablo: "Designation", id: this.datas.id })
      console.log("DesignationRef", DesignationRef)
      let DesignationKontrol = await this.sorguTable({ tablo: "Employee", kosul: [{ query: ["CompanyUid", "==", uid] }, { query: ["DesignationID", "array-contains", DesignationRef] }] })
      console.log("DesignationKontrol", DesignationKontrol)
      if (DesignationKontrol.length == 0) {

        this.DeleteData({ db: "Designation", mt: "DeleteDest", data: this.datas })
        $(".close").click()
        this.sorgu({ tablo: "Designation", guncelle: "SetDesignation", kosul: [{ query: ["CompanyUid", "==", uid] }] })
      }
      else {
        this.$toast.open({
          message: this.$t('Designation kayıtlı personel var'),
          type: 'error',
        });
        $(".close").click()
      }

    }
  },
  computed: {
    ...mapGetters(["GetDesignation"]),
    // GetDepartman() {
    //   let arr = []
    //   this.GetDept.forEach(e => {
    //     arr.push(e.DepartmentName)
    //   })
    //   return [...new Set(arr)]
    // },
  },
  mounted() {

  }
}
</script>