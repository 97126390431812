<template>
  <div class="departments">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
      <!-- Page Wrapper -->
      <div class="page-wrapper">
        <!-- Page Content -->
        <div class="content container-fluid">
          <departmentheader />

          <div class="row">
            <div class="col-md-12">
              <div>
                <departmenttable @EditData="getData" @DeleteData="getDatas" @Departmans="getDepartman" />
              </div>
            </div>
          </div>
        </div>
        <!-- /Page Content -->

        <departmentmodal :editdatas="edtidatas" :deletedatas="deletedatas" :departman="departman" />
      </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions } from 'vuex';
export default {
  name: "departments",
  data() {
    return {
      edtidatas: {},
      deletedatas: {},
      departman: []
    };
  },
  components: {},
  methods: {
    ...mapActions(["sorgu"]),
    getData(e) {
      console.log(e);
      this.edtidatas = e;
    },
    getDatas(d) {
      this.deletedatas = d;
    },
    getDepartman(e) {
      this.departman = e
    }
  },

  mounted() {
    const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
    this.sorgu({tablo:"Department",guncelle:"SetDepartment" , kosul: [{ query: ["CompanyUid", "==", uid] }]})
   


  }
};
</script>