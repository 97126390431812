<template>
  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table table-striped custom-table mb-0 datatable" id="trainerstable">
          <thead>
            <tr>
              <th style="width: 30px;">#</th>
              <th>{{$t("First Name")}} </th>
              <th>{{$t("Last Name")}}</th>
              <th>{{$t("Email")}} </th>
              <th>{{$t("Contact Number")}} </th>
              <th>{{$t("Role")}}</th>
              <th>{{$t("Description")}} </th>
              <th>{{$t("Status")}} </th>
              <th class="text-end">{{$t("Action")}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in GetTrainer" :key="item.id">
              <td>{{ index + 1 }}</td>
              <td>
                <h2 class="table-avatar">
                  <router-link to="/profile" class="avatar"><img alt=""
                      src="../../../../assets/img/profiles/avatar-02.jpg"></router-link>
                  <router-link to="/profile">{{item.FirstName}} </router-link>
                </h2>
              </td>
              <td>{{item.LastName}}</td>
              <td>{{item.Email}}</td>
              <td>{{item.Phone}}</td>
              <td>{{item.Role}}</td>
              <td>{{item.Description}}</td>
              <td>
                <div class="dropdown action-label">
                  <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <i class="fa fa-dot-circle-o text-danger"></i> {{item.Status}}
                  </a>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-success"></i> {{$t("Active")}}</a>
                    <a class="dropdown-item" href="#"><i class="fa fa-dot-circle-o text-danger"></i> {{$t("Inactive")}}</a>
                  </div>
                </div>
              </td>
              <td class="text-end">
                <div class="dropdown dropdown-action">
                  <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i
                      class="material-icons">more_vert</i></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="#" @click="$emit('SendItem',item)" data-bs-toggle="modal"
                      data-bs-target="#edit_type"><i class="fa fa-pencil m-r-5"></i> {{$t("Edit")}}</a>
                    <a class="dropdown-item" href="#" @click="$emit('SendItem',item)" data-bs-toggle="modal"
                      data-bs-target="#delete_type"><i class="fa fa-trash-o m-r-5"></i> {{$t("Delete")}}</a>
                  </div>
                </div>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import util from '../../../../assets/utils/util'
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      trainer: [],
    }
  },
  components: {
  },
  methods: {
    ...mapActions(["AddData", "EditData", "DeleteData", "GetTrainer", "fetchdata"]),

    fetchdataa() {
      this.fetchdata({ db: "Trainer", mt: "SetTrainer" }).then(
        (res) => {
          this.$emit("trType", this.trainer);
        }
      )
    },
    editTrainer(d) {
      this.$emit("EditData", d)
    }

  },
  computed: {
    ...mapGetters(["GetTrainer"]),
    getTrainers() {
      return this.GetTrainer;

    },
  },
  mounted() {
    if (this.GetTrainer.length == 0) { this.fetchdata(); }
    this.fetchdataa();


  },
  name: 'activities'
}
</script>