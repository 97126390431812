<template>
  <div class="row">

    <div id="navbar" class="row">
      <ul class="pagination col-md-1">
        <li class="paginate_button page-item previous" id="promotiontable_previous">
          <a href="javascript:void(0)" @click="nextWeek(-1)" aria-controls="promotiontable" data-dt-idx="0" tabindex="0"
            class="page-link"><i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp; Previous
          </a>
        </li>
      </ul>
      <div class="col-md-10 text-center">
        {{ ayIsimleri($store.getters.GetWeek[0].month) }} /
        {{ $store.getters.GetWeek[0].year }} -
        {{ ayIsimleri($store.getters.GetWeek[6].month) }} /
        {{ $store.getters.GetWeek[6].year }}
      </div>

      <ul class="pagination col-md-1">
        <li class="paginate_button page-item" id="promotiontable_next" style="padding-left: 2em">
          <a href="javascript:void(0)" @click="nextWeek(1)" aria-controls="promotiontable" data-dt-idx="2" tabindex="0"
            class="page-link">Next &nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i></a>
        </li>
      </ul>

      <div class="col-md-12">
        <table id="tableSticky" style="display: none;" class="table table-striped custom-table datatable">
          <thead>
            <tr>
              <th width="20%">{{ $t("Scheduled Shift") }}</th>
              <!-- <th v-for="item in $store.getters.GetWeek" :key="item.day">
                {{ item.day }}
              </th> -->
              <th width="14%">MON {{ $store.getters.GetWeek[0].day }}</th>
              <th width="14%">TUE {{ $store.getters.GetWeek[1].day }}</th>
              <th width="14%">WED {{ $store.getters.GetWeek[2].day }}</th>
              <th width="14%">THU {{ $store.getters.GetWeek[3].day }}</th>
              <th width="14%">FRI {{ $store.getters.GetWeek[4].day }}</th>
              <th width="5%">SAT {{ $store.getters.GetWeek[5].day }}</th>
              <th width="5%">SUN {{ $store.getters.GetWeek[6].day }}</th>

            </tr>
          </thead>
        </table>

      </div>
    </div>
    <div class="col-md-12">
      <div class="table-responsive">


        <table class="table table-striped custom-table datatable" id="scheduletable">
          <thead>
            <tr>
              <th width="20%">{{ $t("Scheduled Shift") }}</th>
              <!-- <th v-for="item in $store.getters.GetWeek" :key="item.day">
                {{ item.day }}
              </th> -->
              <th width="14%">MON {{ $store.getters.GetWeek[0].day }}</th>
              <th width="14%">TUE {{ $store.getters.GetWeek[1].day }}</th>
              <th width="14%">WED {{ $store.getters.GetWeek[2].day }}</th>
              <th width="14%">THU {{ $store.getters.GetWeek[3].day }}</th>
              <th width="14%">FRI {{ $store.getters.GetWeek[4].day }}</th>
              <th width="5%">SAT {{ $store.getters.GetWeek[5].day }}</th>
              <th width="5%">SUN {{ $store.getters.GetWeek[6].day }}</th>

            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in $store.getters.GetMesai" :key="item.id">
              <td hidden>{{ index + 1 }}</td>
              {{
                item.Employee
              }}

              <td>
                <h2 class="table-avatar">
                  <router-link to="/profile" class="avatar"><img alt="" src="../../../../assets/img/user.jpg" />
                  </router-link>
                  <router-link to="/profile" style="color: #251b37">{{ item.FirstName }} {{ item.LastName }}
                  </router-link>
                </h2>
              </td>
              <td>
                <div class="user-add-shedule-list">
                  <h2 v-if="item.tatil.findIndex((t) => t.gun == 0) > -1"> <a href="#"
                      style="border: 3px dashed #1eb53a">
                      <span class="username-info m-b-10">
                        <h4 style="color: #db290d">
                         {{ $t("Holiday") }}  
                        </h4>
                      </span>

                      <span class="userrole-warning">
                        <h5 class="table-avatar" style="color: #404258">

                        </h5>
                      </span>
                    </a></h2>
                  <h2 v-else-if="item.izinler.findIndex((t) => t.gun == 0) > -1"> <a href="#"
                      style="border: 3px dashed #1eb53a">
                      <span class="username-info m-b-10">
                        <h4 style="color: #db290d">
                       {{ $t("Leave") }}
                        </h4>
                      </span>

                      <span class="userrole-warning">
                        <h5 class="table-avatar" style="color: #404258">

                        </h5>
                      </span>
                    </a></h2>
                  <h2 v-else-if="item.gunler.findIndex((t) => t.gun == 0) > -1">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#edit_schedule" @click="
                      $emit(
                        'editScheduling',
                        item.gunler.find((z) => z.gun == 0)
                      )
                    " style="border: 3px dashed #1eb53a">
                      <span class="username-info m-b-10">
                        <h5 style="color: #fd841f">
                          {{
                            item.gunler
                              .find((z) => z.gun == 0)
                            ["mesai"].shiftTime["StartTime"].toDate()
                            | moment("HH:mm")
                          }}
                          -
                          {{
                            item.gunler
                              .find((z) => z.gun == 0)
                            ["mesai"].shiftTime["EndTime"].toDate()
                            | moment("HH:mm")
                          }}-
                          {{
                            AtamaGetir(
                              item.gunler.find((z) => z.gun == 0)["mesai"]
                                .DesignationID
                            )
                          }}
                        </h5>
                      </span>
                      <span class="userrole-danger"></span>
                      <span class="userrole-warning">
                        <h5 class="table-avatar" style="color: #404258">
                          {{
                            FirmaGetir(
                              item.gunler.find((z) => z.gun == 0)["mesai"]
                                .clientID
                            )
                          }}
                        </h5>
                      </span>
                    </a>
                  </h2>
                  <a v-else-if="item.gunler.findIndex((t) => t.gun == 0) == -1" href="#" data-bs-toggle="modal"
                    data-bs-target="#add_schedule" @click="personelGorev(item, 0)">
                    <span><i class="fa fa-plus"></i></span>
                  </a>
                </div>
              </td>
              <td>
                <div class="user-add-shedule-list">

                  <h2 v-if="item.tatil.findIndex((t) => t.gun == 1) > -1"> <a href="#"
                      style="border: 3px dashed #1eb53a">
                      <span class="username-info m-b-10">
                        <h4 style="color: #db290d">
                          {{ $t("Holiday") }}
                        </h4>
                      </span>

                      <span class="userrole-warning">
                        <h5 class="table-avatar" style="color: #404258">

                        </h5>
                      </span>
                    </a></h2>
                  <h2 v-else-if="item.izinler.findIndex((t) => t.gun == 1) > -1">
                    <a href="#" style="border: 3px dashed #1eb53a">
                      <span class="username-info m-b-10">
                        <h4 style="color: #db290d">
                          {{ $t("Leave") }}
                        </h4>
                      </span>

                      <span class="userrole-warning">
                        <h5 class="table-avatar" style="color: #404258">

                        </h5>
                      </span>
                    </a>

                  </h2>
                  <h2 v-else-if="item.gunler.findIndex((t) => t.gun == 1) > -1">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#edit_schedule" @click="
                      $emit(
                        'editScheduling',
                        item.gunler.find((z) => z.gun == 1)
                      )
                    " style="border: 3px dashed #1eb53a">
                      <span class="username-info m-b-10">
                        <h5 style="color: #fd841f">
                          {{
                            item.gunler
                              .find((z) => z.gun == 1)
                            ["mesai"].shiftTime["StartTime"].toDate()
                            | moment("HH:mm")
                          }}
                          -
                          {{
                            item.gunler
                              .find((z) => z.gun == 1)
                            ["mesai"].shiftTime["EndTime"].toDate()
                            | moment("HH:mm")
                          }}-
                          {{
                            AtamaGetir(
                              item.gunler.find((z) => z.gun == 1)["mesai"]
                                .DesignationID
                            )
                          }}
                        </h5>
                      </span>
                      <span class="userrole-danger"></span>
                      <span class="userrole-warning">
                        <h5 class="table-avatar" style="color: #404258">
                          {{
                            FirmaGetir(
                              item.gunler.find((z) => z.gun == 1)["mesai"]
                                .clientID
                            )
                          }}
                        </h5>
                      </span>
                    </a>
                  </h2>
                  <a v-else-if="item.gunler.findIndex((t) => t.gun == 1) == -1" href="#" @click="personelGorev(item, 1)"
                    data-bs-toggle="modal" data-bs-target="#add_schedule">
                    <span><i class="fa fa-plus"></i></span>
                  </a>
                </div>
              </td>
              <td>
                <div class="user-add-shedule-list">
                  <h2 v-if="item.tatil.findIndex((t) => t.gun == 2) > -1"> <a href="#"
                      style="border: 3px dashed #1eb53a">
                      <span class="username-info m-b-10">
                        <h4 style="color: #db290d">
                          {{ $t("Holiday") }}
                        </h4>
                      </span>

                      <span class="userrole-warning">
                        <h5 class="table-avatar" style="color: #404258">

                        </h5>
                      </span>
                    </a></h2>
                  <h2 v-else-if="item.izinler.findIndex((t) => t.gun == 2) > -1"> <a href="#"
                      style="border: 3px dashed #1eb53a">
                      <span class="username-info m-b-10">
                        <h4 style="color: #db290d">
                          {{ $t("Leave") }}
                        </h4>
                      </span>

                      <span class="userrole-warning">
                        <h5 class="table-avatar" style="color: #404258">

                        </h5>
                      </span>
                    </a></h2>


                  <h2 v-else-if="item.gunler.findIndex((t) => t.gun == 2) > -1">


                    <a href="#" data-bs-toggle="modal" data-bs-target="#edit_schedule" @click="
                      $emit(
                        'editScheduling',
                        item.gunler.find((z) => z.gun == 2)
                      )
                    " style="border: 3px dashed #1eb53a">
                      <span class="username-info m-b-10">
                        <h5 style="color: #fd841f">
                          {{
                            item.gunler
                              .find((z) => z.gun == 2)
                            ["mesai"].shiftTime["StartTime"].toDate()
                            | moment("HH:mm")
                          }}
                          -
                          {{
                            item.gunler
                              .find((z) => z.gun == 2)
                            ["mesai"].shiftTime["EndTime"].toDate()
                            | moment("HH:mm")
                          }}-
                          {{
                            AtamaGetir(
                              item.gunler.find((z) => z.gun == 2)["mesai"]
                                .DesignationID
                            )
                          }}
                        </h5>
                      </span>
                      <span class="userrole-danger"></span>
                      <span class="userrole-warning">
                        <h5 class="table-avatar" style="color: #404258">
                          {{
                            FirmaGetir(
                              item.gunler.find((z) => z.gun == 2)["mesai"]
                                .clientID
                            )
                          }}
                        </h5>
                      </span>
                    </a>
                  </h2>
                  <a v-else-if="item.gunler.findIndex((t) => t.gun == 2) == -1" href="#" @click="personelGorev(item, 2)"
                    data-bs-toggle="modal" data-bs-target="#add_schedule">
                    <span><i class="fa fa-plus"></i></span>
                  </a>
                </div>
              </td>
              <td>
                <div class="user-add-shedule-list">
                  <h2 v-if="item.tatil.findIndex((t) => t.gun == 3) > -1"> <a href="#"
                      style="border: 3px dashed #1eb53a">
                      <span class="username-info m-b-10">
                        <h4 style="color: #db290d">
                          {{ $t("Holiday") }}
                        </h4>
                      </span>

                      <span class="userrole-warning">
                        <h5 class="table-avatar" style="color: #404258">

                        </h5>
                      </span>
                    </a></h2>
                  <h2 v-else-if="item.izinler.findIndex((t) => t.gun == 3) > -1"> <a href="#"
                      style="border: 3px dashed #1eb53a">
                      <span class="username-info m-b-10">
                        <h4 style="color: #db290d">
                          {{ $t("Leave") }}
                        </h4>
                      </span>

                      <span class="userrole-warning">
                        <h5 class="table-avatar" style="color: #404258">

                        </h5>
                      </span>
                    </a></h2>
                  <h2 v-else-if="item.gunler.findIndex((t) => t.gun == 3) > -1">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#edit_schedule" @click="
                      $emit(
                        'editScheduling',
                        item.gunler.find((z) => z.gun == 3)
                      )
                    " style="border: 3px dashed #1eb53a">
                      <span class="username-info m-b-10">
                        <h5 style="color: #fd841f">
                          {{
                            item.gunler
                              .find((z) => z.gun == 3)
                            ["mesai"].shiftTime["StartTime"].toDate()
                            | moment("HH:mm")
                          }}
                          -
                          {{
                            item.gunler
                              .find((z) => z.gun == 3)
                            ["mesai"].shiftTime["EndTime"].toDate()
                            | moment("HH:mm")
                          }}-
                          {{
                            AtamaGetir(
                              item.gunler.find((z) => z.gun == 3)["mesai"]
                                .DesignationID
                            )
                          }}
                        </h5>
                      </span>
                      <span class="userrole-danger"></span>
                      <span class="userrole-warning">
                        <h5 class="table-avatar" style="color: #404258">
                          {{
                            FirmaGetir(
                              item.gunler.find((z) => z.gun == 3)["mesai"]
                                .clientID
                            )
                          }}
                        </h5>
                      </span>
                    </a>
                  </h2>
                  <a v-else-if="item.gunler.findIndex((t) => t.gun == 3) == -1" href="#" @click="personelGorev(item, 3)"
                    data-bs-toggle="modal" data-bs-target="#add_schedule">
                    <span><i class="fa fa-plus"></i></span>
                  </a>
                </div>
              </td>
              <td>

                <div class="user-add-shedule-list">
                  <h2 v-if="item.tatil.findIndex((t) => t.gun == 4) > -1"> <a href="#"
                      style="border: 3px dashed #1eb53a">
                      <span class="username-info m-b-10">
                        <h4 style="color: #db290d">
                          {{ $t("Holiday") }}
                        </h4>
                      </span>

                      <span class="userrole-warning">
                        <h5 class="table-avatar" style="color: #404258">

                        </h5>
                      </span>
                    </a></h2>
                  <h2 v-else-if="item.izinler.findIndex((t) => t.gun == 4) > -1"> <a href="#"
                      style="border: 3px dashed #1eb53a">
                      <span class="username-info m-b-10">
                        <h4 style="color: #db290d">
                          {{ $t("Leave") }}
                        </h4>
                      </span>

                      <span class="userrole-warning">
                        <h5 class="table-avatar" style="color: #404258">

                        </h5>
                      </span>
                    </a></h2>
                  <h2 v-else-if="item.gunler.findIndex((t) => t.gun == 4) > -1">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#edit_schedule" @click="
                      $emit(
                        'editScheduling',
                        item.gunler.find((z) => z.gun == 4)
                      )
                    " style="border: 3px dashed #1eb53a">
                      <span class="username-info m-b-10">
                        <h5 style="color: #fd841f">
                          {{
                            item.gunler
                              .find((z) => z.gun == 4)
                            ["mesai"].shiftTime["StartTime"].toDate()
                            | moment("HH:mm")
                          }}
                          -
                          {{
                            item.gunler
                              .find((z) => z.gun == 4)
                            ["mesai"].shiftTime["EndTime"].toDate()
                            | moment("HH:mm")
                          }}-
                          {{
                            AtamaGetir(
                              item.gunler.find((z) => z.gun == 4)["mesai"]
                                .DesignationID
                            )
                          }}
                        </h5>
                      </span>
                      <span class="userrole-danger"></span>
                      <span class="userrole-warning">
                        <h5 class="table-avatar" style="color: #404258">
                          {{
                            FirmaGetir(
                              item.gunler.find((z) => z.gun == 4)["mesai"]
                                .clientID
                            )
                          }}
                        </h5>
                      </span>
                    </a>
                  </h2>
                  <a v-else-if="item.gunler.findIndex((t) => t.gun == 4) == -1" href="#" @click="personelGorev(item, 4)"
                    data-bs-toggle="modal" data-bs-target="#add_schedule">
                    <span><i class="fa fa-plus"></i></span>
                  </a>
                </div>
              </td>
              <td>


                <div class="user-add-shedule-list" style=" opacity: 0.6;background-color: #fd841f;">
                  <h2 v-if="item.tatil.findIndex((t) => t.gun == 5) > -1"> <a href="#"
                      style="border: 3px dashed #1eb53a">
                      <span class="username-info m-b-10">
                        <h4 style="color: #db290d">
                          {{ $t("Holiday") }}
                        </h4>
                      </span>

                      <span class="userrole-warning">
                        <h5 class="table-avatar" style="color: #404258">

                        </h5>
                      </span>
                    </a></h2>
                  <h2 v-else-if="item.izinler.findIndex((t) => t.gun == 5) > -1"> <a href="#"
                      style="border: 3px dashed #1eb53a">
                      <span class="username-info m-b-10">
                        <h4 style="color: #db290d">
                          {{ $t("Leave") }}
                        </h4>
                      </span>

                      <span class="userrole-warning">
                        <h5 class="table-avatar" style="color: #404258">

                        </h5>
                      </span>
                    </a></h2>
                  <h2 v-else-if="item.gunler.findIndex((t) => t.gun == 5) > -1">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#edit_schedule" @click="
                      $emit(
                        'editScheduling',
                        item.gunler.find((z) => z.gun == 5)
                      )
                    ">
                      <span class="username-info m-b-10">
                        <h5 style="color: #fd841f">
                          {{
                            item.gunler
                              .find((z) => z.gun == 5)
                            ["mesai"].shiftTime["StartTime"].toDate()
                            | moment("HH:mm")
                          }}
                          -
                          {{
                            item.gunler
                              .find((z) => z.gun == 5)
                            ["mesai"].shiftTime["EndTime"].toDate()
                            | moment("HH:mm")
                          }}-
                          {{
                            AtamaGetir(
                              item.gunler.find((z) => z.gun == 5)["mesai"]
                                .DesignationID
                            )
                          }}
                        </h5>
                      </span>
                      <span class="userrole-danger"></span>
                      <span class="userrole-warning">
                        <h5 class="table-avatar" style="color: #404258">
                          {{
                            FirmaGetir(
                              item.gunler.find((z) => z.gun == 5)["mesai"]
                                .clientID
                            )
                          }}
                        </h5>
                      </span>
                    </a>
                  </h2>
                  <a v-else-if="item.gunler.findIndex((t) => t.gun == 5) == -1" href="#" @click="personelGorev(item, 5)"
                    data-bs-toggle="modal" data-bs-target="#add_schedule">
                    <span><i class="fa fa-plus"></i></span>
                  </a>
                </div>
              </td>
              <td>

                <div class="user-add-shedule-list" style="opacity: 0.6; background-color: #fd841f; ">
                  <h2 v-if="item.tatil.findIndex((t) => t.gun == 6) > -1"> <a href="#"
                      style="border: 3px dashed #1eb53a">
                      <span class="username-info m-b-10">
                        <h4 style="color: #db290d">
                          {{ $t("Holiday") }}
                        </h4>
                      </span>

                      <span class="userrole-warning">
                        <h5 class="table-avatar" style="color: #404258">

                        </h5>
                      </span>
                    </a></h2>

                  <h2 v-else-if="item.izinler.findIndex((t) => t.gun == 6) > -1"> <a href="#"
                      style="border: 3px dashed #1eb53a">
                      <span class="username-info m-b-10">
                        <h4 style="color: #db290d">
                          {{ $t("Leave") }}
                        </h4>
                      </span>

                      <span class="userrole-warning">
                        <h5 class="table-avatar" style="color: #404258">

                        </h5>
                      </span>
                    </a></h2>
                  <h2 v-else-if="item.gunler.findIndex((t) => t.gun == 6) > -1">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#edit_schedule" @click="
                      $emit(
                        'editScheduling',
                        item.gunler.find((z) => z.gun == 6)
                      )
                    ">
                      <span class="username-info m-b-10">
                        <h5 style="color: #fd841f">
                          {{
                            item.gunler
                              .find((z) => z.gun == 6)
                            ["mesai"].shiftTime["StartTime"].toDate()
                            | moment("HH:mm")
                          }}
                          -
                          {{
                            item.gunler
                              .find((z) => z.gun == 6)
                            ["mesai"].shiftTime["EndTime"].toDate()
                            | moment("HH:mm")
                          }}-
                          {{
                            AtamaGetir(
                              item.gunler.find((z) => z.gun == 6)["mesai"]
                                .DesignationID
                            )
                          }}
                        </h5>
                      </span>
                      <span class="userrole-danger"></span>
                      <span class="userrole-warning">
                        <h5 class="table-avatar" style="color: #404258">
                          {{
                            FirmaGetir(
                              item.gunler.find((z) => z.gun == 6)["mesai"]
                                .clientID
                            )
                          }}
                        </h5>
                      </span>
                    </a>
                  </h2>
                  <a v-else-if="item.gunler.findIndex((t) => t.gun == 6) == -1" href="#" @click="personelGorev(item, 6)"
                    data-bs-toggle="modal" data-bs-target="#add_schedule">
                    <span><i class="fa fa-plus"></i></span>
                  </a>
                </div>
              </td>
              <!-- <td>
                <div
                  class="user-add-shedule-list"
                  style="
                     
                    opacity: 0.6;
                    background-color: #db290d;
                    width: 35px;
                  "
                >
                  <h2 v-if="item.gunler.findIndex((t) => t.gun == 6) > -1">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_schedule"
                      @click="
                        $emit(
                          'editScheduling',
                          item.gunler.find((z) => z.gun == 6)
                        )
                      "
                      style="background: #db290d"
                    >
                      <span class="username-info m-b-10">
                        <h5 style="color: #fd841f">
                          {{
                            item.gunler
                              .find((z) => z.gun == 6)
                              ["mesai"].shiftTime["StartTime"].toDate()
                              | moment("HH:mm")
                          }}
                          -
                          {{
                            item.gunler
                              .find((z) => z.gun == 6)
                              ["mesai"].shiftTime["EndTime"].toDate()
                              | moment("HH:mm")
                          }}-
                          {{
                            AtamaGetir(
                              item.gunler.find((z) => z.gun == 6)["mesai"]
                                .DesignationID
                            )
                          }}
                        </h5>
                      </span>
                      <span class="userrole-danger"></span>
                      <span class="userrole-warning">
                        <h5 class="table-avatar" style="color: #404258">
                          {{
                            FirmaGetir(
                              item.gunler.find((z) => z.gun == 6)["mesai"]
                                .clientID
                            )
                          }}
                        </h5>
                      </span>
                    </a>
                  </h2>

                  
                  <a
                    v-if="item.gunler.findIndex((t) => t.gun == 6) == -1"
                    @click="personelGorev(item, 6)"
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#add_schedule"
                  >
                    <span><i class="fa fa-plus"></i></span>
                  </a>
                </div>
              </td> -->
              <!-- <td>
                <div class="user-add-shedule-list">
                  <a
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#add_schedule"
                  >
                    <span><i class="fa fa-plus"></i></span>
                  </a>
                </div>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div id="edit_schedule" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Schedule</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Client <span class="text-danger">*</span></label>
                    <Select2 name="clientslistData" v-model="clients" :options="getClientData"
                      placeholder="Please select company" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Employee Name <span class="text-danger">*</span></label>
                    <Select2 name="employeelistData" v-model="employees" :options="getEmployeeData"
                      placeholder="Please select employee" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Date <span class="text-danger">*</span></label>
                    <div class="cal-icon">
                      <date-picker v-model="startdate" class="picker" placeholder="Please select start date"
                        :editable="true" :clearable="false" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Shifts <span class="text-danger">*</span></label>
                    <Select2 name="shiftlistData" v-model="shifts" :options="getClientData"
                      placeholder="Please select shift" />
                  </div>
                </div>
                <!-- <div class="col-sm-4">
<div class="form-group">
<label class="col-form-label">Min Start Time <span class="text-danger">*</span></label>
<input class="form-control" type="text" value="06:11 am">
</div>
</div> -->
                <!-- <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Start Time <span class="text-danger">*</span></label>
                    <input class="form-control" type="text" value="06:30 am" />
                  </div>
                </div> -->
                <!-- <div class="col-sm-4">
<div class="form-group">
<label class="col-form-label">Max Start Time <span class="text-danger">*</span></label>
<input class="form-control" type="text" value="08:12 am">
</div>
</div> -->
                <!-- <div class="col-sm-4">
<div class="form-group">
<label class="col-form-label">Min End Time <span class="text-danger">*</span></label>
<input class="form-control" type="text" value="09:12 pm">
</div>
</div> -->
                <!-- <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">End Time <span class="text-danger">*</span></label>
                    <input class="form-control" type="text" value="09:30 pm" />
                  </div>
                </div> -->
                <!-- <div class="col-sm-4">
<div class="form-group">
<label class="col-form-label">Max End Time <span class="text-danger">*</span></label>
<input class="form-control" type="text" value="09:45 pm">
</div>
</div> -->
                <!-- <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Break Time <span class="text-danger">*</span></label>
                    <input class="form-control" type="text" value="45" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="custom-control form-check">
                    <input type="checkbox" class="form-check-input" id="customCheck1" />
                    <label class="form-check-label" for="customCheck1">Recurring Shift</label>
                  </div>
                </div> -->
                <!-- <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Repeat Every</label>
                    <select class="select">
                      <option value="">1</option>
                      <option value="1">2</option>
                      <option value="2">3</option>
                      <option value="3">4</option>
                      <option selected value="4">5</option>
                      <option value="3">6</option>
                    </select>
                    <label class="col-form-label">Week(s)</label>
                  </div>
                </div> -->
                <div class="col-sm-6">
                  <div class="form-group wday-box">
                    <label class="checkbox-inline"><input type="checkbox" name="week_days[]" value="monday"
                        class="days recurring" checked="" onclick="return false;" /><span
                        class="checkmark">M</span></label>
                    <label class="checkbox-inline"><input type="checkbox" name="week_days[]" value="tuesday"
                        class="days recurring" checked="" onclick="return false;" /><span
                        class="checkmark">T</span></label>
                    <label class="checkbox-inline"><input type="checkbox" name="week_days[]" value="wednesday"
                        class="days recurring" checked="" onclick="return false;" /><span
                        class="checkmark">W</span></label>
                    <label class="checkbox-inline"><input type="checkbox" name="week_days[]" value="thursday"
                        class="days recurring" checked="" onclick="return false;" /><span
                        class="checkmark">T</span></label>
                    <label class="checkbox-inline"><input type="checkbox" name="week_days[]" value="friday"
                        class="days recurring" checked="" onclick="return false;" /><span
                        class="checkmark">F</span></label>
                    <label class="checkbox-inline"><input type="checkbox" name="week_days[]" value="saturday"
                        class="days recurring" onclick="return false;" /><span class="checkmark">S</span></label>
                    <label class="checkbox-inline"><input type="checkbox" name="week_days[]" value="sunday"
                        class="days recurring" onclick="return false;" /><span class="checkmark">S</span></label>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">End On <span class="text-danger">*</span></label>
                    <div class="cal-icon">
                      <date-picker v-model="startdate" class="picker" placeholder="Please select end date"
                        :editable="true" :clearable="false" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="custom-control form-check">
                    <input type="checkbox" class="form-check-input" id="customCheck2" />
                    <label class="form-check-label" for="customCheck2">Indefinite</label>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Accept Extra Hours </label>
                    <div class="form-check form-switch">
                      <input type="checkbox" class="form-check-input" id="customSwitch3" checked="" />
                      <label class="form-check-label" for="customSwitch3"></label>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="col-form-label">Publish </label>
                    <div class="form-check form-switch">
                      <input type="checkbox" class="form-check-input" id="customSwitch4" checked="" />
                      <label class="form-check-label" for="customSwitch4"></label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="submit-section">
                <button class="btn btn-primary submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>


import Vue from "vue";
import DatePicker from "vue2-datepicker";
import Select2 from "v-select2-component";
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["searchDailyScheduling"],

  data() {
    return {
      today: new Date(),
      scheduling: {},
      editscheduling: {},
      getEmployeeData: [],
      getClientData: [],
      schedulingTable: [],
      employees: {},
      shifts: {},
      clients: {},
      startdate: {},
    };
  },
  components: {
    DatePicker,
    Select2,
  },
  methods: {
    ...mapActions([
      "AddData",
      "EditData",
      "DeleteData",
      "sorgu",
      "Tanimlamalar",
      "isTablosu",
      "haftaGetir",
    ]),

    ayIsimleri(a) {
      var months = new Array();
      months[1] = "JAN";
      months[2] = "FEB";
      months[3] = "MRZ";
      months[4] = "APR";
      months[5] = "MAİ";
      months[6] = "JUN";
      months[7] = "JUL";
      months[8] = "AUG";
      months[9] = "SEP";
      months[10] = "OKT";
      months[11] = "NOV";
      months[12] = "DEZ";

      return months[a];
    },

    async nextWeek(id) {
      //BULUNDUĞUMUZ HAFTA BAŞINI ALIYOR
      var firstday = new Date(
        this.today.setDate(this.today.getDate() - (this.today.getDay() - 1))
      );
      //İLERİ Mİ GERİYE Mİ GİDİLECEK
      id > 0
        ? this.today.setDate(firstday.getDate() + 7)
        : this.today.setDate(firstday.getDate() - 7);

      //HAFTA SET ETİLİYOR
      this.haftaGetir({ tarih: new Date(this.today) });

      this.$toast.open({
        message: this.$t("Please Waiting"),
        type: "info",
      });

      //GEREKLİ TANIMLAMALAR ÇEKİLİYOR
      await this.Tanimlamalar(),
        //VARDİYA TABLOSUNU GÜNCELLİYORUZ
        await this.isTablosu({ sorgu: this.searchDailyScheduling });
    },
    personelGorev(item, id) {
      var tempDate = this.$store.getters.GetWeek[id];

      item["startDate"] =
        tempDate.year.toString() +
        "-" +
        (tempDate.month < 10
          ? "0" + tempDate.month.toString()
          : tempDate.month.toString()) +
        "-" +
        (tempDate.day < 10 ? "0" + tempDate.day.toString() : tempDate.day);

      this.$emit("personData", item);
    },
    FirmaGetir(t) {
      //  console.log("FİRMA BİLGİSİ İSTENİYOR",this.$store.getters.Getclient)
      try {
        return this.$store.getters.Getclient.find((a) => a.id == t).ClientName;
      } catch (error) {
        return t;
      }
    },
    AtamaGetir(g) {
      try {
        return this.$store.getters.GetDesignation.find((m) => m.id == g)
          .DesignationName;
      } catch (error) {
        return g;
      }
    },
  },
  computed: {
    getData() {
      return this.GetSch;
    },
  },

  async mounted() {
    //GEREKLİ TANIMLAMALAR ÇEKİLİYOR
    await this.Tanimlamalar(),
      //MEVCUT HAFTA ALINIYOR
      await this.haftaGetir({}),
      await this.isTablosu();

    this.ayIsimleri();
    // setTimeout(async () => {
    //   this.isTablosu();
    // }, 2000);

    // When the user scrolls the page, execute myFunction
    window.onscroll = function () { myFunction() };

    // Get the navbar
    var navbar = document.getElementById("navbar");



    // Get the offset position of the navbar
    var sticky = navbar.offsetTop;

    // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function myFunction() {
      if (window.pageYOffset >= sticky) {
        document.getElementById("tableSticky").style.display = "block";
        navbar.classList.add("sticky")
        navbar.style.color = "white"
      } else {
        document.getElementById("tableSticky").style.display = "none";
        navbar.classList.remove("sticky");
        navbar.style.color = "black"
      }
    }

    // util.datatable("#scheduletable");
  },
};
</script>

<style scoped>
/* Style the navbar */
#navbar {
  overflow: hidden;
  padding-top: 17px;


}


/* The sticky class is added to the navbar with JS when it reaches its scroll position */
.sticky {
  position: sticky;
  top: 6.5%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.37);
  width: 100%;
}

/* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
.sticky+.content {
  padding-top: 60px;
}
</style>