<template>




  <div class="row">

    <div class="col-md-12">
      <div class="card card-table">
        <div class="card-header">
          <h3 class="card-title mb-0"> {{$t("Jobs")}}</h3>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-nowrap custom-table mb-0">
              <thead>
                <tr>
                  <th>{{$t("Company")}}</th>
                  <th>{{$t("Designation")}}</th>
                  <th>{{$t("Department")}}</th>
                  <th>{{$t("Start Date")}}</th>
                  <th>{{$t("Expire Date")}}</th>
                  <th class="text-center">{{$t("Start At")}}</th>
                  <th class="text-center">{{$t("End At")}}</th>
                  <th class="text-center">{{$t("Actions")}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in filterBy($store.getters.GetDailySchedule, a=>$store.getters.GetSearchFilter) " :key="item.id">
                  <td>{{ storeGetters(item.clientID, "Getclient", "ClientName") }}

                  </td>
                  <td>{{ storeGetters(item.designations, "GetDesignation", "DesignationName") }} </td>
                  <td>{{ storeGetters(item.department, "GetDepartment", "DepartmentName") }}</td>
                  <td>{{ item.startDate.toDate() | moment("DD-MM-YYYY h:mm") }}</td>
                  <td>{{ item.endDate.toDate() | moment("DD-MM-YYYY h:mm") }}</td>
                  <td>
                    {{ item.startDate.toDate() | moment("from") }}
                  </td>
                  <td>
                    {{ item.endDate.toDate() | moment("from") }}
                  </td>
                  <td class="text-center">
                    <div class="action-label">
                      <a class="btn btn-white btn-sm btn-rounded" href="#" data-bs-toggle="modal"
                        data-bs-target="#add_job" @click="editJob(item)" aria-expanded="false">
                        <i class="fa fa-dot-circle-o "></i> {{ item.workStart }}
                      </a>
                    </div>
                  </td>
                  <!-- <td class="text-center">
                            <div class="dropdown dropdown-action">
                              <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_employee"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                              </div>
                            </div>
                          </td> -->
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div id="add_job" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"> {{$t("Job Details")}}</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="jobEdit">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label><b>{{$t("Company")}} :</b> </label>
                    {{ storeGetters(DailySchedule.clientID, "Getclient", "ClientName") }}
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label><b>{{$t("Designation")}} :</b></label>
                    {{ storeGetters(DailySchedule.designations, "GetDesignation", "DesignationName") }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label><b>{{$t("DepartmentName")}} :</b></label>
                    {{ storeGetters(DailySchedule.department, "GetDepartment", "DepartmentName") }}
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="col-md-6" v-if="DailySchedule.startDate">
                  <div class="form-group">
                    <label><b>{{$t("Start Date")}} :</b></label>
                    {{ DailySchedule.startDate.toDate() | moment("DD-MM-YYYY h:mm") }}
                  </div>
                </div>
                <div class="col-md-6" v-if="DailySchedule.endDate">
                  <div class="form-group">
                    <label><b>{{$t("End Date")}} :</b></label>
                    {{ DailySchedule.endDate.toDate() | moment("DD-MM-YYYY h:mm") }}
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">

                    <b>{{$t("WorkStart")}} :</b>
                    <Select2 :options="workStart" v-model="DailySchedule.workStart" />
                  </div>
                </div>
                <div class="col-md-6" v-if="DailySchedule.workStart == 'Accepted'">
                  <div class="form-group">

                    <b>{{$t("WorkEnd")}} :</b>
                    <Select2 :options="workEnd" v-model="DailySchedule.workEnd" />
                  </div>
                </div>
              </div>




              <div class="submit-section">
                <button class="btn btn-primary submit-btn">{{$t("Save")}}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import { mapActions } from 'vuex'
import { fnk } from "../../../../fonksiyonlar/fnk"
import Select2 from 'v-select2-component'
import Vue2Filters from 'vue2-filters'
import searchVue from '@/components/search.vue'
export default {
  components: {
    Select2,


  },
  data() {
    return {
      workStart: ["Accepted", "Declined"],
      workEnd: ["Accepted", "Declined"],
      DailySchedule: {

      },
    
    }

  },
  mixins: [fnk,Vue2Filters.mixin],
  methods: {
    ...mapActions(["EditData"]),
    editJob(item) {
      this.DailySchedule = item;
    },
    async jobEdit(e) {


      if (this.DailySchedule.workStart == "Declined") {
        delete this.DailySchedule.workStart;
        delete this.DailySchedule.workEnd;

      }
      if (this.DailySchedule.workEnd == "Declined") {

        delete this.DailySchedule.workEnd;
      }



      await this.EditData({ db: "DailyScheduling", data: this.DailySchedule, id: this.DailySchedule.id })
      window.location.reload()

    }

  },

}
</script>