<template>
  <div class="col-lg-4 col-md-4">
                <div class="dash-sidebar">
                  <!-- <section>
                    <h5 class="dash-title">Projects</h5>
                    <div class="card">
                      <div class="card-body">
                        <div class="time-list">
                          <div class="dash-stats-list">
                            <h4>71</h4>
                            <p>Total Tasks</p>
                          </div>
                          <div class="dash-stats-list">
                            <h4>14</h4>
                            <p>Pending Tasks</p>
                          </div>
                        </div>
                        <div class="request-btn">
                          <div class="dash-stats-list">
                            <h4>2</h4>
                            <p>Total Projects</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section> -->
                  <!-- <section>
                  <br/><br/>
                    <div class="card">
                      <div class="card-body">
                    <div class="col-md-12  d-flex">
                <div class="card flex-fill">
                  <div class="card-body">
                    <h4 class="card-title">{{ $t("Task Stats") }}</h4>
                    <div class="statistics">
                      <div class="row">
                        <div class="col-md-6 col-6 text-center">
                          <div class="stats-box mb-4">
                            <p>{{ $t("Total Tasks") }} </p>
                            <h3>{{$store.getters.GetSch.length}}</h3>
                          </div>
                        </div>
                        <div class="col-md-6 col-6 text-center">
                          <div class="stats-box mb-4">
                            <p>{{ $t("Overdue Tasks") }}  </p>
                            <h3>19</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="progress mb-4">
                      <div class="progress-bar bg-purple" role="progressbar" style="width: 30%" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">30%</div>
                      <div class="progress-bar bg-warning" role="progressbar" style="width: 22%" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">22%</div>
                      <div class="progress-bar bg-success" role="progressbar" style="width: 24%" aria-valuenow="12" aria-valuemin="0" aria-valuemax="100">24%</div>
                      <div class="progress-bar bg-danger" role="progressbar" style="width: 26%" aria-valuenow="14" aria-valuemin="0" aria-valuemax="100">21%</div>
                      <div class="progress-bar bg-info" role="progressbar" style="width: 10%" aria-valuenow="14" aria-valuemin="0" aria-valuemax="100">10%</div>
                    </div>
                    <div>
                      <p><i class="fa fa-dot-circle-o text-purple me-2"></i>{{ $t("Completed Tasks") }}  <span class="float-end">166</span></p>
                      <p><i class="fa fa-dot-circle-o text-warning me-2"></i>{{ $t("Inprogress Tasks") }}  <span class="float-end">115</span></p>
                      <p><i class="fa fa-dot-circle-o text-success me-2"></i>{{ $t("On Hold Tasks") }} <span class="float-end">31</span></p>
                      <p><i class="fa fa-dot-circle-o text-danger me-2"></i>{{ $t("Pending Tasks") }} <span class="float-end">47</span></p>
                      <p class="mb-0"><i class="fa fa-dot-circle-o text-info me-2"></i>{{ $t("Review Tasks") }}  <span class="float-end">5</span></p>
                    </div>
                  </div>
                </div>
              </div></div></div>
                  </section> -->
                  <!-- <section> 
                    <h5 class="dash-title">{{ $t("Your Leave") }}</h5>
                    <div class="card">
                      <div class="card-body">
                        <div class="time-list">
                          <div class="dash-stats-list">
                            <h4> {{$store.getters.GetLeaves.length}}</h4>
                            <p>{{ $t("Leave Taken") }}</p>
                          </div>
                          <div class="dash-stats-list">
                            <h4>{{$store.getters.GetLeaves.length}}</h4>
                            
                            <p>{{ $t("Accepted") }}</p>
                          </div>
                        </div>
                        <div class="request-btn">
                          <a class="btn btn-primary" href="#">{{ $t("Apply Leave") }}</a>
                        </div>
                      </div>
                    </div>
                  </section> -->
               
                  <!-- <section>
                    <h5 class="dash-title">{{ $t("Your Employee Total") }}</h5>
                    <div class="card">
                      <div class="card-body">
                        <div class="time-list">
                          <div class="dash-stats-list">
                      
                            <h4>   {{$store.getters.GetEmployes.length}}</h4>
                            <p>{{ $t("Approved") }}</p>
                          </div>
                          <div class="dash-stats-list">
                            <h4>15 Hours</h4>
                            <p>{{ $t("Remaining") }}</p>
                          </div>
                        </div>
                        <div class="request-btn">
                          <a class="btn btn-primary" href="#">{{ $t("Apply Time Off") }}</a>
                        </div>
                      </div>
                    </div>
                  </section> -->
                <!--  <section>
                    <h5 class="dash-title">{{ $t("Upcoming Holiday") }}</h5>
                    <div class="card">
                      <div class="card-body text-center"> -->
                        <!-- <h4 class="holiday-title mb-0">{{$store.getters.Getholidays!=null?$store.getters.Getholidays.length:"0"}}</h4> -->
                     <!-- </div>
                    </div>
                  </section> -->
                  
                </div>
              </div>
  </template>
  <script>
  import util from '../../../../assets/utils/util'
  /* eslint-disable */
     import { mapActions, mapGetters } from "vuex";
    export default {
      data() {
      return {
     //   employee: [],
      }
      },
      components: {
      },
      methods:{
    ...mapActions(["sorgu"]),
      
      fetchdataa()
      {
  //this.fetchdata({db:"Employee",mt:"SetEmployee"});
   
      },
     
      getEmp()
      {
        let arr=[];
        this.getDataEmp.forEach((el)=>{
         arr.push({title:el.FirstName});
        })
  
      },
  
    
      
    
      
      },
      computed:{
    
    
   
   
      },
      mounted() {
       
   
      // this.sorgu({ tablo: "Employee", guncelle: "SetEmployee" })
       this.sorgu({ tablo: "Holidays", guncelle: "Getholidays" })
  
  
      },
      name: 'activities'
    }
  </script>