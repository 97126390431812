<template>
  <div class="performanceappraisal">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
       <!-- Page Wrapper -->
       <div class="page-wrapper">
      
       <!-- Page Content -->
       <div class="content container-fluid">
        
       <performanceappraisalheader />
          
       <performanceappraisaltable />
                
       </div>
       <!-- /Page Content -->

       <modalperformanceappraisal />
               
      </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
   
  export default {
    components: {
   
    },
    mounted() {

    },
    name: 'performanceappraisal'
  }
</script>