<template>
  <!-- Search Filter -->
          <div class="row filter-row mb-4">
                
            <div class="col-sm-6 col-md-3">
              <div class="form-group form-focus select-focus">
                <Select2
                  name="employeelistData"
                  v-model="searchLeaveReport.EmployeeID"
                  :options="getEmployeeData"
                  placeholder="Please select employee"
                />

                <label class="focus-label">{{ $t("Employee") }}</label>
              </div>
            </div>

            <div class="col-sm-6 col-md-3">
              <div class="form-group form-focus select-focus">
                <Select2
                  v-model="searchLeaveReport.clientID"
                  :options="getClientData"
                  placeholder="Please select company"
                />
                <label class="focus-label">{{ $t("Clients") }}</label>
              </div>
            </div>
            <div class="col-sm-6 col-md-2">
              <div class="form-group form-focus focused">
                <div>
                  <input
                    type="date"
                    v-model="searchLeaveReport.startDate"
                    name="startdate"
                    class="picker"
                    placeholder="Please select end date"
                    :editable="true"
                    :clearable="false"
                  />
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-md-2">
              <a href="#" @click="searchBtn()" class="btn btn-success w-100"
                >{{ $t("Search") }}
              </a>
            </div>
            <div class="col-sm-6 col-md-2">
              <a href="#" @click="resetBtn()" class="btn btn-warning w-100"
                >{{ $t("Reset") }}
              </a>
            </div>
          </div>
          <!-- /Search Filter -->
</template>
<script>
   
  
  const currentDate = new Date()
  const currentDate1 = new Date()
  import { mapActions, mapGetters } from 'vuex'
  import Multiselect from "vue-multiselect";
import Select2 from "v-select2-component";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import datetimevuejs from "vuejs-datetimepicker";
/* eslint-disable */
  export default {
    data() {
     
    return {
      searchLeaveReport: { id: "0" },
      allEmployee: false,
      clients: {
        CompanyUid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
        title: "",
      },
      department: {
        CompanyUid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
        DepartmentName: "",
      },
      leavereportdepartment: ["Select Department", "Designing", "Development", "Finance", "Hr & Finance"],
      startdate: "currentDate",
      enddate: "currentDate1",
    }
    },
    components: {
      Select2,
    DatePicker,
    Multiselect,
    datetimevuejs,
    },
    computed:{
      ...mapGetters([
      "Getclient",
      "GetShiftTable",
      "GetEmployes",
      "GetDepartment",
      "GetDailySchedule",
      "GetDesignation",
      "Getcompany",
    ]),
      getClientData() {
      let veri = this.Getclient.map((t) => {
        return { text: t.ClientName, id: t.id };
      });
      veri.unshift({ text: "All Client", id: "0" });
      return veri;
    },
    getEmployeeData() {
      // return this.$store.getters.GetEmployes.map((t) => {
      //   return { text: t.FirstName + " " + t.LastName, id: t.id };
      // });

      let veri = this.GetEmployes.map((t) => {
        return { text: t.FirstName + " " + t.LastName, id: t.id };
      });
      veri.unshift({ text: "All Employee", id: "0" });
      return veri;
    },
    getDepartmentData() {
      let arr = [];
      this.GetDepartment.forEach((e) => {
        arr.push(e.DepartmentName);
      });
      return [...new Set(arr)];
    },
    },
    methods:{
     
    ...mapActions([
      "AddData",
      "EditData",
      "DeleteData",
      "getClients",
      "fetchdata",
      "getSch",
      "sorgu",
      "Tanimlamalar",
      "isTablosu",
      "MusaitPersonel",
      "haftaGetir",
      "sorguTable",
      "sorguRef",
      "sorguDoc",
    ]),
    async clientChange(e) {
      this.tempShiftsTime = await this.sorguTable({
        tablo: "ShiftTable",

        kosul: [{ query: ["clientID", "==", e] }],
      });

      this.tempShiftsTime = this.tempShiftsTime.map((t) => {
        return {
          ShiftName: t.ShiftName,
          StartTime: moment(t.StartTime.toDate()).format("HH:mm"),
          EndTime: moment(t.EndTime.toDate()).format("HH:mm"),
          id: t.id,
        };
      });
    },
    departmentChange(e) {
      console.log(
        "DEPARTMENT",
        this.tempDesignations.filter((t) => t["DepartmentID"] == e)
      );

      this.sorguTable({
        tablo: "Designation",

        kosul: [{ query: ["DepartmentID", "==", e] }],
      }).then((t) => {
        this.tempDesignations = t;
      });

      this.sorgu({
        tablo: "Designation",
        guncelle: "SetTempVeriDep",
        kosul: [{ query: ["DepartmentID", "==", e] }],
      });
    },
    searchBtn() {
      if (this.searchLeaveReport.startDate) {
        this.haftaGetir({
          tarih: new Date(this.searchLeaveReport.startDate),
        });
      } else {
        this.haftaGetir({ tarih: new Date() });
      }

      this.isTablosu({ sorgu: this.searchLeaveReport });
    },
    resetBtn() {
      this.searchLeaveReport = {};
      this.haftaGetir({ tarih: new Date() });
      this.isTablosu();
    },

    },
    mounted() {
    },
  }
</script>