<template>
  <div class="shiftlist">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
      <!-- Page Wrapper -->
      <div class="page-wrapper">

        <!-- Page Content -->
        <div class="content container-fluid">
          <div class="col-auto float-end ms-auto">
            <a href="#" class="btn add-btn m-r-5" data-bs-toggle="modal" data-bs-target="#add_shift">{{
          $t("Add Shifts")}}</a>
            <!-- <a href="#" class="btn add-btn m-r-5" data-bs-toggle="modal" data-bs-target="#add_schedule"> Assign Shifts</a>  -->
          </div>
          <!-- Page Header -->
          <div class="page-header">
            <div class="row">

              <div class="col">
                <h3 class="page-title">{{ $t("Shift List") }}</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/index">{{ $t("Dashboard") }}</router-link>
                  </li>
                  <li class="breadcrumb-item"><a href="#">{{ $t("Employees") }}</a></li>
                  <li class="breadcrumb-item active">{{ $t("Shift List") }}</li>
                </ul>
                <h5 style="color:coral">({{ $t("Firma vardiya ekleme ve düzenleme") }})</h5>
              </div>

            </div>

          </div>
          <!-- /Page Header -->


          <div id="add_shift" class="modal custom-modal fade" role="dialog">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">{{ $t("Add") }} {{ $t("Shift") }}</h5>
                  <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <form>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>{{ $t("Client Name") }} <span class="text-danger">*</span></label>
                          <div class="input-group form-focus select-focus">
                            <!-- <input v-model="shiftTable.Client" class="form-control"><span class="input-group-text"><i class="fa fa-clock-o"></i></span> -->
                            <!-- <Select2 name="clientslistData" v-model="shiftTable.Client" :options="getClientData"
                             width="150" placeholder="Please select company" /> -->

                            <Select2 class="select2-container select2-selection--single" name="clientslistData"
                              v-model="shiftTable.clientID" :options="
                                $store.getters.Getclient.map((t) => {
                                  return { text: t.ClientName, id: t.id };
                                })
                              " placeholder="Please select company" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>{{ $t("Shift Name") }} <span class="text-danger">*</span></label>
                          <div class="input-group time timepicker">
                            <input v-model="shiftTable.ShiftName" class="form-control"><span
                              class="input-group-text"></span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">


                          <label>{{ $t("Start Time") }} <span class="text-danger">*</span></label>
                          <div class="input-group">

                            <timeselector displayFormat="HH:mm a" class="form-control" v-model="shiftTable.StartTime">
                            </timeselector>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">
                          <label>{{ $t("End Time") }} <span class="text-danger">*</span></label>
                          <div class="input-group time timepicker">

                            <timeselector displayFormat="HH:mm a" class="form-control" v-model="shiftTable.EndTime">
                            </timeselector>
                          </div>
                        </div>
                      </div>


                      <div class="col-md-12">
                        <div class="form-group">
                          <label>{{ $t("Add Note") }} </label>
                          <textarea v-model="shiftTable.Note" class="form-control" rows="2"></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="submit-section">
                      <button class="btn btn-primary submit-btn"
                        @click.prevent="addShiftTable">{{ $t("Submit") }}</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <shifttable @shiftTable="$store.getters.GetShiftTable" @sendItem="getData" :datas="data" />

        </div>


        <!-- /Page Content  ssss-->

      </div>
      <!-- /Page Wrapper github-->


    </div>
  </div>
</template>
<script>

const currentDate = new Date()
const currentDate1 = new Date()

// import DatePicker from 'vue2-datepicker';
import Select2 from "v-select2-component";
import { mapGetters, mapActions } from 'vuex';

import Timeselector from 'vue-timeselector';
import 'bootstrap/dist/css/bootstrap.css'





export default {
  data() {
    return {


      data: {},
      classList: {},
      clients: {
        CompanyUid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
        title: ""
      },
      startdate: currentDate,
      enddate: currentDate1,
      shiftTable: {
        CompanyUid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
        // ShiftEndOn: "",
        Status: true,
        Days: [],
        clientID: "",
        editshift: {},

      }
    }
  },
  components: {//DatePicker, 
    Select2,
    Timeselector,


  },
  computed: {
    ...mapGetters(["Getclient"]),


    getClientData() {
      let arr = []
      this.Getclient.forEach(e => {
        arr.push(e.ClientName)
      })
      console.log("DDDD", arr);
      return [...new Set(arr)]

    },


  },
  methods: {
    ...mapActions(["AddData", "EditData", "DeleteData", "getShiftTable", "fetchdata", "sorgu", "Tanimlamalar"]),
    makeToast() {

      this.$bvToast.toast(`This is toast number `, {
        title: 'BootstrapVue Toast',
        autoHideDelay: 10000,

      })
    },


    getData(value) {
      console.log("Daaaa", value);
      this.data = value;
    },

    shiftTableAdd(e) {
      this.shiftTable = e;
    },


    editShift(h) {
      console.log(h);
      this.editshift = h;
    },

    /////EKLE
    addShiftTable() {
      function convert(str) {
        var date = new Date(str),

          hourss = date.getHours(),
          minutess = date.getMinutes();
        return [hourss, minutess].join(":");
      }

      console.log("SHİFTTABLE",this.shiftTable)
      // this.shiftTable.StartTime = convert(this.shiftTable.StartTime)
      // this.shiftTable.EndTime = convert(this.shiftTable.EndTime)

      this.AddData({ db: "ShiftTable", veri: this.shiftTable }).then(p => {
        $(".close").click()
        const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;

        this.sorgu({ tablo: "ShiftTable", guncelle: "SetShiftTable", kosul: [{ query: ["CompanyUid", "==", uid] }] })
        this.$toast.open({
          message: this.$t('Kunde Erfolgreich Registriert'),
          type: 'info',
        });
       });
      this.shiftTable.clientName = this.clientName



    },
    setOption() {
      let addclientfilter = [];
      this.getClientData.forEach((el) => {
        addclientfilter.push({ title: el.ClientName });
      });

    },





  },

  mounted() {
    this.setOption()
    this.getShiftTable()
    this.Tanimlamalar();
    const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
    this.sorgu({ tablo: "ShiftTable", guncelle: "SetShiftTable", kosul: [{ query: ["CompanyUid", "==", uid] }] })


  },
  name: 'shiftTable',
}
</script>

<style scoped>
.select2-container .select2-selection--single {
  border: 1px solid #e3e3e3;
  border-radius: 0.25rem;
  height: 44px;
  width: 230px;
}
</style>
