export const fnk={


    data(){

     return {
      filterPersonel:[],

     }
      
     
    },
watch:{

  tempPersonel(){

  },
},
    methods:{

      personelGorev(item)
            { 
               
          console.log("GELEN VERİ", item)
         
         

            },


        storeGetters(t,s,d) {
           
              try {

                return this.$store.getters[s].find((a) => a.id == t)[d];
              } catch (error) {
                return t;
              }
            },
            
            },
        
 



    }


