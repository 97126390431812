<template>
    <div class="employeedashboard">
        <div class="main-wrapper">
            <main-header></main-header>
            <sidebar></sidebar>
            <!-- Page Wrapper -->
            <div class="page-wrapper">
                <div class="content container-fluid">
                    <welcomeheader />
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                            <div class="card dash-widget">
                                <div class="card-body">
                                    <span class="dash-widget-icon"><i class="fa fa-thumb-tack"></i></span>
                                    <div class="dash-widget-info">
                                        <h3>{{$store.getters.GetLeaves.length}}</h3>
                                        <span> {{ $t("Leaves") }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                            <div class="card dash-widget">
                                <div class="card-body">
                                    <span class="dash-widget-icon"><i class="fa fa-diamond"></i></span>
                                    <div class="dash-widget-info">
                                        <h3>{{$store.getters.Getclient.length}}</h3>
                                        <span>{{ $t("Clients") }} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                            <div class="card dash-widget">
                                <div class="card-body">
                                    <span class="dash-widget-icon"><i class="fa fa-tasks"></i></span>
                                    <div class="dash-widget-info">
                                        <h3>{{$store.getters.GetSch.length}}</h3>
                                        <span>{{ $t("Active Jobs") }} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                            <div class="card dash-widget">
                                <div class="card-body">
                                    <span class="dash-widget-icon"><i class="fa fa-user"></i></span>
                                    <div class="dash-widget-info">
                                        <h3>{{$store.getters.GetEmployes.length}}</h3>
                                        <span>{{ $t("Employees") }}   </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    
                        <today />
                        <projects />
                    </div>
                </div>
                <!-- /Page Content -->
            </div>
            <!-- /Page Wrapper -->
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Welcomeheader from '@/views/pages/dashboard/employee/welcomeheader.vue'
import Projects from '@/views/pages/dashboard/employee/projects.vue'
import Today from '@/views/pages/dashboard/employee/today.vue'
export default {
    components: {
        Welcomeheader,
        Projects,
        Today,
    },
methods:{
   ...mapActions(["getClients"]) 
},
computed:{
...mapGetters(["sorgu","GetShiftTable","GetDepartment","GetEmployes","GetDesignation","Getclient"]),
shiftData()
{
return this.GetShiftTable;
},
departmentData()
{
    return this.GetDepartment;
},
employeeData()
{
    return this.GetEmployes;
},
designationData()
{
    return this.GetDesignation;
},
clientData()
{
    return this.Getclient;
}



},
    mounted() {
    },
    name: 'employeedashboard'
}
</script>