<template>
<div class="row filter-row mb-4">
            <!-- <div class="col-sm-6 col-md-3">  
              <div class="form-group form-focus select-focus">
        <Select2 name="clientslistData" v-model="clients" :options="getClientData"
          placeholder="Please select company" />

        <label class="focus-label">{{$t("Company")}}</label>
      </div>
            </div>
        
            <div class="col-sm-6 col-md-3">  
              <div class="form-group">
                         

                          <div>
                            <input
                              type="date"
                              
                              name="startdate"
                              class="picker"
                              placeholder="Please select start date"
                              :editable="true"
                              :clearable="false"
                            />
                          </div>
                        </div>
            </div>
            <div class="col-sm-6 col-md-3">  
              <div class="form-group">
                         

                         <div>
                           <input
                             type="date"
                             
                             name="enddate"
                             class="picker"
                             placeholder="Please select end date"
                             :editable="true"
                             :clearable="false"
                           />
                         </div>
                       </div>
            </div>
            <div class="col-sm-3 col-md-1">  
              <div class="d-grid">
                <a href="#" class="btn btn-success"> Search </a>  
              </div>
            </div>      -->
                    </div>
</template>
<script>
import Select2 from "v-select2-component";
 
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
   // Select2,
   
  },
  data() {
    return {

      clients: {
        CompanyUid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
        title: ""
      },


    }
  },
  computed: {
    ...mapGetters(["Getclient"]),
    getClientData() {
      let arr = []
      this.Getclient.forEach(e => {
        arr.push(e.ClientName)
      })
      return [...new Set(arr)]

    },

  },
  methods: {

    ...mapActions(["fetchdata"]),

    clientAra() {
      this.$emit("clientFilter", this.getClientData.filter(t => t.ClientName != -1))
    },

    setOption() {

      let addclientfilter = [];

      this.getClientData.forEach((el) => {
        addclientfilter.push({ title: el.ClientName });

      });
      console.log("MÜŞTERİLER", addclientfilter);
    }
  },

  mounted() {
  }
}
</script>