<template>
<div class="row">

            <div class="col-md-12">
              
              <div class="table-responsive">
                <table class="table table-striped custom-table mb-0 datatable" id="clientreports">
                  <thead>
                    <tr>
                      <th>{{$t("Name")}}</th>
                      
                      <th>{{$t("Contact Person")}}</th>
                      <th>{{$t("Email")}}</th>
                      <th>{{$t("Mobile")}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in GetClientData" :key="item.id">
                      <td>
                        <h2 class="table-avatar">
                          <router-link to="/client-profile" class="avatar"
                            ><img src="../../../../assets/img/profiles/client.jpg" alt=""
                          /></router-link>
                          <router-link to="/client-profile">{{
                            item.ClientName
                          }}</router-link>
                        </h2>
                      </td>
                       
                      <td>{{ item.FirstName +" "+item.LastName }}</td>
                      <td>{{ item.Email }}</td>
                      <td>{{ item.Phone }}</td>
                    </tr>
                    <!-- <tr v-for="item in dailyreporttable" :key="item.id">
                      <td>
                        <h2 class="table-avatar">
                          <router-link to="/profile" class="avatar"><img alt="" :src="loadImg(item.image)"></router-link>
                          <router-link to="/profile">{{item.name}} <span>{{item.no1}}</span></router-link>
                        </h2>
                      </td>
                      <td>{{item.date}}</td>
                      <td>{{item.department}}</td>
                      <td class="text-center">
                        <button class="btn btn-outline-info btn-sm">{{item.status}}</button>
                      </td>
                    </tr> -->
                  </tbody>
                </table>
              </div>
            </div>
          </div>
</template>
<script>
// import dailyreporttable from '../../../../assets/json/dailyreporttable.json';
// import util from '../../../../assets/utils/util'
/* eslint-disable */ 
import jsPDF from 'jspdf' 
import autoTable from 'jspdf-autotable'
import { mapActions,mapGetters } from "vuex";
  export default {
    data() {
    return {
      todos: [
        { title: 'Employee', description: "item.name" },
        { title: 'Date', description: "this.date" },
        { title: 'title 3', description: "this.department" },
        { title: 'Department', description: 'description 4' },
      ],
      // dailyreporttable: dailyreporttable
    }
  },
    components: {
      jsPDF,
      autoTable
    },
    methods: {
    ...mapActions(["fetchdata"]),  
   
    fetch() {
      this.fetchdata({ db: "Client", mt: "SetClient" });
    },
  

  },
    computed:
    {
    ...mapGetters(["Getclient"]),
GetClientData(){
return this.Getclient
}
},
    mounted() {
      
 
    },
    methods: {
    //     loadImg(imgPath) {
    //         return images('./' + imgPath).default
    // },
    },
    name: 'activities'
  }
</script>