<template>
  <!-- Page Header -->
          <div class="page-header">
            <div class="row">
              <div class="col">
                <h3 class="page-title">Leave Report</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/index">Dashboard</router-link></li>
                  <li class="breadcrumb-item active">Leave Report</li>
                </ul>
              </div>
              <div class="col-auto">
                <button class="btn btn-primary"  @click="generatePdf">PDF</button>
              </div>
            </div>
          </div>
          <!-- /Page Header -->
</template>


<script>
 import html2pdf from 'html2pdf.js';
import jsPDF from 'jspdf' 
import { jsontoexcel } from "vue-table-to-excel";
import autoTable, { Column } from 'jspdf-autotable'
import { mapActions,mapGetters } from 'vuex';
/* eslint-disable */
export default {
 data()
 {
   return{
   

   };
 },
 components:{
    
    
 },
 computed:{
    ...mapGetters(["GetCompany","GetLeaves"]),
GetComp(){
return this.GetCompany
},
GetLeavesData(){

return this.GetLeaves;
} 
 },

 methods:{
  PersonelGetir(t) {
 
 try {
   var data=this.$store.getters.GetEmployes.find((a) => a.id == t)
   return data.FirstName+ " "+ data.LastName;
 } catch (error) {
   return t;
 }
},
   async generatePdf(){
     
   
    const element = document.getElementById('leavereportcontent');
    const pdfOptions = {
        filename: 'leavereport.pdf',
        orientation: 'landscape'
      };
      html2pdf().from(element).set(pdfOptions).save();
   
//    const logo = this.GetComp[0].Picture 
//    var imgLogo = new Image()
//    imgLogo.src = logo        
//    var doc = new jsPDF('p', 'pt');
//    doc.setFontSize(12);
//    doc.setTextColor(0);
//    doc.addImage(imgLogo, 'PNG',   450, 5,100,80)
//    const date = new Date();
//    let day = date.getDate();
//    let month = date.getMonth() + 1;
//    let year = date.getFullYear();
//    let tarih = `${day}.${month}.${year}`;  
//    doc.text( tarih,40,25)
//    doc.text('Employee Leaves Report', 240, 50);   
//    var data =await this.getData(this.GetLeavesData.length);
//    // data.sort(function (a, b) {
//    //     return parseFloat(b.expenses) - parseFloat(a.expenses);
//    // });
//    doc.autoTable(this.getColumns(), data, {
//      columnStyles: {
//    0: {cellWidth: 21},
//    1: {cellWidth: 150},
//    2: {cellWidth: 120},
//    3: {cellWidth: 120},
//    4: {cellWidth: 104},
   
//  },
//        theme: 'grid',
//        startY: 90,
       
//        drawRow: function (row, data) {
         
//            doc.setFontSize(10);
//            if (row.index === 0) {
//                doc.setTextColor(199, 83, 0);
               
//            }
//          // data fazlaysa sayfa ekle
//            // if (row.index % 5 === 0) {
//            //     var posY = row.y + row.height * 6 + data.settings.margin.bottom;
//            //     if (posY > doc.internal.pageSize.height) {
//            //         data.addPage();
//            //     }
//            // }
//        },
       
//    });
//     doc.save('EmployeeLeavesReport.pdf');


},

 async getData(rowCount) {
  console.log("row",rowCount)
  console.log(this.GetLeaves)
   var data = [];
   for (var j = 0; j <= rowCount-1; j++) {
     
    // await Promise.all(  
       data.push({
           id: j+1,
           person: this.PersonelGetir(this.GetLeaves[j].Personel),           
           leavetype: this.GetLeaves[j].LeaveType,
           nodays: this.GetLeaves[j].Day,
           remainingValue:this.GetLeaves[j].remainingValue,
           
       })
      // );

     
   }
   
   return data;
},


getColumns() {
   return [
       {title: "No  ", dataKey: "id"},
       {title: "Employee", dataKey: "person"},
       {title: "Leave Type  ", dataKey: "leavetype"},
       {title: "No of Days  ", dataKey: "nodays"},
       {title: "Remaining Leave  ", dataKey: "remainingValue"},
      
   ];
},
       },
   
 }
 

</script>