<template>
  <div class="settings">
    <div class="main-wrapper">
      <main-header></main-header>
      <settingsidebar></settingsidebar>
       <!-- Page Wrapper -->
            <div class="page-wrapper">
            
                <!-- Page Content -->
                <div class="content container-fluid">
                    <div class="row">
                        <div class="col-md-8 offset-md-2">
                        
                            <!-- Page Header -->
                            <div class="page-header">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <h3 class="page-title">{{$t("Company")}} {{$t("Settings")}}</h3>
                                    </div>
                                </div>
                            </div>
                            <!-- /Page Header -->
                          
                            <form @submit.prevent="edit">
                                <div class="row">
                                    <div class="col-sm-12">
                  <div class="form-group">
                    <div class="row">
                    <div class="col-sm-3">
               
                      <!-- <label class="col-form-label">Picture <span class="text-danger">*</span></label> &nbsp; -->
                    <input name="picture" v-model="GetCompany.Picture" type="hidden">
                    <img   height="80" width="80" alt="" class="circle"  :src="GetCompany[0].Picture" >
                    
                    </div>
                    <div class="col-sm-9">
                    <br/>
                    <input width="50" type="file" class="form-control" @change="previewImage" accept="image/*">
                    </div>
                   
                   </div>
                  </div>
                </div>
                                    <!-- <div class="col-sm-12"> -->
                      <!-- <label class="col-form-label">Picture <span class="text-danger">*</span></label> &nbsp; -->
                    <!-- <input name="picture" v-model="GetComp.Picture" type="hidden">
                    <img :src="GetComp.Picture" height="80" width="80" alt="" class="circle">
                    </div> -->
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>{{$t("Company")}} {{$t("Name")}} <span class="text-danger">*</span></label>
                                            <input class="form-control" v-model="GetCompany[0].CompanyName " type="text" >
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>{{$t("Contact Person")}}</label>
                                            <input class="form-control" v-model="GetCompany[0].ContactPerson" type="text">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label>{{$t("Address")}}</label>
                                            <input class="form-control" v-model="GetCompany[0].Adress" type="text">
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3">
                                        <div class="form-group">
                                            <label>{{$t("Country")}}</label>
                                            <Select2 :options="countrysetting" v-model="GetCompany[0].Country" placeholder="Germany"/>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3">
                                        <div class="form-group">
                                            <label>{{$t("City")}}</label>
                                            <input class="form-control" v-model="GetCompany[0].City" type="text">
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3">
                                        <div class="form-group">
                                            <label>{{$t("State/Province")}}</label>
                                            <Select2 :options="statesetting" v-model="GetCompany[0].StateProvince" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-3">
                                        <div class="form-group">
                                            <label>{{$t("Postal Code")}}</label>
                                            <input class="form-control" v-model="GetCompany[0].PostalCode" type="text">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>{{$t("Email")}}</label>
                                            <input class="form-control" v-model="GetCompany[0].Email" type="email">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>{{$t("Phone Number")}}</label>
                                            <input class="form-control" v-model="GetCompany[0].Phone" type="text">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>{{$t("Mobile Number")}}</label>
                                            <input class="form-control" v-model="GetCompany[0].MobileNumber" type="text">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>{{$t("Fax")}}</label>
                                            <input class="form-control" v-model="GetCompany[0].Fax" type="text">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label>{{$t("Website Url")}}</label>
                                            <input class="form-control" v-model="GetCompany[0].WebsiteUrl" type="text">
                                        </div>
                                    </div>
                                </div>
                                <div class="submit-section">
                                    <button class="btn btn-primary submit-btn">{{$t("Save")}}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <!-- /Page Content -->
                
            </div>
            <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
import Select2 from 'v-select2-component'
   import {mapActions, mapGetters}  from 'vuex'
  export default {
    data() {
    return {
      countrysetting: ["Germany"],
      statesetting: ["Baden-Württemberg",
"Bavaria",
"Berlin",
"Brandenburg",
"Bremen",
"Hamburg",
"Hesse",
"Mecklenburg-Vorpommern",
"Lower Saxony",
"North Rhine-Westphalia",
"Rhineland-Palatinate",
"Saarland",
"Saxony-Anhalt",
"Saxony",
"Schleswig-Holstein",
"Thuringia"
]
    }
    },
    components: {
   Select2,
    }, 
 
    methods:{
        ...mapActions(["fetchdata","EditData","resimYukle","Tanimlamalar"]),
        async previewImage(event) {
      this.uploadValue = 0;
      this.picture = null;
      this.imageData = event.target.files[0];
      this.GetComp.Picture = await this.resimYukle(this.imageData)
    },
        fetch(){
this.fetchdata({db:"CompanySettings",mt:"SetCompany"})
        },
        edit(){
          
this.EditData({db:"CompanySettings",mt:"ChangeCompany",id:this.GetComp.id,data:this.GetComp})
        }
    },
    computed:{
        ...mapGetters(["GetCompany"]),
GetComp(){
return this.GetCompany
} 
    },
    mounted() { 
      //this.fetch()  
this.GetComp()
this.Tanimlamalar()

    },
    name: 'settings'
  }
</script>


 