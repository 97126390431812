<template><!-- Add Department Modal -->
  <div>

    <div id="add_department" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t("Add") }} {{ $t("Department") }}</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label>{{ $t("Department Name") }} <span class="text-danger">*</span></label>
                <input v-model="DepartmentData.DepartmentName" class="form-control" type="text">
              </div>
              <div class="submit-section">
                <button @click.prevent="addDep" class="btn btn-primary submit-btn">{{ $t("Submit") }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Department Modal -->

    <!-- Edit Department Modal -->
    <div id="edit_department" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t("Edit") }} {{ $t("Department") }}</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="editDep">
              <div class="form-group">
                <label>{{ $t("Department Name") }} <span class="text-danger">*</span></label>
                <input class="form-control" name="DepartmentName" v-model="DepartmentData.DepartmentName" type="text">
              </div>
              <div class="submit-section">
                <button class="btn btn-primary submit-btn">{{ $t("Save") }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Department Modal -->

    <!-- Delete Department Modal -->
    <div class="modal custom-modal fade" id="delete_department" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>{{ $t("Delete Department") }}</h3>
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{{ $t("Are you sure want to delete") }}?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">

                <div class="col-6" @click.prevent="deleteDep">
                  <a class="btn btn-primary continue-btn">{{ $t("Delete") }}</a>
                </div>
                <div class="col-6">
                  <a href="javascript:void(0);" data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn">{{ $t("Cancel") }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<!-- /Delete Department Modal --></template>
<script>
import { mapActions } from 'vuex'
import { jsonEval } from "@firebase/util";

export default {
  watch: {
    editdatas(veri) {
      this.DepartmentData = veri

    }
  },

  props: {
    editdatas: {
      type: Object,
    },
    deletedatas:
    {
      type: Object,
    },
    departman: {
      type: Array
    }
  },
  data() {
    return {
      Dept: {
        DepartmentName: "",
        CompanyUid: JSON.parse(localStorage.getItem("storedData")).CompanyUid
      },
      DepartmentData: {},
      EditDepartmanName: "this.editdatas.DepartmentName"
    }
  },
  methods: {
    ...mapActions(["DeleteData", "EditData", "AddData", "sorgu", "sorguRef", "sorguTable"]),
    async deleteDep() {
      const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
      let DepartmentRef = await this.sorguRef({ tablo: "Department", id: this.deletedatas.id })
      console.log("DepartmentRef", DepartmentRef)
      let DepartmentKontrol = await this.sorguTable({ tablo: "Employee", kosul: [{ query: ["CompanyUid", "==", uid] }, { query: ["DepartmentID", "array-contains", DepartmentRef] }] })
      console.log("DepartmentKontrol", DepartmentKontrol)
      if(DepartmentKontrol.length==0)
      {

         this.DeleteData({ db: "Department", mt: "DeleteDept", data: this.deletedatas })
      $(".close").click()
      this.sorgu({ tablo: "Department", guncelle: "SetDepartment", kosul: [{ query: ["CompanyUid", "==", uid] }] })
      }
      else{
        this.$toast.open({
        message: this.$t('Departmana kayıtlı personel var'),
        type: 'error',
      });
      $(".close").click()
      }
     




    },
    addDep() {
      this.DepartmentData.CompanyUid = JSON.parse(localStorage.getItem("storedData")).CompanyUid
      this.AddData({ db: "Department", veri: this.DepartmentData }).then(p => {
        $(".close").click()
        const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
        this.DepartmentData={}
        this.sorgu({ tablo: "Department", guncelle: "SetDepartment", kosul: [{ query: ["CompanyUid", "==", uid] }] })
        this.$toast.open({
          message: this.$t('Kunde Erfolgreich Registriert'),
          type: 'info',



        });
      });


    },
    editDep(e) {
      // const formData = new FormData(e.target);
      // const formProps = Object.fromEntries(formData);
      this.EditData({ db: "Department", data: this.DepartmentData, id: this.DepartmentData.id }).then(() => {
        this.sorgu({ tablo: "Department", guncelle: "SetDepartment" })
        $(".close").click()
        this.DepartmentData={}
      });

    }

  }
}
</script>
