<template>
  <div class="login account-page" style="height: 100vh !important">
    <!-- Main Wrapper -->

    <div class="main-wrapper">
      <div class="account-content">
        <div class="container">
          <!-- Account Logo -->
          <div class="account-logo">
            <router-link to="/index"><img src="../../../../assets/img/logo2.png" alt="Dreamguy's Technologies" />
            </router-link>
          </div>
          <!-- /Account Logo -->
          <div class="account-box">
            <div class="account-wrapper">
              <div class="alert alert-danger alert-dismissible " v-if="alert" role="alert">
                <strong>Uyari!</strong> Şifre yada E-mail yanlış
                <button type="button" class="btn btn-light btn-sm" @click="alert = !alert" data-dismiss="alert"
                  aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <h3 class="account-title">{{$t("Login")}}</h3>
              <p class="account-subtitle">Access to our dashboard</p>
              <!-- Account Form -->
              <form class="login" @submit.prevent="onSubmit">
                <div class="form-group">
                  <label>{{$t("Email Address")}}</label>
                  <validation-provider name="email" :rules="{ required: true }">
                    <template #default="{ errors }">
                      <input required class="form-control" v-model="email" />
                      <div class="emailshow text-danger" id="email">{{ errors[0] }}</div>
                    </template>
                  </validation-provider>
                </div>
                <div class="form-group password-icon">
                  <div class="row">
                    <div class="col">
                      <label>{{$t("Password")}}</label>
                    </div>
                    <div class="col-auto">
                      <router-link class="text-muted" to="/forgot-password">
                        {{$t("Forgot password")}}?
                      </router-link>
                    </div>
                  </div>

                  <validation-provider name="password" :rules="{ required: true }">
                    <template #default="{ errors }">
                      <input type="password" required class="form-control" v-model="password" />
                      <div class="emailshow text-danger" id="password">{{ errors[0] }}</div>
                    </template>
                  </validation-provider>
                  <div class="emailshow text-danger" id="password"></div>
                </div>
                <div class="form-group text-center">
                  <button class="btn btn-primary account-btn" type="submit" value="Login">
                    {{$t("Login")}}
                  </button>
                </div>
                <div class="account-footer">
                  <p>
                    {{$t("Don't have an account yet")}}?
                    <router-link to="/register">{{$t("Register")}}</router-link>
                  </p>
                </div>
              </form>

              <!-- /Account Form -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Main Wrapper -->
  </div>
</template>
<script>

import { ref } from "vue";
import { router } from "../../../../router";
import VueRouter from "vue-router";
import { useStore } from "vuex";
import { ValidationProvider } from "vee-validate";
import store from "@/store/index";
// import { initializeApp } from '@firebase/app';
import { conf } from "../../../../firebase/firebase.js";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { mapGetters, mapActions } from "vuex";
const auth = getAuth(conf);
import * as Yup from "yup";
export default {
  components: {

    ValidationProvider,
  },
  mounted() {
    // if ($(".toggle-password").length > 0) {
    //   $(document).on("click", ".toggle-password", function () {
    //     $(this).toggleClass("fa-eye fa-eye-slash");
    //     var input = $(".pass-input");
    //     if (input.attr("type") == "password") {
    //       input.attr("type", "text");
    //     } else {
    //       input.attr("type", "password");
    //     }
    //   });
    // }
  },
  data() {
    return {
      password: "",
      alert: false,
      email: "",
      schema: Yup.object().shape({
        email: Yup.string()
          .required("Email is required")
          .email("Email is invalid"),
        password: Yup.string()
          .min(6, "Password must be at least 6 characters")
          .required("Password is required"),
      }),
    };
  },
  methods: {
    ...mapActions(["Tanimlamalar"]),
    login() {
      console.log(this.password);
    },
girisBilgisi()
{
return ;
},
    onSubmit(values) { 

      console.log(values);
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          console.log(userCredential);
          let password =
          {
            email: this.email,
            token: userCredential.user.accessToken,
            CompanyUid: userCredential.user.uid,
          };

          const jsonData = JSON.stringify(password);
          localStorage.setItem("storedData", jsonData);
  
           this.Tanimlamalar();
          // ...
           this.$router.push("/index");
        })
        .catch((error) => {
          this.alert = true;
          const errorCode = error.code;
          const errorMessage = error.message;
        });  
        
   //
      // document.getElementById("email").innerHTML = ""
      // document.getElementById("password").innerHTML = ""
      // let data = localStorage.getItem('storedData');
      //   var Pdata= JSON.parse(data)
      //   const Eresult= Pdata.find(({ email }) => email === values.email);
      //  if (Eresult) {
      //   if (Eresult.password === values.password) {
      //   router.push('/index')
      //   } else {
      //       document.getElementById("password").innerHTML = "Incorrect password"
      //   }
      // } else {
      //       document.getElementById("email").innerHTML = "Email is not valid"
      // }
    },
  },
  // setup() {

  //   // let users = localStorage.getItem('storedData');
  //   //   if (users === null) {
  //   //     let password = [
  //   //       {
  //   //         email: 'admin@dreamguys.in',
  //   //         password: '123456',
  //   //       },
  //   //     ];
  //   //     const jsonData = JSON.stringify(password);
  //   //     localStorage.setItem('storedData', jsonData);
  //   //   }
  //      const

  //         return {
  //             schema,

  //         };

  //     }
};
</script>
<style>
</style>
