<template>
  <!-- <div id="app">   
    <div> -->
  <router-view></router-view>
  <!-- </div>
 </div> -->
</template>

<script>

export default {
  name: "App",
   mounted(){
  this.$store.dispatch("sayfaYetki");
  this.$store.dispatch("Tanimlamalar");

  
  setTimeout(() => {
  
    $('#sidebar-menu a').on('click', function (e) {
        if ($(this).parent().hasClass('submenu')) {
          e.preventDefault();
        }
        if (!$(this).hasClass('subdrop')) {
          $('ul', $(this).parents('ul:first')).slideUp(350);
          $('a', $(this).parents('ul:first')).removeClass('subdrop');
          $(this).next('ul').slideDown(350);
          $(this).addClass('subdrop');
        } else if ($(this).hasClass('subdrop')) {
          $(this).removeClass('subdrop');
          $(this).next('ul').slideUp(350);
        }
      });
      $('#sidebar-menu ul li.submenu a.active').parents('li:last').children('a:first').addClass('active').trigger('click');
   
  }, 2500);

  
}
};
</script>