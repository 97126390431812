<template>
  <div class="userdashboard">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
       <!-- Page Wrapper -->
      <div class="page-wrapper">
      
        <!-- Page Content -->
        <div class="content container-fluid">
          
          <!-- Page Header -->
          <div class="page-header">
            <div class="row">
              <div class="col-sm-12">
                <h3 class="page-title">{{$t("Job")}} {{$t("Dashboard")}}</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/index">{{$t("Dashboard")}}</router-link></li>
                  <li class="breadcrumb-item">{{$t("Jobs")}}</li>
                  
                </ul>
              </div>
            </div>
          </div>
          <!-- /Page Header -->
          
          <!-- Content Starts -->
          
              <jobheader /> 
          
          <userdashboardwidget />
<!--                 
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <usergraph />
                <userlatestjob />
              </div>
            </div>
          </div> -->

            
          <!-- <userlatestjobtable /> -->
      
          <userappliedjobtable />
        
          <!-- /Content End -->
          
        </div>
        <!-- /Page Content -->
        
      </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
     
  
  export default {
    components: {   
       
    },
    mounted() {
    },
    name: 'userdashboard'
  }
</script>