<template>
  <!-- Content Starts -->
  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table table-striped custom-table datatable" id="shifttable">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ $t("Client Name") }}</th>
              <th>{{ $t("Shift Name") }}</th>
              <th>{{ $t("Start Time") }}</th>
              <th>{{ $t("End Time") }}</th>

              <th>{{ $t("Note") }}</th>
              <!-- <th class="text-center">{{ $t("Status") }}</th> -->
              <th class="text-end no-sort">{{ $t("Action") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in GetShiftTable" :key="item.id">
              <td>{{ index+ 1}}</td>
              <td> {{ FirmaGetir(item.clientID) }}</td>
              <td>{{ item.ShiftName }}</td>
              <td>{{ item.StartTime.toDate() | moment("HH:mm") }}</td>
              <td>{{ item.EndTime.toDate() | moment("HH:mm") }}</td> 

              <td>{{ item.Note }}</td>
              <!-- <td class="text-center">
                <div class="action-label">
                  <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);">
                    <i class="fa fa-dot-circle-o text-success"></i> {{ $t("Active") }}
                  </a>
                </div>
              </td> -->
              <td class="text-end">
                <div class="dropdown dropdown-action">
                  <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i
                      class="material-icons">more_vert</i></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" id="editShift" href="#" @click="editShift(item)" data-bs-toggle="modal"
                      data-bs-target="#edit_shift"><i class="fa fa-pencil m-r-5"></i> {{ $t("Edit") }}</a>
                    <a class="dropdown-item" href="#" @click="$emit('sendItem', item)" data-bs-toggle="modal"
                      data-bs-target="#delete_employee"><i class="fa fa-trash-o m-r-5"></i> {{ $t("Delete") }}</a>
                  </div>
                </div>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>

    <!-- //////////EDİT SHİFT /////////// -->
    <div id="edit_shift" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t("Edit Shift") }}</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="editVeri">

             
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{ $t("Client Name") }} <span class="text-danger">*</span></label>
                    <div class="input-group  ">
                      <!-- <input :value="datas.Client" class="form-control"><span class="input-group-text"><i
                         class="fa fa-clock-o"></i></span> -->
                      <!-- <Select2 name="clientslistData"   v-model="shiftTable.Client" :options="getClientData" placeholder="Please select company" />
   -->
                      <Select2 class="select2-container select2-selection--single" name="clientslistData"
                        :value="EditShiftTable.clientID" :options="
                          $store.getters.Getclient.map((t) => {
                            return { text: t.ClientName, id: t.id };
                          })
                        " placeholder="Please select company" />

                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{ $t("Shift Name") }} <span class="text-danger">*</span></label>
                    <div class="input-group time timepicker">
                      <input v-model="EditShiftTable.ShiftName" class="form-control"><span class="input-group-text"><i
                          class="fa fa-clock-o"></i></span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{ $t("Start Time") }} <span class="text-danger">*</span></label>
                    <div class="input-group time timepicker">

                      <timeselector displayFormat="HH:mm a" class="form-control" v-model="EditShiftTable.StartTime">
                      </timeselector>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{ $t("End Time") }} <span class="text-danger">*</span></label>
                    <div class="input-group time timepicker">

                      <timeselector displayFormat="HH:mm a" class="form-control" v-model="EditShiftTable.EndTime">
                      </timeselector>
                    </div>
                  </div>
                </div>


                <div class="col-md-12">
                  <div class="form-group">
                    <label>{{ $t("Add Note") }} </label>
                    <textarea v-model="EditShiftTable.Note" class="form-control" rows="2"></textarea>
                  </div>
                </div>
              </div>
              <div class="submit-section">
                <button class="btn btn-primary submit-btn">{{ $t("Submit") }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /////////////////////////////// -->

    <!-- /////DELETE POPUP///////// -->
    <div class="modal custom-modal fade" id="delete_employee" role="dialog">
      <div class="modal-dialog modal-dialog-centered">

        <div class="modal-content">
          <div class="modal-body">

            <div class="form-header">
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"></button>
              <h3>{{ $t("Delete") }} {{ $t("Shift") }}</h3>
              <p>{{ $t("Are you sure want to delete") }}?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a @click.prevent="deleteVeri" class="btn btn-primary continue-btn">{{ $t("Delete") }}</a>
                </div>
                <div class="col-6">
                  <a href="javascript:void(0);" data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn">{{ $t("Cancel") }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ////////// -->


  </div>
  <!-- /Content End -->
</template>
<script>
import util from "../../../../assets/utils/util";
import Vue from "vue";
import DatePicker from 'vue2-datepicker';
import { mapActions, mapGetters } from "vuex";
import Timeselector from 'vue-timeselector';
import Select2 from "v-select2-component";
import moment from 'moment';

/* eslint-disable */
export default {
  props: ["datas"],
  data() {

    return {


      shiftTable: {},
      EditShiftTable: {
      }

    }
  },
  components: {
    DatePicker,
    Timeselector,
    Select2

  },
  watch: {
    shiftTable(e) {

    }

  },
  computed: {
    ...mapGetters(["GetShiftTable"]),
    getData() {
      return this.GetShiftTable;
    },
  },
  methods: {
    ...mapActions(["AddData", "EditData", "DeleteData", "getShiftTable", "fetchdata", "sorgu"]),


    editShift(d) {
      this.EditShiftTable = {...d};

      this.EditShiftTable["StartTime"] = d["StartTime"].toDate()
      this.EditShiftTable["EndTime"] = d["EndTime"].toDate()

    },


    FirmaGetir(t) {
      //  console.log("FİRMA BİLGİSİ İSTENİYOR",this.$store.getters.Getclient)
      try {
        return this.$store.getters.Getclient.find((a) => a.id == t).ClientName;
      } catch (error) {
        return t;
      }
    },
    editVeri(b) { 


      this.EditData({ db: "ShiftTable", data: this.EditShiftTable, id: this.EditShiftTable.id }).then(t => {
        $(".close").click()
        const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
        this.sorgu({ tablo: "ShiftTable", guncelle: "SetShiftTable", kosul: [{ query: ["CompanyUid", "==", uid] }] })
        this.$toast.open({
          message: this.$t('Kunde Erfolgreich Arrangiert'),
          type: 'warning',



        });
      })




    },

    /////////SİLME İŞLEMİ ////////////////////////
    deleteVeri() {

      this.DeleteData({ db: "ShiftTable", mt: "DeleteShiftTable", data: this.datas }).then(p => {
        $(".close").click()
        const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
        this.sorgu({ tablo: "ShiftTable", guncelle: "SetShiftTable", kosul: [{ query: ["CompanyUid", "==", uid] }] })
        this.$toast.open({
          message: this.$t('Kunde Erfolgreich Gelöscht'),
          type: 'danger',



        });
      })


    },

  },

  mounted() {

  },
};
</script>


