<template>
  <div class="taskreports">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
        <!-- Page Wrapper -->
        <div class="page-wrapper">
      
        <!-- Page Content -->
        <div class="content container-fluid">
       
         
          
          <!-- Page Header -->
          <div class="page-header">
            <div class="row">
              <div class="col">
                <h3 class="page-title">Task Reports</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/index">Dashboard</router-link></li>
                  <li class="breadcrumb-item active">Task Reports</li>
                </ul>
          
              </div>
              <div class="col-auto">
                <div id="excelFormat" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-xl " role="document">
        <div class="modal-content">
          <div class="modal-header">
             
          </div>
          <div class="modal-body">
            <form>
         
              <table border="0" cellpadding="0" cellspacing="0" id="sheet0" class="sheet0 gridlines">
        <col class="col0">
        <col class="col1">
        <col class="col2">
        <col class="col3">
        <col class="col4">
        <col class="col5">
        <col class="col6">
        <col class="col7">
        <col class="col8">
        <col class="col9">
        <tbody>
          <tr class="row0">
            <td class="column0 style147 s style148" rowspan="2">Firma</td>
            <td class="column1 style149 s style150" rowspan="2">Schicht</td>
            <td class="column2 style151 s style152" rowspan="2">Arbeitszeit</td>
            <td class="column3 style121 s style123" colspan="7">Schichtplan KW {{ $store.getters.GetWeek[0].month }}/{{$store.getters.GetWeek[0].year }}</td>
          </tr>
          <tr class="row1">
            <td class="column3 style1 s">Mon {{ $store.getters.GetWeek[0].day }}</td>
            <td class="column4 style1 s">Tue {{ $store.getters.GetWeek[1].day }}</td>
            <td class="column5 style1 s">Wed {{ $store.getters.GetWeek[2].day }}</td>
            <td class="column6 style1 s">Thu {{ $store.getters.GetWeek[3].day }}</td>
            <td class="column7 style1 s">Fri {{ $store.getters.GetWeek[4].day }}</td>
            <td class="column8 style1 s">Sat {{ $store.getters.GetWeek[5].day }}</td>
            <td class="column9 style35 s">Sun {{ $store.getters.GetWeek[6].day }}</td>
          </tr>
          <tr class="row2"  >
            <td class="column0 style124 s style127" rowspan="15">{{ $store.getters.Getclient[4].ClientName }}</td>
            <td class="column1 style128 s style129" rowspan="7">{{ $store.getters.GetShiftTable[10].ShiftName }}</td>
            <td class="column2 style130 s style131" rowspan="7">{{ $store.getters.GetShiftTable[10].StartTime.toDate()
                            | moment("HH:mm") }}-{{$store.getters.GetShiftTable[10].EndTime.toDate()
                            | moment("HH:mm") }}</td>
            <td class="column3 style14 null" >{{ $store.getters.GetSch[0].FirstName + " "+$store.getters.GetSch[0].LastName}} </td>
            <td class="column4 style137 null style139" rowspan="7">  </td>
            <td class="column5 style14 null"></td>
            <td class="column6 style14 null"></td>
            <td class="column7 style14 null"></td>
            <td class="column8 style14 null"></td>
            <td class="column9 style15 null"></td>
          </tr>
          <tr class="row3">
            <td class="column3 style16 null">A</td>
            <td class="column5 style16 null">B</td>
            <td class="column6 style16 null"></td>
            <td class="column7 style16 null"></td>
            <td class="column8 style16 null"></td>
            <td class="column9 style17 null"></td>
          </tr>
          <tr class="row4">
            <td class="column3 style16 null"></td>
            <td class="column5 style16 null"></td>
            <td class="column6 style16 null"></td>
            <td class="column7 style16 null"></td>
            <td class="column8 style16 null"></td>
            <td class="column9 style17 null"></td>
          </tr>
          <tr class="row5">
            <td class="column3 style16 null"></td>
            <td class="column5 style16 null"></td>
            <td class="column6 style16 null"></td>
            <td class="column7 style16 null"></td>
            <td class="column8 style16 null"></td>
            <td class="column9 style17 null"></td>
          </tr>
          <tr class="row6">
            <td class="column3 style16 null"></td>
            <td class="column5 style16 null"></td>
            <td class="column6 style16 null"></td>
            <td class="column7 style16 null"></td>
            <td class="column8 style16 null"></td>
            <td class="column9 style17 null"></td>
          </tr>
          <tr class="row7">
            <td class="column3 style16 null"></td>
            <td class="column5 style16 null"></td>
            <td class="column6 style16 null"></td>
            <td class="column7 style16 null"></td>
            <td class="column8 style16 null"></td>
            <td class="column9 style17 null"></td>
          </tr>
          <tr class="row8">
            <td class="column3 style16 null"></td>
            <td class="column5 style16 null"></td>
            <td class="column6 style16 null"></td>
            <td class="column7 style16 null"></td>
            <td class="column8 style16 null"></td>
            <td class="column9 style17 null"></td>
          </tr>
          <tr class="row9">
            <td class="column1 style132 s style129" rowspan="4">{{ $store.getters.GetShiftTable[0].ShiftName }}</td>
            <td class="column2 style133 s style134" rowspan="4">{{ $store.getters.GetShiftTable[0].StartTime.toDate()
                            | moment("HH:mm") }}-{{ $store.getters.GetShiftTable[0].EndTime.toDate()
                            | moment("HH:mm") }}</td>
            <td class="column3 style18 null"></td>
            <td class="column4 style135 null style136" rowspan="4"></td>
            <td class="column5 style18 null"></td>
            <td class="column6 style18 null"></td>
            <td class="column7 style18 null"></td>
            <td class="column8 style18 null"></td>
            <td class="column9 style19 null"></td>
          </tr>
          <tr class="row10">
            <td class="column3 style16 null"></td>
            <td class="column5 style16 null"></td>
            <td class="column6 style16 null"></td>
            <td class="column7 style16 null"></td>
            <td class="column8 style16 null"></td>
            <td class="column9 style17 null"></td>
          </tr>
          <tr class="row11">
            <td class="column3 style16 null"></td>
            <td class="column5 style16 null"></td>
            <td class="column6 style16 null"></td>
            <td class="column7 style16 null"></td>
            <td class="column8 style16 null"></td>
            <td class="column9 style17 null"></td>
          </tr>
          <tr class="row12">
            <td class="column3 style16 null"></td>
            <td class="column5 style16 null"></td>
            <td class="column6 style16 null"></td>
            <td class="column7 style16 null"></td>
            <td class="column8 style16 null"></td>
            <td class="column9 style17 null"></td>
          </tr>
          <tr class="row13">
            <td class="column1 style132 s style129" rowspan="4">{{ $store.getters.GetShiftTable[13].ShiftName }}</td>
            <td class="column2 style140 s style141" rowspan="4">{{ $store.getters.GetShiftTable[13].StartTime.toDate()
                            | moment("HH:mm") }}-{{ $store.getters.GetShiftTable[13].EndTime.toDate()
                            | moment("HH:mm") }}</td>
            <td class="column3 style18 null"></td>
            <td class="column4 style18 null"></td>
            <td class="column5 style18 null"></td>
            <td class="column6 style18 null"></td>
            <td class="column7 style18 null"></td>
            <td class="column8 style18 null"></td>
            <td class="column9 style19 null"></td>
          </tr>
          <tr class="row14">
            <td class="column3 style36 null"></td>
            <td class="column4 style36 null"></td>
            <td class="column5 style36 null"></td>
            <td class="column6 style36 null"></td>
            <td class="column7 style36 null"></td>
            <td class="column8 style36 null"></td>
            <td class="column9 style37 null"></td>
          </tr>
          <tr class="row15">
            <td class="column3 style36 null"></td>
            <td class="column4 style36 null"></td>
            <td class="column5 style16 null"></td>
            <td class="column6 style16 null"></td>
            <td class="column7 style16 null"></td>
            <td class="column8 style16 null"></td>
            <td class="column9 style17 null"></td>
          </tr>
          <tr class="row16">
            <td class="column3 style20 null"></td>
            <td class="column4 style20 null"></td>
            <td class="column5 style22 null"></td>
            <td class="column6 style22 null"></td>
            <td class="column7 style22 null"></td>
            <td class="column8 style22 null"></td>
            <td class="column9 style24 null"></td>
          </tr>
          <tr class="row17">
            <td class="column0 style161 s style163" rowspan="10">{{ $store.getters.Getclient[3].ClientName }}</td>
            <td class="column1 style153 s style154" rowspan="7">{{ $store.getters.GetShiftTable[7].ShiftName }}</td>
            <td class="column2 style27 n">1</td>
            <td class="column3 style4 null"></td>
            <td class="column4 style4 null"></td>
            <td class="column5 style4 null"></td>
            <td class="column6 style4 null"></td>
            <td class="column7 style4 null"></td>
            <td class="column8 style4 null"></td>
            <td class="column9 style6 null"></td>
          </tr>
          <tr class="row18">
            <td class="column2 style28 n">2</td>
            <td class="column3 style2 null"></td>
            <td class="column4 style2 null"></td>
            <td class="column5 style2 null"></td>
            <td class="column6 style2 null"></td>
            <td class="column7 style2 null"></td>
            <td class="column8 style2 null"></td>
            <td class="column9 style7 null"></td>
          </tr>
          <tr class="row19">
            <td class="column2 style28 n">3</td>
            <td class="column3 style2 null"></td>
            <td class="column4 style2 null"></td>
            <td class="column5 style2 null"></td>
            <td class="column6 style2 null"></td>
            <td class="column7 style2 null"></td>
            <td class="column8 style2 null"></td>
            <td class="column9 style7 null"></td>
          </tr>
          <tr class="row20">
            <td class="column2 style28 n">4</td>
            <td class="column3 style2 null"></td>
            <td class="column4 style2 null"></td>
            <td class="column5 style2 null"></td>
            <td class="column6 style2 null"></td>
            <td class="column7 style2 null"></td>
            <td class="column8 style2 null"></td>
            <td class="column9 style7 null"></td>
          </tr>
          <tr class="row21">
            <td class="column2 style28 n">5</td>
            <td class="column3 style2 null"></td>
            <td class="column4 style2 null"></td>
            <td class="column5 style2 null"></td>
            <td class="column6 style2 null"></td>
            <td class="column7 style2 null"></td>
            <td class="column8 style2 null"></td>
            <td class="column9 style7 null"></td>
          </tr>
          <tr class="row22">
            <td class="column2 style28 n">6</td>
            <td class="column3 style2 null"></td>
            <td class="column4 style2 null"></td>
            <td class="column5 style5 null"></td>
            <td class="column6 style5 null"></td>
            <td class="column7 style5 null"></td>
            <td class="column8 style5 null"></td>
            <td class="column9 style9 null"></td>
          </tr>
          <tr class="row23">
            <td class="column2 style29 n">7</td>
            <td class="column3 style5 null"></td>
            <td class="column4 style5 null"></td>
            <td class="column5 style5 null"></td>
            <td class="column6 style5 null"></td>
            <td class="column7 style5 null"></td>
            <td class="column8 style5 null"></td>
            <td class="column9 style9 null"></td>
          </tr>
          <tr class="row24">
            <td class="column1 style142 s style144" rowspan="3">{{ $store.getters.GetShiftTable[2].ShiftName }}</td>
            <td class="column2 style32 n">1</td>
            <td class="column3 style13 null"></td>
            <td class="column4 style13 null"></td>
            <td class="column5 style4 null"></td>
            <td class="column6 style4 null"></td>
            <td class="column7 style4 null"></td>
            <td class="column8 style4 null"></td>
            <td class="column9 style6 null"></td>
          </tr>
          <tr class="row25">
            <td class="column2 style28 n">2</td>
            <td class="column3 style3 null"></td>
            <td class="column4 style3 null"></td>
            <td class="column5 style2 null"></td>
            <td class="column6 style2 null"></td>
            <td class="column7 style2 null"></td>
            <td class="column8 style2 null"></td>
            <td class="column9 style7 null"></td>
          </tr>
          <tr class="row26">
            <td class="column2 style33 n">3</td>
            <td class="column3 style11 null"></td>
            <td class="column4 style11 null"></td>
            <td class="column5 style8 null"></td>
            <td class="column6 style8 null"></td>
            <td class="column7 style8 null"></td>
            <td class="column8 style8 null"></td>
            <td class="column9 style10 null"></td>
          </tr>
          <tr class="row27">
            <td class="column0 style155 s style160" colspan="3" rowspan="2">{{ $store.getters.Getclient[2].ClientName }}</td>
            <td class="column3 style106 null style107" rowspan="2"></td>
            <td class="column4 style106 null style107" rowspan="2"></td>
            <td class="column5 style30 null"></td>
            <td class="column6 style30 null"></td>
            <td class="column7 style30 null"></td>
            <td class="column8 style30 null"></td>
            <td class="column9 style31 null"></td>
          </tr>
          <tr class="row28">
            <td class="column5 style25 null"></td>
            <td class="column6 style25 null"></td>
            <td class="column7 style25 null"></td>
            <td class="column8 style25 null"></td>
            <td class="column9 style26 null"></td>
          </tr>
          <tr class="row29">
            <td class="column0 style108 s style113" colspan="3" rowspan="9">{{ $store.getters.Getclient[5].ClientName }}</td>
            <td class="column3 style114 null style115" rowspan="9"></td>
            <td class="column4 style116 null style117" rowspan="9"></td>
            <td class="column5 style44 null"></td>
            <td class="column6 style45 null"></td>
            <td class="column7 style43 null"></td>
            <td class="column8 style38 null"></td>
            <td class="column9 style77 null"></td>
          </tr>
          <tr class="row30">
            <td class="column5 style46 null"></td>
            <td class="column6 style45 null"></td>
            <td class="column7 style43 null"></td>
            <td class="column8 style39 null"></td>
            <td class="column9 style78 null"></td>
          </tr>
          <tr class="row31">
            <td class="column5 style42 null"></td>
            <td class="column6 style45 null"></td>
            <td class="column7 style76 null"></td>
            <td class="column8 style39 null"></td>
            <td class="column9 style40 null"></td>
          </tr>
          <tr class="row32">
            <td class="column5 style42 null"></td>
            <td class="column6 style42 null"></td>
            <td class="column7 style43 null"></td>
            <td class="column8 style39 null"></td>
            <td class="column9 style40 null"></td>
          </tr>
          <tr class="row33">
            <td class="column5 style42 null"></td>
            <td class="column6 style42 null"></td>
            <td class="column7 style45 null"></td>
            <td class="column8 style39 null"></td>
            <td class="column9 style40 null"></td>
          </tr>
          <tr class="row34">
            <td class="column5 style42 null"></td>
            <td class="column6 style43 null"></td>
            <td class="column7 style42 null"></td>
            <td class="column8 style39 null"></td>
            <td class="column9 style40 null"></td>
          </tr>
          <tr class="row35">
            <td class="column5 style42 null"></td>
            <td class="column6 style43 null"></td>
            <td class="column7 style43 null"></td>
            <td class="column8 style39 null"></td>
            <td class="column9 style40 null"></td>
          </tr>
          <tr class="row36">
            <td class="column5 style42 null"></td>
            <td class="column6 style45 null"></td>
            <td class="column7 style45 null"></td>
            <td class="column8 style39 null"></td>
            <td class="column9 style40 null"></td>
          </tr>
          <tr class="row37">
            <td class="column5 style45 null"></td>
            <td class="column6 style42 null"></td>
            <td class="column7 style42 null"></td>
            <td class="column8 style41 null"></td>
            <td class="column9 style40 null"></td>
          </tr>
          <tr class="row38">
            <td class="column0 style118 s style120" colspan="3">{{ $store.getters.Getclient[1].ClientName }}</td>
            <td class="column3 style79 null"></td>
            <td class="column4 style79 null"></td>
            <td class="column5 style80 null"></td>
            <td class="column6 style80 null"></td>
            <td class="column7 style80 null"></td>
            <td class="column8 style80 null"></td>
            <td class="column9 style81 null"></td>
          </tr>
          <tr class="row39">
            <td class="column0 style85 s style90" colspan="2" rowspan="12">{{ $store.getters.Getclient[0].ClientName }}</td>
            <td class="column2 style91 s style92" rowspan="2">{{ $store.getters.GetShiftTable[11].ShiftName }}</td>
            <td class="column3 style49 null"></td>
            <td class="column4 style93 null style94" rowspan="2"></td>
            <td class="column5 style49 null"></td>
            <td class="column6 style49 null"></td>
            <td class="column7 style49 null"></td>
            <td class="column8 style49 null"></td>
            <td class="column9 style52 null"></td>
          </tr>
          <tr class="row40">
            <td class="column3 style50 null"></td>
            <td class="column5 style50 null"></td>
            <td class="column6 style50 null"></td>
            <td class="column7 style50 null"></td>
            <td class="column8 style50 null"></td>
            <td class="column9 style53 null"></td>
          </tr>
          <tr class="row41">
            <td class="column2 style95 s style92" rowspan="2">{{ $store.getters.GetShiftTable[5].ShiftName }}</td>
            <td class="column3 style51 null"></td>
            <td class="column4 style83 null style84" rowspan="2"></td>
            <td class="column5 style51 null"></td>
            <td class="column6 style51 null"></td>
            <td class="column7 style51 null"></td>
            <td class="column8 style51 null"></td>
            <td class="column9 style51 null"></td>
          </tr>
          <tr class="row42">
            <td class="column3 style60 null"></td>
            <td class="column5 style60 null"></td>
            <td class="column6 style60 null"></td>
            <td class="column7 style60 null"></td>
            <td class="column8 style60 null"></td>
            <td class="column9 style61 null"></td>
          </tr>
          <tr class="row43">
            <td class="column2 style145 s style146" rowspan="2">{{ $store.getters.GetShiftTable[3].ShiftName }}</td>
            <td class="column3 style62 null"></td>
            <td class="column4 style62 null"></td>
            <td class="column5 style64 null"></td>
            <td class="column6 style64 null"></td>
            <td class="column7 style64 null"></td>
            <td class="column8 style64 null"></td>
            <td class="column9 style66 null"></td>
          </tr>
          <tr class="row44">
            <td class="column3 style59 null"></td>
            <td class="column4 style59 null"></td>
            <td class="column5 style65 null"></td>
            <td class="column6 style65 null"></td>
            <td class="column7 style65 null"></td>
            <td class="column8 style65 null"></td>
            <td class="column9 style67 null"></td>
          </tr>
          <tr class="row45">
            <td class="column2 style96 s style98" rowspan="6">{{ $store.getters.GetShiftTable[1].ShiftName }}</td>
            <td class="column3 style47 null"></td>
            <td class="column4 style47 null"></td>
            <td class="column5 style63 null"></td>
            <td class="column6 style63 null"></td>
            <td class="column7 style63 null"></td>
            <td class="column8 style63 null"></td>
            <td class="column9 style57 null"></td>
          </tr>
          <tr class="row46">
            <td class="column3 style48 null"></td>
            <td class="column4 style48 null"></td>
            <td class="column5 style48 null"></td>
            <td class="column6 style48 null"></td>
            <td class="column7 style48 null"></td>
            <td class="column8 style48 null"></td>
            <td class="column9 style58 null"></td>
          </tr>
          <tr class="row47">
            <td class="column3 style42 null"></td>
            <td class="column4 style42 null"></td>
            <td class="column5 style42 null"></td>
            <td class="column6 style42 null"></td>
            <td class="column7 style42 null"></td>
            <td class="column8 style42 null"></td>
            <td class="column9 style68 null"></td>
          </tr>
          <tr class="row48">
            <td class="column3 style42 null"></td>
            <td class="column4 style42 null"></td>
            <td class="column5 style54 null"></td>
            <td class="column6 style34 null"></td>
            <td class="column7 style54 null"></td>
            <td class="column8 style54 null"></td>
            <td class="column9 style55 null"></td>
          </tr>
          <tr class="row49">
            <td class="column3 style42 null"></td>
            <td class="column4 style42 null"></td>
            <td class="column5 style54 null"></td>
            <td class="column6 style34 null"></td>
            <td class="column7 style54 null"></td>
            <td class="column8 style54 null"></td>
            <td class="column9 style55 null"></td>
          </tr>
          <tr class="row50">
            <td class="column3 style69 null"></td>
            <td class="column4 style69 null"></td>
            <td class="column5 style54 null"></td>
            <td class="column6 style34 null"></td>
            <td class="column7 style54 null"></td>
            <td class="column8 style54 null"></td>
            <td class="column9 style56 null"></td>
          </tr>
          <tr class="row51">
            <td class="column0 style99 s style104" colspan="3" rowspan="2">{{ $store.getters.Getclient[0].ClientName }}</td>
            <td class="column3 style70 null"></td>
            <td class="column4 style70 null"></td>
            <td class="column5 style72 null"></td>
            <td class="column6 style72 null"></td>
            <td class="column7 style72 null"></td>
            <td class="column8 style72 null"></td>
            <td class="column9 style74 null"></td>
          </tr>
          <tr class="row52">
            <td class="column3 style71 null"></td>
            <td class="column4 style71 null"></td>
            <td class="column5 style73 null"></td>
            <td class="column6 style73 null"></td>
            <td class="column7 style73 null"></td>
            <td class="column8 style73 null"></td>
            <td class="column9 style75 null"></td>
          </tr>
          <tr class="row53">
            <td class="column0 style105 null style105" colspan="10"></td>
          </tr>
          <tr class="row54">
            <td class="column0 style179 s style179" colspan="10">Bei Änderung bitte die zuständige Person kontaktieren. Änderungen vorbehalten.</td>
          </tr>
          <tr class="row55">
            <td class="column0 style105 null style105" colspan="10"></td>
          </tr>
          <tr class="row56">
            <td class="column0 style177 s style177" colspan="10">Urlaub</td>
          </tr>
          <tr class="row57">
            <td class="column0 style174 s style174" colspan="3">Vorname</td>
            <td class="column3 style164 null"></td>
            <td class="column4 style164 null"></td>
            <td class="column5 style175 s">Name</td>
            <td class="column6 style164 null"></td>
            <td class="column7 style164 null"></td>
            <td class="column8 style176 s">Zeitraum</td>
            <td class="column9 style164 null"></td>
          </tr>
          <tr class="row58">
            <td class="column0 style176 null"></td>
            <td class="column1 style176 null"></td>
            <td class="column2 style176 null"></td>
            <td class="column3 style164 null"></td>
            <td class="column4 style165 null"></td>
            <td class="column5 style175 null"></td>
            <td class="column6 style164 null"></td>
            <td class="column7 style164 null"></td>
            <td class="column8 style176 null"></td>
            <td class="column9 style164 null"></td>
          </tr>
          <tr class="row59">
            <td class="column0 style166 null style166" colspan="3"></td>
            <td class="column3 style167 null"></td>
            <td class="column4 style167 null"></td>
            <td class="column5 style164 null"></td>
            <td class="column6 style164 null"></td>
            <td class="column7 style164 null"></td>
            <td class="column8 style164 null"></td>
            <td class="column9 style164 null"></td>
          </tr>
          <tr class="row60">
            <td class="column0 style168 null style168" colspan="3"></td>
            <td class="column3 style169 null"></td>
            <td class="column4 style169 null"></td>
            <td class="column5 style164 null"></td>
            <td class="column6 style164 null"></td>
            <td class="column7 style164 null"></td>
            <td class="column8 style164 null"></td>
            <td class="column9 style164 null"></td>
          </tr>
          <tr class="row61">
            <td class="column0 style178 s style178" colspan="10">Krank</td>
          </tr>
          <tr class="row62">
            <td class="column0 style174 s style174" colspan="3">Vorname</td>
            <td class="column3 style164 null"></td>
            <td class="column4 style164 null"></td>
            <td class="column5 style175 s">Name</td>
            <td class="column6 style164 null"></td>
            <td class="column7 style164 null"></td>
            <td class="column8 style176 s">Zeitraum</td>
            <td class="column9 style164 null"></td>
          </tr>
          <tr class="row63">
            <td class="column0 style170 null style170" colspan="3"></td>
            <td class="column3 style171 null"></td>
            <td class="column4 style172 null"></td>
            <td class="column5 style164 null"></td>
            <td class="column6 style164 null"></td>
            <td class="column7 style164 null"></td>
            <td class="column8 style164 null"></td>
            <td class="column9 style164 null"></td>
          </tr>
          <tr class="row64">
            <td class="column0 style170 null style170" colspan="3"></td>
            <td class="column3 style173 null"></td>
            <td class="column4 style172 null"></td>
            <td class="column5 style164 null"></td>
            <td class="column6 style164 null"></td>
            <td class="column7 style164 null"></td>
            <td class="column8 style164 null"></td>
            <td class="column9 style164 null"></td>
          </tr>
          <tr class="row65">
            <td class="column0 style170 null style170" colspan="3"></td>
            <td class="column3 style173 null"></td>
            <td class="column4 style172 null"></td>
            <td class="column5 style164 null"></td>
            <td class="column6 style164 null"></td>
            <td class="column7 style164 null"></td>
            <td class="column8 style164 null"></td>
            <td class="column9 style164 null"></td>
          </tr>
          <tr class="row66">
            <td class="column0 style82 null style82" colspan="3"></td>
            <td class="column3 style21 null"></td>
            <td class="column4 style23 null"></td>
            <td class="column5">&nbsp;</td>
            <td class="column6">&nbsp;</td>
            <td class="column7">&nbsp;</td>
            <td class="column8">&nbsp;</td>
            <td class="column9">&nbsp;</td>
          </tr>
        </tbody>
    </table>
   


            </form>
          </div>
        </div>
      </div>
    </div> 
   
                <button class="btn btn-primary" @click="generatePdf"><i class="fa fa-file-pdf-o"></i> PDF</button> &nbsp;   
                <!-- <a class="btn btn-success" href="#" data-bs-toggle="modal" data-bs-target="#excelFormat" ><i class="fa fa-file-excel-o"></i> EXCEL</a> -->
                <!-- <button class="btn btn-success" @click="fnExcelReport">Excel Format</button> -->
                <!-- <button   class="btn btn-success" @click="generateExcel">EXCEL</button> -->
              </div>
            </div>
          </div>
          <!-- /Page Header -->

          <!-- <taskreportfilter /> -->
          
           
 
           
 
          <taskreporttable />
          
          </div>
        
      </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>

import jsPDF from 'jspdf'  
import html2pdf from 'html2pdf.js';
import moment from 'moment';
import autoTable from 'jspdf-autotable'
/* eslint-disable */
import { mapActions,mapGetters } from "vuex";
  export default {
  components: {
    html2pdf,
    },
    computed:
    {
    ...mapGetters(["GetCompany","GetDailySchedule","Getclient","GetSch"]),
GetScheduleData(){
return this.GetDailySchedule
},
GetComp(){
return this.GetCompany
},
GetClientData(){
return this.Getclient
},
},
    methods:{
      ...mapActions([
       
      "sorgu",
      "Tanimlamalar",
      "isTablosu",
      "haftaGetir",
    ]),
      async generatePdf(){
        const element = document.getElementById('taskreports');
    const pdfOptions = {
        filename: 'taskreports.pdf',
        orientation: 'landscape'
      };
      html2pdf().from(element).set(pdfOptions).save();
  //   const logo = this.$store.getters.GetCompany[0].Picture
  
  //   var imgLogo = new Image()
  //   imgLogo.src = logo   
    
  //   var doc = new jsPDF('p', 'pt');
   
  //   doc.setFontSize(12);
  //   doc.setTextColor(0);
   
    
  //    doc.addImage(imgLogo, 'PNG',450, 5,100,80)
  
  //   const date = new Date();
  //   let day = date.getDate();
  //   let month = date.getMonth() + 1;
  //   let year = date.getFullYear();
  //   let tarih = `${day}.${month}.${year}`;  
  //   doc.text( tarih,40,25)
  //   doc.text('Employee Task Report', 240, 50);  
   
  //   var data =  this.getData(this.$store.getters.GetDailySchedule.length);
  //   console.log("DATA",data)
  //   // data.sort(function (a, b) {
  //   //     return parseFloat(b.expenses) - parseFloat(a.expenses);
  //   // });
  //   console.log(data)
  //   doc.autoTable(this.getColumns(), data, {
  //     columnStyles: {
  //   0: {cellWidth: 21}, //NO
  //   1: {cellWidth: 100},
  //   2: {cellWidth: 120},
  //   3: {cellWidth: 75},
  //   4: {cellWidth: 60},
  //   5: {cellWidth: 60},
  //   6: {cellWidth: 40},
  //   7: {cellWidth: 40},
    
  // },
  //       theme: 'grid',
  //       startY: 90,
        
  //       drawRow: function (row, data) {
          
  //           doc.setFontSize(10);
  //           if (row.index === 0) {
  //               doc.setTextColor(199, 83, 0);
                
  //           }
	// 				// data fazlaysa sayfa ekle
  //           // if (row.index % 5 === 0) {
  //           //     var posY = row.y + row.height * 6 + data.settings.margin.bottom;
  //           //     if (posY > doc.internal.pageSize.height) {
  //           //         data.addPage();
  //           //     }
  //           // }
  //       },
        
  //   });
  //    doc.save('EmployeeTaskReport.pdf');


},


ayIsimleri(a) {
      var months = new Array();
      months[1] = "JAN";
      months[2] = "FEB";
      months[3] = "MRZ";
      months[4] = "APR";
      months[5] = "MAİ";
      months[6] = "JUN";
      months[7] = "JUL";
      months[8] = "AUG";
      months[9] = "SEP";
      months[10] = "OKT";
      months[11] = "NOV";
      months[12] = "DEZ";

      return months[a];
    },
mesaiGetir( ){

  var data=$store.getters.GetSch;
  console.log(data)

},
 
 
format_date(value) {
  if (value) {
    return moment(String(value)).format('DD-MM-YYYY')
  }
},
DesignationsGetir(g) {
      try {
    
        return this.$store.getters.GetDesignation.find((m) => m.id == g)
          .DesignationName;
      } catch (error) {
        return g;
     
      }
  
    },
    clientGetir(e) {
      try {
        return this.$store.getters.Getclient.find((a) => a.id == e).ClientName;

      }
      catch (error) {
        return e;
      }
    },
  async getData(rowCount) {
      console.log(rowCount)
      var data = [];
      for (var j = 0; j <= rowCount - 1; j++) {

        await Promise.all(
          data.push({
            id: j + 1,
            person: this.GetDailySchedule[j].FirstName + " " + this.GetDailySchedule[j].LastName,
            client: this.clientGetir(this.GetDailySchedule[j].clientID),
            contactPerson: this.GetDailySchedule[j].FirstName + " " + this.GetDailySchedule[j].LastName,
            designation: this.DesignationsGetir(this.GetDailySchedule[j].DesignationID),
            startdate: this.format_date(this.GetDailySchedule[j].startDate.toDate() || moment("DD/MM/YYYY")),
            enddate: this.format_date(this.GetDailySchedule[j].endDate.toDate() || moment("DD/MM/YYYY")),
            isgunu: this.GetDailySchedule[j].isGunu,
            toplamsaat: Math.ceil(this.GetDailySchedule[j].totalHours),

          })
        );


      }

      return data;
    },
 

 

 
getColumns() {
    return [
        {title: "No  ", dataKey: "id"},
        {title: "Employee", dataKey: "person"},
        {title: "Client Name", dataKey: "client"},
        {title: "Designation ", dataKey: "designation"},
        {title: "Start Date", dataKey: "startdate"},
        {title: "End Date", dataKey: "enddate"},
        {title: "Total Day ", dataKey: "isgunu"},
        {title: "Total Hours", dataKey: "toplamsaat"},
       
    ];
},

    },
    computed:{
      getData() {
      return this.GetSch;
    },
    },
  
    name: 'taskreports'
  }
</script>
<style>
  @import '../../../../assets/css/tableExcelExport.css';
  
</style>