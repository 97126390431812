<template>
  <!-- Add Trainers List Modal -->
  <div>
    <div id="add_trainer" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{$t("Add New")}} {{$t("Trainer")}}</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{$t("First Name")}} <span class="text-danger">*</span></label>
                    <input v-model="trainner.FirstName" class="form-control" type="text">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{$t("Last Name")}}</label>
                    <input v-model="trainner.LastName" class="form-control" type="text">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{$t("Role")}} <span class="text-danger">*</span></label>
                    <input v-model="trainner.Role" class="form-control" type="text">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{$t("Email")}} <span class="text-danger">*</span></label>
                    <input v-model="trainner.Email" class="form-control" type="email">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{$t("Phone")}} </label>
                    <input v-model="trainner.Phone" class="form-control" type="text">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{$t("Status")}}</label>
                    <Select2 name="clientslistData" v-model="trainner.Status" :options="editstatus"
                      placeholder="Please select status" />
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label>{{$t("Description")}} <span class="text-danger">*</span></label>
                    <textarea v-model="trainner.Description" class="form-control" rows="4"></textarea>
                  </div>
                </div>
              </div>
              <div class="submit-section">
                <button @click.prevent="addTrainier" class="btn btn-primary submit-btn">{{$t("Submit")}}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Trainers List Modal -->

    <!-- Edit Trainers List Modal -->
    <div id="edit_type" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{$t("Edit Trainer")}}</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="editVeri">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{$t("First Name")}} <span class="text-danger">*</span></label>
                    <input class="form-control" type="text" :value="datas.FirstName">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{$t("Last Name")}}</label>
                    <input class="form-control" type="text" :value="datas.LastName">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{$t("Role")}} <span class="text-danger">*</span></label>
                    <input class="form-control" type="text" :value="datas.Role">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{$t("Email")}} <span class="text-danger">*</span></label>
                    <input class="form-control" type="email" :value="datas.Email">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{$t("Phone")}} </label>
                    <input class="form-control" type="text" :value="datas.Phone">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{$t("Status")}}</label>
                    <Select2 name="clientslistData" v-model="trainner" :options="editstatus"
                      placeholder="Please select status" />

                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label>{{$t("Description")}} <span class="text-danger">*</span></label>
                    <textarea class="form-control" rows="4" :value="datas.Description"></textarea>
                  </div>
                </div>
              </div>
              <div class="submit-section">
                <button class="btn btn-primary submit-btn">{{$t("Save")}}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Trainers List Modal -->

    <!-- Delete Trainers List Modal -->
    <div class="modal custom-modal fade" id="delete_type" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>{{$t("Delete Trainers List")}}</h3>
              <p>{{$t("Are you sure want to delete")}}?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a class="btn btn-primary continue-btn" @click.prevent="deleteVeri">{{$t("Delete")}}</a>
                </div>
                <div class="col-6">
                  <a data-bs-dismiss="modal" class="btn btn-primary cancel-btn">{{$t("Cancel")}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Trainers List Modal -->
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import Select2 from "v-select2-component";
export default {
  props: ["datas"],
  components: {
    Select2,
  },
  data() {
    return {
      trainner: {
        CompanyUID: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
        Description: "",
        Email: "",
        EmployeID: "",
        FirstName: "",
        LastName: "",
        Phone: "",
        Role: "",
        Status: true,
      },
      editTrainer: {},
      deleteTrainer: {},
      addstatus: ["Active", "Inactive"],
      editstatus: ['Active', 'Inactive']
    }
  },
  methods: {
    ...mapActions(["AddData", "fetchdata", "EditData", "DeleteData", "GetTrainer"]),

    /////////EKLE
    addTrainier() {
      this.AddData({
        db: "Trainer",
        mt: "SetTrainer",
        veri: this.trainner,
      }).then(() => {
        this.GetTrainer()
        $(".close").click()
      });
    },
    /////////DÜZENLE
    editTraines(h) {
      this.editTrainer = h;
    },
    editVeri(b) {
      const formData = new FormData(b.target);
      const formProps = Object.fromEntries(formData);
      this.EditData({ db: "Trainer", mt: "SetTrainer", data: formProps, id: this.editTrainer.id }).then(() => {
        this.GetTrainer()
        $(".close").click()

      });

    },
    /////////SİL
    deleteVeri() {
      this.DeleteData({ db: "Trainer", mt: "DeleteTrainer", data: this.datas }).then(() => {
        this.GetTrainer()
        $(".close").click()
      });
    },


  },
  mounted() {
  }
}
</script>


*///// Select2 Kullanımı Add Modal Popup için Aktif ////////*