<template>
  <div class="designations">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
      <!-- Page Wrapper -->
      <div class="page-wrapper">

        <!-- Page Content -->
        <div class="content container-fluid">

          <designationheader />

          <div class="row">
            <div class="col-md-12">
              <div >
                <designationtable @SendItem="getData" />
              </div>
            </div>
          </div>
        </div>
        <!-- /Page Content -->

        <designationmodal :datas="data" />

      </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'designations',
  components: {

  },
  data() {
    return {
      data: {}
    }
  },
  computed: {


  },
  methods: {
    ...mapActions(["sorgu"]),
    getData(value) {
      console.log(value);
      this.data = value
    }
  },
  mounted() {
    const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
    this.sorgu({ tablo: "Designation", guncelle: "SetDesignation", kosul: [{ query: ["CompanyUid", "==", uid] }] })
     this.sorgu({ tablo: "Department", guncelle: "SetDepartment", kosul: [{ query: ["CompanyUid", "==", uid] }] })
  }
}
</script>