<template>
  <div class="clients">
    <div class="main-wrapper">
       <main-header></main-header>
      <sidebar></sidebar>
      <!-- Page Wrapper -->
      <div class="page-wrapper">
      
        <!-- Page Content -->
        <div class="content container-fluid">
        
        <clientsheader />  
          
        <clientsfilter />  
          
          <div class="row staff-grid-row">
            <div class="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3" v-for="item in GetClientData" :key="item.id">
              <div class="profile-widget">
                <div class="profile-img">
                   <router-link :to="{ name: 'client-profile', params: { data: item.id } }"    class="avatar"><img  src="../../../assets/img/profiles/client.jpg" alt="client" ></router-link>
                </div>
                <div class="dropdown profile-action">
                  <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                   <div class="dropdown-menu dropdown-menu-right">
                                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit_client"  @click="send(item)"><i class="fa fa-pencil m-r-5"></i> {{$t("Edit")}}</a>
                                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_client"><i class="fa fa-trash-o m-r-5"></i> {{$t("Delete")}}</a>
                                </div>
                </div>
                <h4 class="user-name m-t-10 mb-0 text-ellipsis"><router-link to="/client-profile">{{item.ClientName}}</router-link></h4>
                <h5 class="user-name m-t-10 mb-0 text-ellipsis"><router-link to="/client-profile">{{item.FirstName+" "+item.LastName}}</router-link></h5>
                <div class="small text-muted">{{item.owner}}</div>
                <!-- <router-link to="/chat" class="btn btn-white btn-sm m-t-10">Message</router-link> -->
                <router-link to="/client-profile" class="btn btn-white btn-sm m-t-10 ms-1">{{$t("View Profile")}}</router-link>
              </div>
            </div>
          </div>
          </div>
        <!-- /Page Content -->
      
        <clientsmodal :datas="data"/>
        
        </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
import client from '../../../assets/json/client.json';
/* eslint-disable */ 

import clientlist from "../../../assets/json/clientlist.json";
import util from "../../../assets/utils/util";
// eslint-disable-next-line
const images = require.context(
  "../../../assets/img/profiles",
  false,
  /\.png$|\.jpg$/
);
import { mapActions,mapGetters } from "vuex";
export default {
  prop:["clientData"],
  name: "clientslist",
  data() {
    return {
   
      clientlist: clientlist,
      data:{}
    };
  },
  components: {},

  methods: {
    // ...mapActions(["fetchdata"]),
    // fetch() {
    //   this.fetchdata({ db: "Client", mt: "SetClient" });
    // },
send(e){
  this.data=e
},
 
  },
  computed:{
    ...mapGetters(["Getclient"]),
GetClientData(){
return this.Getclient
}
  },
  mounted() {
    if(this.GetClientData.length==0){
  this.fetch()
}
  },
};
</script>