<template><!-- Add Employee Modal -->
  <div>
    <div id="add_employee" class="modal custom-modal fade" role="dialog">

      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t("Add Personel") }}</h5>


            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="SendData">

              <div class="row">

                <!-- <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Picture<span class="text-danger">*</span></label>
                    <input name="picture" v-model="picture" type="hidden">
                    <img :src="employee.picture" height="50" width="50" alt="" class="circle">
                    <input type="file" class="form-control" @change="previewImage" accept="image/*">
                  </div>
                </div> -->
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("First Name") }} <span class="text-danger">*</span></label>
                    <input class="form-control" v-model="employee.FirstName" type="text">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Last Name") }}</label>
                    <input class="form-control" v-model="employee.LastName" type="text">
                  </div>
                </div>
             
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Email") }} <span class="text-danger">*</span></label>
                    <input class="form-control" v-model="employee.Email" type="email">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Username") }} <span class="text-danger">*</span></label>
                    <input class="form-control" v-model="employee.Username" type="text">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Password") }}</label>
                    <input class="form-control" v-model="employee.Password" type="password">
                  </div>
                </div>
               
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Confirm Password") }}</label>
                    <input class="form-control" type="password">
                  </div>
                </div>
                <div class="col-sm-6 ">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Country") }}</label>
                    <Select2 :options="countrysetting" v-model="employee.Country" placeholder="Germany" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("State/Province") }}</label>
                    <Select2 :options="statesetting" v-model="employee.StateProvince" />
                  </div>
                </div>
                <div class="col-sm-6 ">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Postal Code") }}</label>
                    <input class="form-control" v-model="employee.PostalCode" type="text">
                  </div>
                </div>

              
              
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Address") }}</label>
                    <input class="form-control" v-model="employee.address" type="text">
                  </div>
                </div>
               
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Phone") }} </label>
                    <input class="form-control" v-model="employee.Phone" type="text">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Birth Date") }} <span class="text-danger">*</span></label>

                    <input type="date" class="form-control" v-model="employee.date" valueType="format"
                      format='DD-MM-YYYY' />

                  </div>
                </div>
            
               
               
                <!-- <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Personel ID <span class="text-danger">*</span></label>
                    <input type="text" v-model="employee.EmploeeID" class="form-control">
                  </div>
                </div> -->
                <!-- <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Birth Date <span class="text-danger">*</span></label>

                    <date-picker valueType="format" format='DD-MM-YYYY' v-model="employee.date" :editable="true"
                      :clearable="false" />

                  </div>
                </div> -->

               
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Way of Working") }}</label>
                    <Select2 v-model="employee.wayOfWorking" :options="wayOfWorking" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Working Time") }} ( {{ $t("weekly") }})
                    </label>

                    <input class="form-control" v-model="employee.workingTime" type="text">
                    <!-- <Select2 name="wayOfWorking" :options="wayOfWorking" /> -->
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Department") }} <span class="text-danger">*</span></label>
                    <multiselect v-model="employee.DepartmentID" @input="departmentChange"
                      :options="$store.getters.GetDepartment.map(t => { return { 'name': t.DepartmentName, 'id': t.id } })"
                      :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"
                      @select="departmentSelect" placeholder="Select please" label="name" track-by="name"
                      :preselect-first="true">
                      <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single"
                          v-if="values.length > 0 && !isOpen">{{ values.length }} options selected</span></template>
                    </multiselect>

                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Designation") }} <span class="text-danger">*</span></label>


                    <multiselect v-model="employee.DesignationID"
                      :options="$store.getters.GetDesignation.map(t => { return { 'name': t.DesignationName, 'id': t.id } })"
                      :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"
                      @select="designationSelect" placeholder="Select please" label="name" track-by="name"
                      :preselect-first="true">
                      <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single"
                          v-if="values.length > 0 && !isOpen">{{ values.length }} options selected</span></template>
                    </multiselect>

                  </div>
                </div>




                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Working Fee") }} ({{ $t("hourly") }})</label>
                    <input class="form-control" name="workingFee" v-model="employee.workingFee" type="text">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Gender") }} <span class="text-danger">*</span></label>
                    <Select2 name="Gender" :options="gender" />
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-6">

                        <!-- <label class="col-form-label">Picture <span class="text-danger">*</span></label> &nbsp; -->
                        <input name="picture" v-model="employee.picture" type="hidden">
                        <img height="80" width="80" alt="" class="circle" src="../../../../assets/img/profiles/user.jpg">

                      </div>
                      <div class="col-sm-6">
                        <br />
                        <input width="50" type="file" class="form-control" @change="previewImage" accept="image/*">
                      </div>

                    </div>
                  </div>
                </div>
              </div>


              <div class="submit-section">
                <button class="btn btn-primary submit-btn">{{ $t("Submit") }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Employee Modal -->

    <!-- Edit Employee Modal -->
    <div id="edit_employee" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t("Edit Employee") }}</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">


            <form @submit.prevent="EditDatas">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("First Name") }} <span class="text-danger">*</span></label>
                    <input class="form-control" name="FirstName" v-model="employee.FirstName" type="text">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Last Name") }}</label>
                    <input class="form-control" name="LastName" v-model="employee.LastName" type="text">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Email") }} <span class="text-danger">*</span></label>
                    <input class="form-control" name="Email" v-model="employee.Email" type="email">
                  </div>
                </div> 
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Username") }} <span class="text-danger">*</span></label>
                    <input class="form-control" name="Username" v-model="employee.Username" type="text">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Password") }}</label>
                    <input class="form-control" name="Password" v-model="employee.Password" type="password">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Confirm Password") }}</label>
                    <input class="form-control" type="password">
                  </div>
                </div>
                <div class="col-sm-6 ">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Country") }}</label>
                    <Select2 :options="countrysetting" v-model="employee.Country" placeholder="Germany" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("State/Province") }}</label>
                    <Select2 :options="statesetting" v-model="employee.StateProvince" />
                  </div>
                </div>
                <div class="col-sm-6 ">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Postal Code") }}</label>
                    <input class="form-control" v-model="employee.PostalCode" type="text">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Address") }}</label>
                    <input class="form-control" v-model="employee.address" type="text">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Phone") }} </label>
                    <input class="form-control" name="Phone" v-model="employee.Phone" type="text">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Birth Date") }} <span class="text-danger">*</span></label>

                    <input type="date" class="form-control" v-model="employee.date" valueType="format"
                      format='DD-MM-YYYY' />

                  </div>
                </div>

              
                

                <!-- <div class="form-group">
                    <label class="col-form-label">Address <span class="text-danger">*</span></label>
                    <textarea name="address" id="" v-model="employee.address" class="form-control" cols="10"
                      rows="1"></textarea>

                  </div> -->
                 


              
              
               
               
              


               
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Way of Working") }}</label>

                    <select class="form-control" name="wayOfWorking">
                      <option v-for="(item, index) in wayOfWorking" v-bind:key="index"
                        :selected="item == employee.wayOfWorking">
                        {{ item }}
                      </option>
                    </select>
                    <!-- <Select2 name="wayOfWorking" :options="wayOfWorking" /> -->
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Working Time") }} ( {{ $t("weekly") }})</label>

                    <input class="form-control" name="workingTime" v-model="employee.workingTime" type="text">
                    <!-- <Select2 name="wayOfWorking" :options="wayOfWorking" /> -->
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Department") }} <span class="text-danger">*</span></label>



                    <multiselect v-model="employee.DepartmentID" @input="departmentChange"
                      :options="$store.getters.GetDepartment.map(t => { return { 'name': t.DepartmentName, 'id': t.id } })"
                      :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"
                      @select="departmentSelect" placeholder="Select please" label="name" track-by="name"
                      :preselect-first="true">
                      <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single"
                          v-if="values.length > 0 && !isOpen">{{ values.length }} options selected</span></template>
                    </multiselect>




                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Designation") }} <span class="text-danger">*</span></label>


                    <multiselect v-model="employee.DesignationID"
                      :options="$store.getters.GetDesignation.map(t => { return { 'name': t.DesignationName, 'id': t.id } })"
                      :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"
                      @select="designationSelect" placeholder="Select please" label="name" track-by="name"
                      :preselect-first="true">
                      <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single"
                          v-if="values.length > 0 && !isOpen">{{ values.length }} options selected</span></template>
                    </multiselect>

                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Working Fee") }} ({{ $t("hourly") }})</label>
                    <input class="form-control" name="workingFee" v-model="employee.workingFee" type="text">
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Gender") }} <span class="text-danger">*</span></label>
                    <Select2 name="Gender" v-model="employee.Gender" :options="gender" />
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <!-- <label class="col-form-label">Picture <span class="text-danger">*</span></label> &nbsp; -->
                        <input name="picture" v-model="employee.picture" type="hidden">
                        <img :src="employee.picture" height="80" width="80" alt="" class="circle">
                      </div>
                      <div class="col-sm-6">
                        <br />
                        <input width="50" type="file" class="form-control" @change="previewImage" accept="image/*">
                      </div>

                    </div>
                  </div>
                </div>
              </div>






              <div class="submit-section">
                <button class="btn btn-primary submit-btn">{{ $t("Save") }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Employee Modal -->

    <!-- Delete Employee Modal -->
    <div class="modal custom-modal fade" id="delete_employee" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>{{ $t("Delete Employee") }}</h3>
              <p>{{ $t("Are you sure want to delete?") }} </p>
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a @click="DeleteDta" class="btn btn-primary continue-btn">{{ $t("Delete") }}</a>
                </div>
                <div class="col-6">
                  <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-primary cancel-btn">{{ $t("Cancel")
                  }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<!-- /Delete Employee Modal --></template>
<style scoped="">
img.preview {
  width: 200px;
}
</style>
<script>
import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL } from "firebase/storage";

//import DatePicker from 'vue2-datepicker';

import 'vue2-datepicker/index.css';
import Select2 from 'v-select2-component'
import { mapActions, mapGetters } from 'vuex'
const currentDate = new Date()
const currentDate1 = new Date()
export default {
  props: ["userdata"],
  components: {
    // DatePicker,
    Select2
  },
  data() {
    return {
      value: [],
      tempDesignations: [],
      options: [
        { name: 'Vue.js', language: 'JavaScript', selectLabel: "seçiniz", },
        { name: 'Adonis', language: 'JavaScript' },
        { name: 'Rails', language: 'Ruby' },
        { name: 'Sinatra', language: 'Ruby' },
        { name: 'Laravel', language: 'PHP' },
        { name: 'Phoenix', language: 'Elixir' }
      ],
      countrysetting: ["Germany"],
      statesetting: ["Baden-Württemberg",
        "Bavaria",
        "Berlin",
        "Brandenburg",
        "Bremen",
        "Hamburg",
        "Hesse",
        "Mecklenburg-Vorpommern",
        "Lower Saxony",
        "North Rhine-Westphalia",
        "Rhineland-Palatinate",
        "Saarland",
        "Saxony-Anhalt",
        "Saxony",
        "Schleswig-Holstein",
        "Thuringia"
      ],
      imageData: null,
      picture: null,
      uploadValue: 0,



      compid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
      wayOfWorking: ["Full Time", "Part Time", "Minijop"],
      gender: ["Male", "Female"],

      startdate: currentDate,
      enddate: currentDate1,

      employee: {
        FirstName: "",
        LastName: "",
        Email: "",
        Password: "",
        EmploeeID: "",
        date: new Date(),
        Phone: "",
        picture: "",
        wayOfWorking: "",
        workingTime: "",
        CompanyUid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
        DepartmentID: "",

        DesignationID: "",
        workingFee: "",
      },

    }
  },


  methods: {


    ...mapActions(["AddData", "EditData", "DeleteData", "sorgu", "resimYukle", "sorguRef", "sorguDoc", "sorguTable"]),


    departmentSelect(veri) {
      this.sorgu({ tablo: "Designation", guncelle: "SetDesignation" })

    },
    departmentChange(e) {

      this.sorguTable({
        tablo: "Designation",

        kosul: [{ query: ["DepartmentID", "==", e] }],
      }).then(t => {

        this.tempDesignations = t
      })

    },
    DepartmanGetir(veri) {
      return this.$store.getters.GetDepartment.find(t => t.id == veri).DepartmentName
    },

    designationSelect(veri) {


    },
    async previewImage(event) {
      this.uploadValue = 0;
      this.picture = null;
      this.imageData = event.target.files[0];
      this.employee.picture = await this.resimYukle(this.imageData)
    },


    async SendData() {
      //console.log({ db: "Employee", mt: "SetEmployee", employee: this.employee });
      for (let i = 0; i < this.employee["DepartmentID"].length; i++) {
        this.employee["DepartmentID"][i] = await this.sorguRef({ tablo: "Department", id: this.employee["DepartmentID"][i].id })

      }

      for (let i = 0; i < this.employee["DesignationID"].length; i++) {
        this.employee["DesignationID"][i] = await this.sorguRef({ tablo: "Designation", id: this.employee["DesignationID"][i].id })
      }
      this.AddData({ db: "Employee", veri: this.employee }).then(p => {
        $(".close").click();
        this.employee={}
        const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
        this.sorgu({ tablo: "Employee", sirala:"FirstName", guncelle: "SetEmployee", kosul: [{ query: ["CompanyUid", "==", uid] }] })

      })
    },
    async EditDatas(e) {




      // for (let i = 0; i < this.employee["DepartmentID"].length; i++) {
      //   this.employee["DepartmentID"][i]= await this.sorguRef({tablo:"Department",id: this.employee["DepartmentID"][i].id})
      // }

      this.employee["DepartmentID"] = await Promise.all(
        this.employee["DepartmentID"].map(async t => { return await this.sorguRef({ tablo: "Department", id: t.id }) })
      )


      this.employee["DesignationID"] = await Promise.all(
        this.employee["DesignationID"].map(async t => { return await this.sorguRef({ tablo: "Designation", id: t.id }) })
      )

      // for (let i = 0; i < this.employee["DesignationID"].length; i++) {
      //   this.employee["DesignationID"][i]= await this.sorguRef({tablo:"Designation",id: this.employee["DesignationID"][i].id})
      // }

      this.EditData({ db: "Employee", data: this.employee, id: this.employee.id }).then(t => {
        $(".close").click();
        this.employee={}
        const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
        this.sorgu({ tablo: "Employee",sirala:"FirstName", guncelle: "SetEmployee", kosul: [{ query: ["CompanyUid", "==", uid] }] })

      })
    },
    DeleteDta() {
      this.DeleteData({ db: "Employee", mt: "DeleteEmployee", data: this.userdata }).then(p => {
        $(".close").click()
        const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
        this.sorgu({ tablo: "Employee", guncelle: "SetEmployee", kosul: [{ query: ["CompanyUid", "==", uid] }] })

      })
    },
    FetchDesignation() {
      this.fetchdata({ db: "Department", mt: "SetDept" }).then(() => {
        this.fetchdata({ db: "Designation", mt: "SetDest" })
      })
    }
  },
  watch: {
    async userdata(value) {

      console.log("DepartmentID", typeof (value["DepartmentID"]))
      console.log("DepartmentID", value["DepartmentID"])


      console.log("DesignationID", typeof (value["DesignationID"]))
      console.log("DesignationID", value["DesignationID"])


      if (typeof (value["DepartmentID"]) != "object") value["DepartmentID"] = []
      if (typeof (value["DesignationID"]) != "object") value["DesignationID"] = []




      //  DEPARTMANLARI MULTİSELECT UYGUN HALE GETİRİYORUZ
      if (value["DepartmentID"].length > 0) {
        if (value["DepartmentID"][0]["firestore"]) {
          value["DepartmentID"] = await Promise.all(value["DepartmentID"].map(async (t) => {
            var veri = await this.sorguDoc(t)
            return { 'name': veri.DepartmentName, 'id': veri.id };
          }))
        }
      }

      if (value["DesignationID"].length > 0) {
        if (value["DesignationID"][0]["firestore"]) {
          value["DesignationID"] = await Promise.all(value["DesignationID"].map(async (t) => {
            var veri = await this.sorguDoc(t)
            return { 'name': veri.DesignationName, 'id': veri.id };
          }))
        }
      }

      // console.log("KULLANICI BİLGİLERİ 2", value) 
      this.employee = value
    }
  },
  computed: {
    ...mapGetters(["GetDepartment", "GetDest", "Getcompany"]),
    GetDepartman() {
      // let arr = []
      // this.GetDept.forEach(e => {
      //   arr.push(e.DepartmentName)
      // })
      // return [...new Set(arr)]

      return ""
      // return this.GetDept.map(p => p.DepartmentName)
    },

    GetComp() {
      return this.Getcompany
    },
    GetwayOfWorking() {

      return this.userdata.wayOfWorking
    },
    GetDesignation() {
      let arr = []
      this.GetDest.forEach(e => {
        arr.push(e.DesignationName)
      })
      return [...new Set(arr)]
    }
  },
  mounted() {


    this.$store.commit("SetDesignation", [])
    //console.log("STORE",this.$store)

    // this.employee.date = "05-07-2022"
    //   this.FetchDesignation()
    //   console.log("USERDATA", this.employee);
  }
}
</script>
