<template>

<table class="table table-striped custom-table mb-0 datatable" id="leavereportcontent">
                  <thead>
                    <tr>
                      <th>Employee</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Leave Type</th>
                      <th>No of Days</th>
                      <th>Remaining Leave</th>
                      <th>Total Leaves</th>
                      <th>Total Leave Taken</th>
                    
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in GetLeaves" :key="item.id">
                      <td>
                        <h2 class="table-avatar">
                          <!-- <router-link :to="{ name: 'profile', params: { data: item.id } }" class="avatar"><img class="avatar"
                  :src="item.picture" alt="/" />   </router-link> -->
                          {{ PersonelGetir(item.Personel) }}
                        </h2>
                      </td>
                      <td>{{    format_date(item.startDate.toDate())}}</td>
                      <td>{{format_date( item.endDate.toDate())}}</td>
                      
                      <td class="text-center">
                        <button class="btn btn-info btn-sm">{{item.LeaveType}}</button>
                      </td>
                      <td class="text-center"><span class="btn btn-danger btn-sm">{{item.Day}}</span></td>
                      <td class="text-center"><span class="btn btn-warning btn-sm"><b>{{item.remainingValue}}</b></span></td>
                      <td class="text-center"><span class="btn btn-success btn-sm"><b>20</b></span></td>
                      <td class="text-center">{{item.status}}</td>
                  
                    </tr>
                  </tbody>
                </table>
</template>
<script>
import leavereport from '../../../../assets/json/leavereport.json';
import util from '../../../../assets/utils/util'
import {mapActions,mapGetters} from 'vuex';
/* eslint-disable */ 
const images = require.context('../../../../assets/img/profiles', false, /\.png$|\.jpg$/)
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import 'vue2-datepicker/index.css';
import Select2 from 'v-select2-component'
  export default {
    data() {
    return {
      leavereport: leavereport
    }
    },
    components: {
      Select2,
    DatePicker
    },
    computed:{
      ...mapGetters(["GetLeaves"])
    },
  
    methods: {

PersonelGetir(t) {
 
  try {
    var data=this.$store.getters.GetEmployes.find((a) => a.id == t)
    return data.FirstName+ " "+ data.LastName;
  } catch (error) {
    return t;
  }
},
kalanGunSayisi(){
  var data=this.$store.getters.GetLeaves;
    
 
  
console.log("Gun Sayisi: ",data);
},
format_date(value) {
  if (value) {
    return moment(String(value)).format('DD-MM-YYYY')
  }
},
loadImg(imgPath) {
  return images('./' + imgPath).default
},
zaman(a) {
  var date = a;
  return date.getDay() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()

}
},
mounted() {
   
    },
  }
</script>