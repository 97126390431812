import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/css/bootstrap.min.css'
import './assets/css/dataTables.bootstrap4.min.css'
import './assets/css/font-awesome.min.css'
import './assets/css/line-awesome.min.css'
import '../node_modules/vue-multiselect/dist/vue-multiselect.min.css'

/*********LANGUAGE SETTİNGS**********/
import i18n from './i18n'




/*********Header component**********/
import MainHeader from './views/layouts/mainheader.vue'
import Sidebar from './views/pages/sidebar.vue'
import HeaderLogo from './components/header-logo.vue'
import Toggle from './components/toggle.vue'
import Search from './components/search.vue'
import Flag from './components/flag.vue'
import Notifications from './components/notifications.vue'
import MessageNotifications from './components/message-notifications.vue'
import HeaderEnd from './components/header-end.vue'
/*********dashboard component******/
import PageHeader from './views/pages/dashboard/admin/pageheader.vue'
import Widget from './views/pages/dashboard/admin/widget.vue'
import Chart from './views/pages/dashboard/admin/chart.vue'
import Employee from './views/pages/dashboard/admin/employee.vue'
import Statistics from './views/pages/dashboard/admin/statistics.vue'
import Invoices from './views/pages/dashboard/admin/invoices.vue'
import Clients from './views/pages/dashboard/admin/clients.vue'
import Welcomeheader from './views/pages/dashboard/employee/welcomeheader.vue'
import Projects from './views/pages/dashboard/employee/projects.vue'
import Today from './views/pages/dashboard/employee/today.vue'
/*********Activities component******/
import HeaderActivities from './views/pages/activities/headeractivities.vue'
/*********Policies component******/
import HeaderPolicies from './views/pages/policies/headerpolicies.vue'
import PoliciesTable from './views/pages/policies/policiestable.vue'
import ModalPolicies from './views/pages/policies/modalpolicies.vue'
/*********knowledgebase component******/
import HeaderKnowledgebase from './views/pages/knowledgebase/headerknowledgebase.vue'
import KnowledgebaseContent from './views/pages/knowledgebase/knowledgebasecontent.vue'
import HeaderView from './views/pages/knowledgebase/view/headerview.vue'
import CategoriesView from './views/pages/knowledgebase/view/categoriesview.vue'
import ContentView from './views/pages/knowledgebase/view/contentview.vue'
/*********leads component******/
import HeaderLeads from './views/pages/leads/headerleads.vue'
import ContentLeads from './views/pages/leads/contentleads.vue'
/*********jobs component******/
import JobHeader from './views/pages/jobs/jobheader.vue'
import SearchFilter from './views/pages/jobs/searchfilter.vue'
import AppliedjobHeader from './views/pages/jobs/appliedjobs/appliedjobheader.vue'
import ModalAppliedJobs from './views/pages/jobs/appliedjobs/modalappliedjobs.vue'
import AptituteresultHeader from './views/pages/jobs/aptituteresult/aptituteresult-header.vue'
import ArchivedjobsHeader from './views/pages/jobs/archivedjobs/archivedjobs-header.vue'
import CandidateListHeader from './views/pages/jobs/candidatelist/candidatelistheader.vue'
import CandidateListModal from './views/pages/jobs/candidatelist/candidatelistmodal.vue'
import ExperHeader from './views/pages/jobs/experiancelevel/experheader.vue'
import ExperTable from './views/pages/jobs/experiancelevel/expertable.vue'
import ExperModal from './views/pages/jobs/experiancelevel/expermodal.vue'
import InterviewingHeader from './views/pages/jobs/interviewing/interviewingheader.vue'
import InterviewingSidebar from './views/pages/jobs/interviewing/interviewingsidebar.vue'
import InterviewingContent from './views/pages/jobs/interviewing/interviewingcontent.vue'
import ModalInterviewing from './views/pages/jobs/interviewing/modalinterviewing.vue'
import HeaderInterviewQuestions from './views/pages/jobs/interviewquestions/headerinterviewquestions.vue'
import InterviewQuestionsContent from './views/pages/jobs/interviewquestions/interviewquestioncontent.vue'
import ModalInterviewQuestions from './views/pages/jobs/interviewquestions/modalinterviewquestions.vue'
import HeaderJobapplicants from './views/pages/jobs/jobapplicants/headerjobapplicants.vue'
import JobApplicantsContent from './views/pages/jobs/jobapplicants/jobapplicantscontent.vue'
import JobAptituteHeader from './views/pages/jobs/jobaptitute/jobaptituteheader.vue'
import JobAptituteDetails from './views/pages/jobs/jobaptitute/jobaptitutedetails.vue'
import JobAptituteQuestions from './views/pages/jobs/jobaptitute/jobaptitutequestions.vue'
import HeaderJobDetails from './views/pages/jobs/jobdetails/headerjobdetails.vue'
import JobDetailsInfo from './views/pages/jobs/jobdetails/jobdetailsinfo.vue'
import JobDetailsContent from './views/pages/jobs/jobdetails/jobdetailscontent.vue'
import JobDetailsDetinfo from './views/pages/jobs/jobdetails/jobdetailsdetinfo.vue'
import ModalJobDetails from './views/pages/jobs/jobdetails/modaljobdetails.vue'
import JobListHeader from './views/pages/jobs/joblist/joblistheader.vue'
import JobListContent from './views/pages/jobs/joblist/joblistcontent.vue'
import JobListMainHeader from './views/pages/jobs/joblist/joblistmainheader.vue'
import HeaderMainJob from './views/pages/jobs/mainjobs/headermainjob.vue'
import MainJobContent from './views/pages/jobs/mainjobs/mainjobcontent.vue'
import ModalMainJob from './views/pages/jobs/mainjobs/modalmainjob.vue'
import HeaderJobDashboard from './views/pages/jobs/jobsdashboard/headerjobdashboard.vue'
import OverviewJobDashboard from './views/pages/jobs/jobsdashboard/overviewjobdashboard.vue'
import LatestJobDashboard from './views/pages/jobs/jobsdashboard/latestjobdashboard.vue'
import JobApplicantList from './views/pages/jobs/jobsdashboard/jobapplicantlist.vue'
import JobShortlistCandidate from './views/pages/jobs/jobsdashboard/jobshortlistcandidate.vue'
import WidgetJob from './views/pages/jobs/jobsdashboard/widgetjob.vue'
import HeaderJobView from './views/pages/jobs/jobview/headerjobview.vue'
import ModalJobView from './views/pages/jobs/jobview/modaljobview.vue'
import JobViewContent from './views/pages/jobs/jobview/jobviewcontent.vue'
import ContentJobView from './views/pages/jobs/jobview/contentjobview.vue'
import InfoJobView from './views/pages/jobs/jobview/infojobview.vue'
import HeaderResume from './views/pages/jobs/manageresume/headerresume.vue'
import ResumeContent from './views/pages/jobs/manageresume/resumecontent.vue'
import ModalResume from './views/pages/jobs/manageresume/modalresume.vue'
import HeaderOfferApprove from './views/pages/jobs/offerapprove/headerofferapprove.vue'
import OfferApproveContent from './views/pages/jobs/offerapprove/offerapprovecontent.vue'
import ModalOfferApprove from './views/pages/jobs/offerapprove/modalofferapprove.vue'
import HeaderOfferedJob from './views/pages/jobs/offeredjob/headerofferedjob.vue'
import ContentOfferedJob from './views/pages/jobs/offeredjob/contentofferedjob.vue'
import UserAppliedjobTable from './views/pages/jobs/userdashboard/userappliedjobtable.vue'
import UserLatestjobTable from './views/pages/jobs/userdashboard/userlatestjobtable.vue'
import UserLatestjob from './views/pages/jobs/userdashboard/userlatestjob.vue'
import UserGraph from './views/pages/jobs/userdashboard/usergraph.vue'
import UserDashboardWidget from './views/pages/jobs/userdashboard/userdashboardwidget.vue'
import UserAllJobsTable from './views/pages/jobs/useralljobs/useralljobtable.vue'
import ShortTable from './views/pages/jobs/shortlistcandidate/shorttable.vue'
import QuestionWizard from './views/pages/jobs/question/questionwizard.vue'
import ModalQuestion from './views/pages/jobs/question/modalquestion.vue'
import ScheduleTable from './views/pages/jobs/scheduletiming/scheduletable.vue'


/*********settings component******/
import SettingSidebar from './views/pages/settings/settingsidebar.vue'
import SettingApproval from './views/pages/settings/approvalsetting/settingapproval.vue'
import MessageApproval from './views/pages/settings/approvalsetting/messageapproval.vue'
import ProfileApproval from './views/pages/settings/approvalsetting/profileapproval.vue'
import HomeApproval from './views/pages/settings/approvalsetting/homeapproval.vue'
import ApprovalHeader from './views/pages/settings/approvalsetting/approvalheader.vue'
import ApprovalTabs from './views/pages/settings/approvalsetting/approvaltabs.vue'
import ChangepasswordHeader from './views/pages/settings/changepassword/changepasswordheader.vue'
import FormChangepassword from './views/pages/settings/changepassword/formchangepassword.vue'
import CronSettingForm from './views/pages/settings/cronsetting/cronsettingform.vue'
import CronSettingHeader from './views/pages/settings/cronsetting/cronsettingheader.vue'
import EmailForm from './views/pages/settings/emailsettings/emailform.vue'
import FormInvoiceSettings from './views/pages/settings/invoicesettings/forminvoicesettings.vue'
import HeaderInvoiceSettings from './views/pages/settings/invoicesettings/headerinvoicesettings.vue'
import HeaderLeaveType from './views/pages/settings/leavetype/headerleavetype.vue'
import LeaveTypeContent from './views/pages/settings/leavetype/leavetypecontent.vue'
import ModalLeaveType from './views/pages/settings/leavetype/modalleavetype.vue'
import HeaderLocalization from './views/pages/settings/localization/headerlocalization.vue'
import ContentLocalization from './views/pages/settings/localization/contentlocalization.vue'
import HeaderNotification from './views/pages/settings/notification/headernotification.vue'
import NotifiationContent from './views/pages/settings/notification/notificationcontent.vue'
import HeaderPerformanceSetting from './views/pages/settings/performancesetting/headerperformancesetting.vue'
import PerformanceSettingGoal from './views/pages/settings/performancesetting/performancesettinggoal.vue'
import CompetencyPerformanceSetting from './views/pages/settings/performancesetting/competencyperformancesetting.vue'
import OkrPerformanceSetting from './views/pages/settings/performancesetting/okrperformancesetting.vue'
import TabPerformanceSetting from './views/pages/settings/performancesetting/tabperformancesetting.vue'
import FormToxboxSetting from './views/pages/settings/toxboxsetting/formtoxboxsetting.vue'

/*********assets component******/
import Headerassets from './views/pages/assets/headerassets.vue'
import Assetsfilter from './views/pages/assets/assetsfilter.vue'
import ModalPopup from './views/pages/assets/modalpopup.vue'
/*********employee component******/
import AttendanceHeader from './views/pages/employees/attendance/attendanceheader.vue'
import AttendanceFilter from './views/pages/employees/attendance/attendancefilter.vue'
import AttendanceModal from './views/pages/employees/attendance/attendancemodal.vue'
import AttendEmployeeHeader from './views/pages/employees/attendanceemployee/attendemployeeheader.vue'
import Timesheet from './views/pages/employees/attendanceemployee/timesheet.vue'
import Statis from './views/pages/employees/attendanceemployee/statistics.vue'
import TodayActivity from './views/pages/employees/attendanceemployee/todayactivity.vue'
import AttendEmpFilter from './views/pages/employees/attendanceemployee/attendempfilter.vue'
import DepartmentHeader from './views/pages/employees/departments/departmentheader.vue'
import DepartmentTable from './views/pages/employees/departments/departmenttable.vue'
import DepartmentModal from './views/pages/employees/departments/departmentmodal.vue'
import DesignationHeader from './views/pages/employees/designations/designationheader.vue'
import DesignationTable from './views/pages/employees/designations/designationtable.vue'
import DesignationModal from './views/pages/employees/designations/designationmodal.vue'
import EmployeeHeader from './views/pages/employees/mainemployee/employeeheader.vue'
import EmployeeFilter from './views/pages/employees/mainemployee/employeefilter.vue'
import EmployeeContent from './views/pages/employees/mainemployee/employeecontent.vue'
import EmployeeModal from './views/pages/employees/mainemployee/employeemodal.vue'
import EmployeeListContent from './views/pages/employees/mainemployee/employeeslistcontent.vue'
import HeaderHoliday from './views/pages/employees/holidays/headerholidays.vue'
import HolidayContent from './views/pages/employees/holidays/holidaycontent.vue'
import ModalHoliday from './views/pages/employees/holidays/modalholiday.vue'
import HeaderLeave from './views/pages/employees/leave/headerleave.vue'
import LeaveWidget from './views/pages/employees/leave/leavewidget.vue'
import LeaveFilter from './views/pages/employees/leave/leavefilter.vue'
import LeaveContent from './views/pages/employees/leave/leavecontent.vue'
import ModalLeave from './views/pages/employees/leave/modalleave.vue'
import HeaderLeaveEmployee from './views/pages/employees/leaveemployee/headerleaveemployee.vue'
import WidgetLeaveEmployee from './views/pages/employees/leaveemployee/widgetleaveemployee.vue'
import ContentLeaveEmployee from './views/pages/employees/leaveemployee/contentleaveemployee.vue'
import ModalLeaveEmployee from './views/pages/employees/leaveemployee/modalleaveemployee.vue'
import HeaderLeaveSetting from './views/pages/employees/leavesettings/headerleavesetting.vue'
import AnnualLeaveSetting from './views/pages/employees/leavesettings/annualleavesetting.vue'
import ModalLeaveSetting from './views/pages/employees/leavesettings/modalleavesetting.vue'
import CreateLeaveSetting from './views/pages/employees/leavesettings/createleavesetting.vue'
import PaternityLeaveSetting from './views/pages/employees/leavesettings/paternityleavesetting.vue'
import MaternityLeaveSetting from './views/pages/employees/leavesettings/maternityleavesetting.vue'
import HospitalLeaveSetting from './views/pages/employees/leavesettings/hospitalleavesetting.vue'
import SickLeaveSetting from './views/pages/employees/leavesettings/sickleavesetting.vue'
import HeaderOvertime from './views/pages/employees/overtime/headerovertime.vue'
import ModalOvertime from './views/pages/employees/overtime/modalovertime.vue'
import OvertimeContent from './views/pages/employees/overtime/overtimecontent.vue'
import OvertimeWidget from './views/pages/employees/overtime/overtimewidget.vue'
import TimesheetTable from './views/pages/employees/timesheet/timesheettable.vue'
import ShiftTable from './views/pages/employees/shift/shifttable.vue'
import SchedulingTable from './views/pages/employees/scheduling/schedulingtable'

/*********report component******/
import AttendReportHeader from './views/pages/reports/attendancereport/attendreportheader.vue'
import AttendReportFilter from './views/pages/reports/attendancereport/attendreportfilter.vue'
import DailyReportsHeader from './views/pages/reports/dailyreports/dailyreportsheader.vue'
import DailyReportHeaderContent from './views/pages/reports/dailyreports/dailyreportheadercontent.vue'
import DailyReportsFilter from './views/pages/reports/dailyreports/dailyreportsfilter.vue'
import DailyReporttable from './views/pages/reports/dailyreports/dailyreporttable.vue'

import ClientReportsHeader from './views/pages/reports/clientreport/clientreportsheader.vue'
import ClientReportHeaderContent from './views/pages/reports/clientreport/clientreportheadercontent.vue'
import ClientReportsFilter from './views/pages/reports/clientreport/clientreportsfilter.vue'
import ClientReporttable from './views/pages/reports/clientreport/clientreporttable.vue'


import EmployeeReportFilter from './views/pages/reports/employeereports/employeereportfilter.vue'
import EmployeeReportHeader from './views/pages/reports/employeereports/employeereportheader.vue'
import EmployeeReportTable from './views/pages/reports/employeereports/employeereporttable.vue'
import ExpenseReportHeader from './views/pages/reports/expensereports/expensereportheader.vue'
import ExpenseReportFilter from './views/pages/reports/expensereports/expensereportfilter.vue'
import ExpenseReportContent from './views/pages/reports/expensereports/expensereportcontent.vue'
import HeaderInvoiceReport from './views/pages/reports/invoicereport/headerinvoicereport.vue'
import InvoiceReportFilter from './views/pages/reports/invoicereport/invoicereportfilter.vue'
import InvoiceReportTable from './views/pages/reports/invoicereport/invoicereporttable.vue'
import HeaderLeaveReport from './views/pages/reports/leavereports/headerleavereport.vue'
import LeaveReportFilter from './views/pages/reports/leavereports/leavereportfilter.vue'
import LeaveReportContent from './views/pages/reports/leavereports/leavereportcontent.vue'
import HeaderPaymentReport from './views/pages/reports/paymentreport/headerpaymentreport.vue'
import ContentPaymentReport from './views/pages/reports/paymentreport/contentpaymentreport.vue'
import FilterPaymentReport from './views/pages/reports/paymentreport/filterpaymentreport.vue'
import HeaderPayslipReport from './views/pages/reports/payslipreport/headerpayslipreport.vue'
import PayslipFilter from './views/pages/reports/payslipreport/payslipfilter.vue'
import PayslipContent from './views/pages/reports/payslipreport/payslipcontent.vue'
import HeaderProjectReport from './views/pages/reports/projectreport/headerprojectreport.vue'
import FilterProjectReport from './views/pages/reports/projectreport/filterprojectreport.vue'
import ProjectReportContent from './views/pages/reports/projectreport/projectreportcontent.vue'
import TableUserReport from './views/pages/reports/userreport/tableuserreport.vue'
import TaskReportTable from './views/pages/reports/taskreport/taskreporttable.vue'
import TaskReportFilter from './views/pages/reports/taskreport/taskreportfilter.vue'


/*********accounting component******/
import BudgetExpenseHeader from './views/pages/accounting/budgetexpenses/budgetexpenseheader.vue'
import BudgetExpenseModal from './views/pages/accounting/budgetexpenses/budgetexpensemodal.vue'
import BudgetRevenueHeader from './views/pages/accounting/budgetrevenues/budgetrevenueheader.vue'
import BudgetRevenueModal from './views/pages/accounting/budgetrevenues/budgetrevenuemodal.vue'
import BudgetHeader from './views/pages/accounting/budgets/budgetheader.vue'
import BudgetModal from './views/pages/accounting/budgets/budgetmodal.vue'
import CategoriesHeader from './views/pages/accounting/categories/categoriesheader.vue'
import CategoriesModal from './views/pages/accounting/categories/categoriesmodal.vue'
import SubCategoriesModal from './views/pages/accounting/subcategory/subcategoriesmodal.vue'
import SubCategoriesHeader from './views/pages/accounting/subcategory/subcategoriesheader.vue'

/*********app component******/
import CallSidebar from './views/pages/app/callsidebar.vue'
import ChatMiddle from './views/pages/app/chat/chatmiddle.vue'
import ChatEnd from './views/pages/app/chat/chatend.vue'
import ChatModal from './views/pages/app/chat/chatmodal.vue'
import EventHeader from './views/pages/app/events/eventheader.vue'
import EventModal from './views/pages/app/events/eventmodal.vue'
import FilemanagerSidebar from './views/pages/app/filemanager/filemanagersidebar.vue'
import FilemanagerContent from './views/pages/app/filemanager/filemanagercontent.vue'
import InboxSidebar from './views/pages/app/inboxsidebar.vue'
import HeaderInbox from './views/pages/app/inbox/headerinbox.vue'
import InboxContent from './views/pages/app/inbox/inboxcontent.vue'
import MailViewSidebar from './views/pages/app/mailviewsidebar.vue'
import HeaderMailView from './views/pages/app/email/mailview/headermailview.vue'
import MailViewContent from './views/pages/app/email/mailview/mailviewcontent.vue'

/*********profile component******/
import ClientProfileHeader from './views/pages/profile/clientprofile/clientprofileheader.vue'
import ClientHeaderContent from './views/pages/profile/clientprofile/clientheadercontent.vue'
import ClientProfileTab from './views/pages/profile/clientprofile/clientprofiletab.vue'
import ClientProfileTabContent from './views/pages/profile/clientprofile/clientprofiletabcontent.vue'
import HeaderProfile from './views/pages/profile/headerprofile.vue'
import ProfileCardFirst from './views/pages/profile/profilecardfirst.vue'
import ProfileCardSecond from './views/pages/profile/profilecardsecond.vue'
import ModalProfile from './views/pages/profile/modalprofile.vue'
import ProfileContent from './views/pages/profile/profilecontent.vue'

/*********clients component******/
import ClientsHeader from './views/pages/clients/clientsheader.vue'
import ClientsFilter from './views/pages/clients/clientsfilter.vue'
import ClientsModal from './views/pages/clients/clientsmodal.vue'
/*********components component******/
import ComponentSidebar from './views/pages/components/componentsidebar.vue'
import ComponentHeaderContent from './views/pages/components/componentheadercontent.vue'
import ComponentHeader from './views/pages/components/componentheader.vue'
/*********app component******/
import MailSidebar from './views/pages/app/mailsidebar.vue'
import ComposeHeader from './views/pages/app/email/compose/composeheader.vue'
import ContactHeader from './views/pages/app/contacts/contactheader.vue'
import ContactModal from './views/pages/app/contacts/contactmodal.vue'
import ContactContent from './views/pages/app/contacts/contactcontent.vue'
import ContactSidebar from './views/pages/app/contacts/contactsidebar.vue'
/*********sales component******/
import CreateEstimateHeader from './views/pages/sales/estimates/createestimateheader.vue'
import CreateEstimateTable from './views/pages/sales/estimates/createestimate/createestimatetable.vue'
import CreateEstimateFilter from './views/pages/sales/estimates/createestimatefilter.vue'
import CreateInvoiceFilter from './views/pages/sales/invoices/createinvoicefilter.vue'
import CreateInvoiceHeader from './views/pages/sales/invoices/createinvoiceheader.vue'
import CreateInvoiceTable from './views/pages/sales/invoices/createinvoice/createinvoicetable.vue'
import EditEstimateFilter from './views/pages/sales/estimates/editestimatefilter.vue'
import EditEstimateHeader from './views/pages/sales/estimates/editestimateheader.vue'
import EditEstimateTable from './views/pages/sales/estimates/editestimate/editestimatetable.vue'
import EditInvoiceFilter from './views/pages/sales/invoices/editinvoicefilter.vue'
import EditInvoiceHeader from './views/pages/sales/invoices/editinvoiceheader.vue'
import EditInvoiceTable from './views/pages/sales/invoices/editinvoice/editinvoicetable.vue'
import MainEstimateHeader from './views/pages/sales/estimates/mainestimateheader.vue'
import MainEstimateFilter from './views/pages/sales/estimates/mainestimatefilter.vue'
import MainEstimateContent from './views/pages/sales/estimates/mainestimate/mainestimatecontent.vue'
import MainEstimateModal from './views/pages/sales/estimates/mainestimate/mainestimatemodal.vue'
import EstimateViewHeader from './views/pages/sales/estimates/estimateview/estimateviewheader.vue'
import EstimateViewContent from './views/pages/sales/estimates/estimateview/estimateviewcontent.vue'
import ExpenseHeader from './views/pages/sales/expenses/exepenseheader.vue'
import ExpenseFilter from './views/pages/sales/expenses/expensefilter.vue'
import ExpenseModal from './views/pages/sales/expenses/expensemodal.vue'
import ExpenseTable from './views/pages/sales/expenses/expensetable.vue'
import MainInvoiceFilter from './views/pages/sales/invoices/maininvoicefilter.vue'
import MainInvoiceTable from './views/pages/sales/invoices/maininvoice/maininvoicetable.vue'
import MainInvoiceHeader from './views/pages/sales/invoices/maininvoice/maininvoiceheader.vue'
import HeaderInvoiceView from './views/pages/sales/invoices/invoiceview/headerinvoiceview.vue'
import InvoiceViewContent from './views/pages/sales/invoices/invoiceview/invoiceviewcontent.vue'
import HeaderPayment from './views/pages/sales/payments/headerpayment.vue'
import PaymentContent from './views/pages/sales/payments/paymentcontent.vue'
import FundTable from './views/pages/sales/providentfund/fundtable.vue'
import TaxModal from './views/pages/sales/taxes/taxmodal.vue'
import TaxTable from './views/pages/sales/taxes/taxtable.vue'

/*********table component******/
import DatatableHeader from './views/pages/table/datatable/datatableheader.vue'
import TableContent from './views/pages/table/datatable/tablecontent.vue'
import TableBasic1 from './views/pages/table/tablebasic/tablebasic1.vue'
import TableBasic2 from './views/pages/table/tablebasic/tablebasic2.vue'
import TableBasic3 from './views/pages/table/tablebasic/tablebasic3.vue'

/*********mainpage component******/
import FaqHeader from './views/pages/mainpage/faq/faqheader.vue'
import FaqContent from './views/pages/mainpage/faq/faqcontent.vue'
import HeaderPrivacy from './views/pages/mainpage/privacypolicy/headerprivacy.vue'
import PrivacyContent from './views/pages/mainpage/privacypolicy/privacycontent.vue'
import ModalTermination from './views/pages/mainpage/termination/modaltermination.vue'
import TerminationTable from './views/pages/mainpage/termination/terminationtable.vue'

/*********form component******/
import HeaderBasicInputs from './views/pages/form/headerbasicinputs.vue'
import BasicInputForm from './views/pages/form/basicinputs/basicinputform.vue'
import HeaderHorizontal from './views/pages/form/headerhorizontal.vue'
import BasicForm from './views/pages/form/horizontal/basicform.vue'
import AddressForm from './views/pages/form/horizontal/addressform.vue'
import HorizontalColumnForm from './views/pages/form/horizontal/horizontalcolumnform.vue'
import HorizontalColumnForm1 from './views/pages/form/horizontal/horizontalcolumnform1.vue'
import HeaderInputgroups from './views/pages/form/headerinputgroups.vue'
import BasicExample from './views/pages/form/inputgroups/basicexample.vue'
import Sizing from './views/pages/form/inputgroups/sizing.vue'
import Checkbox from './views/pages/form/inputgroups/checkbox.vue'
import Multipleaddons from './views/pages/form/inputgroups/multipleaddons.vue'
import HeaderFormMask from './views/pages/form/headerformmask.vue'
import FormMaskContent from './views/pages/form/formmask/formmaskcontent.vue'
import HeaderFormValidation from './views/pages/form/headerformvalidation.vue'
import FormValdiationContent from './views/pages/form/formvalidation/formvaldiationcontent.vue'
import HeaderFormVertical from './views/pages/form/headerformvertical.vue'
import BasicVerticalForm from './views/pages/form/formvertical/basicverticalform.vue'
import BasicAddressForm from './views/pages/form/formvertical/basicaddressform.vue'
import BasicTwoColumnForm from './views/pages/form/formvertical/basictwocolumnform.vue'

/*********goals component******/
import HeaderTracking from './views/pages/goals/goaltracking/headertracking.vue'
import TrackingContent from './views/pages/goals/goaltracking/trackingcontent.vue'
import ModalTracking from './views/pages/goals/goaltracking/modaltracking.vue'
import HeaderGoalType from './views/pages/goals/goaltype/headergoaltype.vue'
import GoalTypeContent from './views/pages/goals/goaltype/goaltypecontent.vue'
import ModalGoalType from './views/pages/goals/goaltype/modalgoaltype.vue'

/*********payroll component******/
import HeaderPayrollItem from './views/pages/payroll/payrollitems/headerpayrollitem.vue'
import PayrollItemTab from './views/pages/payroll/payrollitems/payrollitemtab.vue'
import PayrollItemContent from './views/pages/payroll/payrollitems/payrollitemcontent.vue'
import ModalDeductionPayroll from './views/pages/payroll/payrollitems/modaldeductionpayroll.vue'
import ModalOvertimePayrollItem from './views/pages/payroll/payrollitems/modalovertimepayrollitem.vue'
import ModalAddPayrollItem from './views/pages/payroll/payrollitems/modaladdpayrollitem.vue'
import SalaryModal from './views/pages/payroll/salary/salarymodal.vue'
import SalaryTable from './views/pages/payroll/salary/salarytable.vue'
import SalaryViewTable from './views/pages/payroll/salaryview/salaryviewtable.vue'

/*********performance component******/
import PerformanceHeader from './views/pages/performance/performanceheader.vue'
import BasicInformation from './views/pages/performance/basicinformation.vue'
import Achievements from './views/pages/performance/achievements.vue'
import Improvement from './views/pages/performance/improvement.vue'
import PersonalUpdate from './views/pages/performance/personalupdate.vue'
import Training from './views/pages/performance/training.vue'
import Hrd from './views/pages/performance/hrd.vue'
import PerformanceAppraisalHeader from './views/pages/performance/performanceappraisal/performanceappraisalheader.vue'
import PerformanceAppraisalTable from './views/pages/performance/performanceappraisal/performanceappraisaltable.vue'
import ModalPerformanceAppraisal from './views/pages/performance/performanceappraisal/modalperformanceappraisal.vue'
import PerformanceIndicatorHeader from './views/pages/performance/performanceindicator/performanceindicatorheader.vue'
import PerformanceIndicatorTable from './views/pages/performance/performanceindicator/performanceindicatortable.vue'
import ModalPerformanceIndicator from './views/pages/performance/performanceindicator/modalperformanceindicator.vue'

/*********project component******/
import HeaderProjectList from './views/pages/project/projectlist/headerprojectlist.vue'
import ModalProjectList from './views/pages/project/projectlist/modalprojectlist.vue'
import TableProjectList from './views/pages/project/projectlist/tableprojectlist.vue'
import FilterProjectList from './views/pages/project/projectlist/filterprojectlist.vue'


/*********projects component******/
import MainProjectTable from './views/pages/projects/mainproject/mainprojecttable.vue'
import ProjectDetails from './views/pages/projects/projectview/projectdetails.vue'
import ProjectAdmin from './views/pages/projects/projectview/projectadmin.vue'
import TaskBoard from './views/pages/projects/taskboard/taskboardmodal.vue'
import TaskBoardDetail from './views/pages/projects/taskboard/taskboarddetail.vue'

/*********promotion component******/
import PromotionTable from './views/pages/promotion/promotiontable.vue'

/*********users component******/
import UserModal from './views/pages/users/usermodal.vue'
import UserTable from './views/pages/users/usertable.vue'

/*********training component******/
import TrainingTable from './views/pages/training/trainingtype/trainingtable.vue'
import ModalMainTraining from './views/pages/training/maintraining/modalmaintraining.vue'
import MainTable from './views/pages/training/maintraining/maintrtable.vue'
import TrainerModal from './views/pages/training/trainers/trainermodal.vue'
import TrainersTable from './views/pages/training/trainers/trainerstable.vue'

/*********tickets component******/
import TicketViewModal from './views/pages/tickets/ticketview/ticketviewmodal.vue'
import MainTicketModal from './views/pages/tickets/mainticket/mainticketmodal.vue'
import MainComponentTable from './views/pages/tickets/mainticket/maintable.vue'
import MainFilter from './views/pages/tickets/mainticket/mainfilter.vue'
import MainWidget from './views/pages/tickets/mainticket/mainwidget.vue'

/*********tasks component******/
import TaskModal from './views/pages/tasks/taskmodal.vue'
import TaskSidebar from './views/pages/tasks/tasksidebar.vue'

/*********subscriptions component******/
import CompanyTable1 from './views/pages/subscription/subscriptioncompany/companytable.vue'
import CompanyTable2 from './views/pages/subscription/subscriptioncompany/companytable1.vue'
import CompanyTable3 from './views/pages/subscription/subscriptions/companytable2.vue'
import CompanyTable4 from './views/pages/subscription/subscriptions/companytable3.vue'
import CompanyTable5 from './views/pages/subscription/subscriptions/companytable4.vue'
import CompaniesModal from './views/pages/subscription/subscribedcompanies/companiesmodal.vue'
import CompaniesTable from './views/pages/subscription/subscribedcompanies/companiestable.vue'
import CompaniesFilter from './views/pages/subscription/subscribedcompanies/companiesfilter.vue'

/*********resignation component******/
import modalresignation from './views/pages/resignation/modalresignation.vue'
import resignationtable from './views/pages/resignation/resignationtable.vue'

/*******Plugin import***********/
import VueSelect from 'v-select2-component'
import DatePicker from 'vue2-datepicker'
import Multiselect from 'vue-multiselect'















/* eslint-disable */
import jquery from 'jquery';
window.$ = jquery
import './assets/css/style.css'
import './assets/js/bootstrap.bundle.min.js'
import './assets/js/Chart.min.js'
import './assets/css/bootstrap4.min.css'

import './assets/css/dataTables.bootstrap4.min.css'
import './assets/css/jquery.dataTables.min.js'
import './assets/css/dataTables.bootstrap4.min.js'




Vue.component('Multiselect', Multiselect);
Vue.component('sidebar', Sidebar);

/*********Header component**********/
Vue.component('main-header', MainHeader);
Vue.component('header-logo', HeaderLogo);
Vue.component('toggle', Toggle);
Vue.component('search', Search);
Vue.component('flag', Flag);
Vue.component('notifications', Notifications);
Vue.component('messagenotifications', MessageNotifications);
Vue.component('headerend', HeaderEnd);

/*********admin dashboard component******/
Vue.component('pageheader', PageHeader);
Vue.component('widget', Widget);
Vue.component('chart', Chart);
Vue.component('employee', Employee);
Vue.component('statistics', Statistics);
Vue.component('invoices', Invoices);
Vue.component('clients', Clients);

/*********employee dashboard component******/
Vue.component('welcomeheader', Welcomeheader);
Vue.component('today', Today);
Vue.component('projects', Projects);

/*********activities component******/
Vue.component('headeractivities', HeaderActivities);

/*********policies component******/
Vue.component('headerpolicies', HeaderPolicies);
Vue.component('policiestable', PoliciesTable);
Vue.component('modalpolicies', ModalPolicies);

/*********knowledgebase component******/
Vue.component('headerknowledgebase', HeaderKnowledgebase);
Vue.component('knowledgebasecontent', KnowledgebaseContent);
Vue.component('headerview', HeaderView);
Vue.component('contentview', ContentView);
Vue.component('categoriesview', CategoriesView);

/*********leads component******/
Vue.component('headerleads', HeaderLeads);
Vue.component('contentleads', ContentLeads);

/*********jobs component******/
Vue.component('jobheader', JobHeader);
Vue.component('appliedjobheader', AppliedjobHeader);
Vue.component('modalappliedjobs', ModalAppliedJobs)
Vue.component('searchfilter', SearchFilter);
Vue.component('aptituteresultheader', AptituteresultHeader);
Vue.component('archivedjobsheader', ArchivedjobsHeader);
Vue.component('candidatelistheader', CandidateListHeader);
Vue.component('candidatelistmodal', CandidateListModal);
Vue.component('experheader', ExperHeader);
Vue.component('expertable', ExperTable);
Vue.component('expermodal', ExperModal);
Vue.component('interviewingheader', InterviewingHeader);
Vue.component('interviewingsidebar', InterviewingSidebar);
Vue.component('interviewingcontent', InterviewingContent);
Vue.component('modalinterviewing', ModalInterviewing);
Vue.component('headerinterviewquestions', HeaderInterviewQuestions);
Vue.component('interviewquestioncontent', InterviewQuestionsContent);
Vue.component('modalinterviewquestions', ModalInterviewQuestions);
Vue.component('headerjobapplicants', HeaderJobapplicants);
Vue.component('jobapplicantscontent', JobApplicantsContent);
Vue.component('jobaptituteheader', JobAptituteHeader);
Vue.component('jobaptitutedetails', JobAptituteDetails);
Vue.component('jobaptitutequestions', JobAptituteQuestions);
Vue.component('headerjobdetails', HeaderJobDetails);
Vue.component('jobdetailsinfo', JobDetailsInfo);
Vue.component('jobdetailscontent', JobDetailsContent);
Vue.component('jobdetailsdetinfo', JobDetailsDetinfo);
Vue.component('modaljobdetails', ModalJobDetails);
Vue.component('joblistheader', JobListHeader);
Vue.component('jobcontent', JobListContent);
Vue.component('joblistmainheader', JobListMainHeader);
Vue.component('headermainjob', HeaderMainJob);
Vue.component('mainjobcontent', MainJobContent);
Vue.component('modalmainjob', ModalMainJob);
Vue.component('headerjobdashboard', HeaderJobDashboard);
Vue.component('widgetjob', WidgetJob);
Vue.component('overviewjobdashboard', OverviewJobDashboard);
Vue.component('latestjobdashboard', LatestJobDashboard);
Vue.component('jobapplicantlist', JobApplicantList);
Vue.component('jobshortlistcandidate', JobShortlistCandidate);
Vue.component('headerjobview', HeaderJobView);
Vue.component('infojobview', InfoJobView);
Vue.component('contentjobview', ContentJobView);
Vue.component('jobviewcontent', JobViewContent);
Vue.component('modaljobview', ModalJobView);
Vue.component('headerresume', HeaderResume);
Vue.component('resumecontent', ResumeContent);
Vue.component('modalresume', ModalResume);
Vue.component('headerofferapprove', HeaderOfferApprove);
Vue.component('offerapprovecontent', OfferApproveContent);
Vue.component('modalofferapprove', ModalOfferApprove);
Vue.component('headerofferedjob', HeaderOfferedJob);
Vue.component('contentofferedjob', ContentOfferedJob);
Vue.component('userappliedjobtable', UserAppliedjobTable);
Vue.component('userlatestjobtable', UserLatestjobTable);
Vue.component('userlatestjob', UserLatestjob);
Vue.component('usergraph', UserGraph);
Vue.component('userdashboardwidget', UserDashboardWidget);
Vue.component('useralljobtable', UserAllJobsTable);
Vue.component('shorttable', ShortTable);
Vue.component('questionwizard', QuestionWizard);
Vue.component('modalquestion', ModalQuestion);
Vue.component('scheduletable', ScheduleTable);


/*********settings component******/
Vue.component('settingsidebar', SettingSidebar);
Vue.component('approvalheader', ApprovalHeader);
Vue.component('homeapproval', HomeApproval);
Vue.component('profileapproval', ProfileApproval);
Vue.component('messageapproval', MessageApproval);
Vue.component('settingapproval', SettingApproval);
Vue.component('approvaltabs', ApprovalTabs);
Vue.component('changepasswordheader', ChangepasswordHeader);
Vue.component('formchangepassword', FormChangepassword);
Vue.component('cronsettingheader', CronSettingHeader);
Vue.component('cronsettingform', CronSettingForm);
Vue.component('emailform', EmailForm);
Vue.component('headerinvoicesettings', HeaderInvoiceSettings);
Vue.component('forminvoicesettings', FormInvoiceSettings);
Vue.component('headerleavetype', HeaderLeaveType);
Vue.component('leavetypecontent', LeaveTypeContent);
Vue.component('modalleavetype', ModalLeaveType);
Vue.component('headerlocalization', HeaderLocalization);
Vue.component('contentlocalization', ContentLocalization);
Vue.component('headernotification', HeaderNotification);
Vue.component('notificationcontent', NotifiationContent);
Vue.component('formtoxboxsetting', FormToxboxSetting);


/*********assets component******/
Vue.component('headerassets', Headerassets);
Vue.component('assetsfilter', Assetsfilter);
Vue.component('modalpopup', ModalPopup);

/*********employee component******/
Vue.component('attendanceheader', AttendanceHeader);
Vue.component('attendancefilter', AttendanceFilter);
Vue.component('attendancemodal', AttendanceModal);
Vue.component('attendemployeeheader', AttendEmployeeHeader);
Vue.component('timesheet', Timesheet);
Vue.component('statistics1', Statis);
Vue.component('todayactivity', TodayActivity);
Vue.component('attendempfilter', AttendEmpFilter);
Vue.component('departmentheader', DepartmentHeader);
Vue.component('departmenttable', DepartmentTable);
Vue.component('departmentmodal', DepartmentModal);
Vue.component('designationheader', DesignationHeader);
Vue.component('designationtable', DesignationTable);
Vue.component('designationmodal', DesignationModal);
Vue.component('employeeheader', EmployeeHeader);
Vue.component('employeefilter', EmployeeFilter);
Vue.component('employeecontent', EmployeeContent);
Vue.component('employeemodal', EmployeeModal);
Vue.component('employeelistcontent', EmployeeListContent);
Vue.component('headerholidays', HeaderHoliday);
Vue.component('holidaycontent', HolidayContent);
Vue.component('modalholiday', ModalHoliday);
Vue.component('headerleave', HeaderLeave);
Vue.component('leavewidget', LeaveWidget);
Vue.component('leavefilter', LeaveFilter);
Vue.component('leavecontent', LeaveContent);
Vue.component('modalleave', ModalLeave);
Vue.component('headerleaveemployee', HeaderLeaveEmployee);
Vue.component('widgetleaveemployee', WidgetLeaveEmployee);
Vue.component('contentleaveemployee', ContentLeaveEmployee);
Vue.component('modalleaveemployee', ModalLeaveEmployee);
Vue.component('headerleavesetting', HeaderLeaveSetting);
Vue.component('annualleavesetting', AnnualLeaveSetting);
Vue.component('sickleavesetting', SickLeaveSetting);
Vue.component('hospitalleavesetting', HospitalLeaveSetting);
Vue.component('maternityleavesetting', MaternityLeaveSetting);
Vue.component('paternityleavesetting', PaternityLeaveSetting);
Vue.component('createleavesetting', CreateLeaveSetting);
Vue.component('modalleavesetting', ModalLeaveSetting);
Vue.component('headerovertime', HeaderOvertime);
Vue.component('overtimewidget', OvertimeWidget);
Vue.component('overtimecontent', OvertimeContent);
Vue.component('modalovertime', ModalOvertime);
Vue.component('timesheettable', TimesheetTable);
Vue.component('shifttable', ShiftTable);
Vue.component('schedulingtable', SchedulingTable);


/*********report component******/
Vue.component('attendreportheader', AttendReportHeader);
Vue.component('attendreportfilter', AttendReportFilter);
Vue.component('dailyreportsheader', DailyReportsHeader);
Vue.component('dailyreportheadercontent', DailyReportHeaderContent);
Vue.component('dailyreportsfilter', DailyReportsFilter);
Vue.component('dailyreporttable', DailyReporttable);

Vue.component('clientreportsheader', ClientReportsHeader);
Vue.component('clientreportheadercontent', ClientReportHeaderContent);
Vue.component('clientreportsfilter', ClientReportsFilter);
Vue.component('clientreporttable', ClientReporttable);

Vue.component('employeereportheader', EmployeeReportHeader);
Vue.component('employeereportfilter', EmployeeReportFilter);
Vue.component('employeereporttable', EmployeeReportTable);
Vue.component('expensereportheader', ExpenseReportHeader);
Vue.component('expensereportfilter', ExpenseReportFilter);
Vue.component('expensereportcontent', ExpenseReportContent);
Vue.component('headerinvoicereport', HeaderInvoiceReport);
Vue.component('invoicereportfilter', InvoiceReportFilter);
Vue.component('invoicereporttable', InvoiceReportTable);
Vue.component('headerleavereport', HeaderLeaveReport);
Vue.component('leavereportfilter', LeaveReportFilter);
Vue.component('leavereportcontent', LeaveReportContent);
Vue.component('headerpaymentreport', HeaderPaymentReport);
Vue.component('filterpaymentreport', FilterPaymentReport);
Vue.component('contentpaymentreport', ContentPaymentReport);
Vue.component('headerpayslipreport', HeaderPayslipReport);
Vue.component('payslipfilter', PayslipFilter);
Vue.component('payslipcontent', PayslipContent);
Vue.component('headerprojectreport', HeaderProjectReport);
Vue.component('filterprojectreport', FilterProjectReport);
Vue.component('projectreportcontent', ProjectReportContent);
Vue.component('tableuserreport', TableUserReport);

Vue.component('taskreporttable', TaskReportTable);
Vue.component('taskreportfilter', TaskReportFilter);


/*********accounting component******/
Vue.component('budgetexpenseheader', BudgetExpenseHeader);
Vue.component('budgetexpensemodal', BudgetExpenseModal);
Vue.component('budgetrevenueheader', BudgetRevenueHeader);
Vue.component('budgetrevenuemodal', BudgetRevenueModal);
Vue.component('budgetheader', BudgetHeader);
Vue.component('budgetmodal', BudgetModal);
Vue.component('categoriesheader', CategoriesHeader);
Vue.component('categoriesmodal', CategoriesModal);

Vue.component('subcategoriesmodal', SubCategoriesModal);
Vue.component('subcategoriesheader', SubCategoriesHeader);


/*********call component******/
Vue.component('callsidebar', CallSidebar);
Vue.component('chatmiddle', ChatMiddle);
Vue.component('chatend', ChatEnd);
Vue.component('chatmodal', ChatModal);

/*********profile component******/
Vue.component('clientprofileheader', ClientProfileHeader);
Vue.component('clientheadercontent', ClientHeaderContent);
Vue.component('clientprofiletab', ClientProfileTab);
Vue.component('clientprofiletabcontent', ClientProfileTabContent);
Vue.component('headerprofile', HeaderProfile);
Vue.component('profilecardfirst', ProfileCardFirst);
Vue.component('profilecardsecond', ProfileCardSecond);
Vue.component('profilecontent', ProfileContent);
Vue.component('modalprofile', ModalProfile);

/*********clients component******/
Vue.component('clientsheader', ClientsHeader);
Vue.component('clientsfilter', ClientsFilter);
Vue.component('clientsmodal', ClientsModal);

/*********components component******/
Vue.component('componentheader', ComponentHeader);
Vue.component('componentsidebar', ComponentSidebar);
Vue.component('componentheadercontent', ComponentHeaderContent);

/*********app component******/
Vue.component('mailsidebar', MailSidebar);
Vue.component('composeheader', ComposeHeader);
Vue.component('contactheader', ContactHeader);
Vue.component('contactsidebar', ContactSidebar);
Vue.component('contactcontent', ContactContent);
Vue.component('contactmodal', ContactModal);
Vue.component('eventheader', EventHeader);
Vue.component('eventmodal', EventModal);
Vue.component('filemanagersidebar', FilemanagerSidebar);
Vue.component('filemanagercontent', FilemanagerContent);
Vue.component('inboxsidebar', InboxSidebar);
Vue.component('headerinbox', HeaderInbox);
Vue.component('inboxcontent', InboxContent);
Vue.component('mailviewsidebar', MailViewSidebar);
Vue.component('headermailview', HeaderMailView);
Vue.component('mailviewcontent', MailViewContent);

/*********sales component******/
Vue.component('estimateheader', CreateEstimateHeader);
Vue.component('estimatefilter', CreateEstimateFilter);
Vue.component('createestimatetable', CreateEstimateTable);
Vue.component('createinvoiceheader', CreateInvoiceHeader);
Vue.component('createinvoicefilter', CreateInvoiceFilter);
Vue.component('createinvoicetable', CreateInvoiceTable);
Vue.component('editestimateheader', EditEstimateHeader);
Vue.component('editestimatefilter', EditEstimateFilter);
Vue.component('editestimatetable', EditEstimateTable);
Vue.component('editinvoiceheader', EditInvoiceHeader);
Vue.component('editinvoicefilter', EditInvoiceFilter);
Vue.component('editinvoicetable', EditInvoiceTable);
Vue.component('mainestimateheader', MainEstimateHeader);
Vue.component('mainestimatefilter', MainEstimateFilter);
Vue.component('mainestimatecontent', MainEstimateContent);
Vue.component('mainestimatemodal', MainEstimateModal);
Vue.component('estimateviewheader', EstimateViewHeader);
Vue.component('estimateviewcontent', EstimateViewContent);
Vue.component('expenseheader', ExpenseHeader);
Vue.component('expensefilter', ExpenseFilter);
Vue.component('expensetable', ExpenseTable);
Vue.component('expensemodal', ExpenseModal);
Vue.component('maininvoiceheader', MainInvoiceHeader);
Vue.component('maininvoicefilter', MainInvoiceFilter);
Vue.component('maininvoicetable', MainInvoiceTable);
Vue.component('headerinvoiceview', HeaderInvoiceView);
Vue.component('invoiceviewcontent', InvoiceViewContent);
Vue.component('headerpayment', HeaderPayment);
Vue.component('paymentcontent', PaymentContent);
Vue.component('fundtable', FundTable);
Vue.component('taxmodal', TaxModal);
Vue.component('taxtable', TaxTable);


/*********table component******/
Vue.component('datatableheader', DatatableHeader);
Vue.component('table-content', TableContent);
Vue.component('tablebasic1', TableBasic1);
Vue.component('tablebasic2', TableBasic2);
Vue.component('tablebasic3', TableBasic3);

/*********mainpage component******/
Vue.component('faqheader', FaqHeader);
Vue.component('faqcontent', FaqContent);
Vue.component('headerprivacy', HeaderPrivacy);
Vue.component('privacycontent', PrivacyContent);
Vue.component('modaltermination', ModalTermination);
Vue.component('terminationtable', TerminationTable);

/*********form component******/
Vue.component('headerbasicinputs', HeaderBasicInputs);
Vue.component('basicinputform', BasicInputForm);
Vue.component('headerhorizontal', HeaderHorizontal);
Vue.component('basicform', BasicForm);
Vue.component('addressform', AddressForm);
Vue.component('horizontalcolumnform', HorizontalColumnForm);
Vue.component('horizontalcolumnform1', HorizontalColumnForm1);
Vue.component('headerinputgroups', HeaderInputgroups);
Vue.component('basicexample', BasicExample);
Vue.component('sizing', Sizing);
Vue.component('checkbox', Checkbox);
Vue.component('multipleaddons', Multipleaddons);
Vue.component('headerformmask', HeaderFormMask);
Vue.component('formmaskcontent', FormMaskContent);
Vue.component('headerformvalidation', HeaderFormValidation);
Vue.component('formvalidationcontent', FormValdiationContent);
Vue.component('headerformvertical', HeaderFormVertical);
Vue.component('basicverticalform', BasicVerticalForm);
Vue.component('basicaddressform', BasicAddressForm);
Vue.component('basictwocolumnform', BasicTwoColumnForm);

/*********goals component******/
Vue.component('headertracking', HeaderTracking);
Vue.component('trackingcontent', TrackingContent);
Vue.component('modaltracking', ModalTracking);
Vue.component('headergoaltype', HeaderGoalType);
Vue.component('goaltypecontent', GoalTypeContent);
Vue.component('modalgoaltype', ModalGoalType);

/*********payroll component******/
Vue.component('headerpayrollitem', HeaderPayrollItem);
Vue.component('payrollitemtab', PayrollItemTab);
Vue.component('payrollitemcontent', PayrollItemContent);
Vue.component('modaladdpayrollitem', ModalAddPayrollItem);
Vue.component('modalovertimepayrollitem', ModalOvertimePayrollItem);
Vue.component('modaldeductionpayroll', ModalDeductionPayroll);
Vue.component('salarytable', SalaryModal);
Vue.component('salarymodal', SalaryTable);
Vue.component('salaryviewtable', SalaryViewTable);


/*********performance component******/
Vue.component('performanceheader', PerformanceHeader);
Vue.component('basicinformation', BasicInformation);
Vue.component('achievements', Achievements);
Vue.component('improvement', Improvement);
Vue.component('personalupdate', PersonalUpdate);
Vue.component('training', Training);
Vue.component('hrd', Hrd);
Vue.component('performanceappraisalheader', PerformanceAppraisalHeader);
Vue.component('performanceappraisaltable', PerformanceAppraisalTable);
Vue.component('modalperformanceappraisal', ModalPerformanceAppraisal);
Vue.component('performanceindicatorheader', PerformanceIndicatorHeader);
Vue.component('performanceindicatortable', PerformanceIndicatorTable);
Vue.component('modalperformanceindicator', ModalPerformanceIndicator);
Vue.component('headerperformancesetting', HeaderPerformanceSetting);
Vue.component('tabperformancesetting', TabPerformanceSetting);
Vue.component('okrperformancesetting', OkrPerformanceSetting);
Vue.component('competencyperformancesetting', CompetencyPerformanceSetting);
Vue.component('performancesettinggoal', PerformanceSettingGoal);

/*********project component******/
Vue.component('headerprojectlist', HeaderProjectList);
Vue.component('filterprojectlist', FilterProjectList);
Vue.component('tableprojectlist', TableProjectList);
Vue.component('modalprojectlist', ModalProjectList);

/*********projects component******/
Vue.component('mainprojecttable', MainProjectTable);
Vue.component('projectdetails', ProjectDetails);
Vue.component('projectadmin', ProjectAdmin);
Vue.component('taskboardmodal', TaskBoard);
Vue.component('taskboardetail', TaskBoardDetail);

/*********projects component******/
Vue.component('promotiontable', PromotionTable);

/*********users component******/
Vue.component('usermodal', UserModal);
Vue.component('usertable', UserTable);

/*********training component******/
Vue.component('trainingtable', TrainingTable);
Vue.component('modalmaintraining', ModalMainTraining);
Vue.component('maintrtable', MainTable);
Vue.component('trainermodal', TrainerModal);
Vue.component('trainerstable', TrainersTable);

/*********tickets component******/
Vue.component('ticketviewmodal', TicketViewModal);
Vue.component('mainticketmodal', MainTicketModal);
Vue.component('maintable', MainComponentTable);
Vue.component('mainfilter', MainFilter);
Vue.component('mainwidget', MainWidget);

/*********tasks component******/
Vue.component('tasksidebar', TaskSidebar);
Vue.component('taskmodal', TaskModal);

/*********subscriptions component******/
Vue.component('companytable1', CompanyTable1);
Vue.component('companytable2', CompanyTable2);
Vue.component('companytable3', CompanyTable3);
Vue.component('companytable4', CompanyTable4);
Vue.component('companytable5', CompanyTable5);
Vue.component('companiesmodal', CompaniesModal);
Vue.component('companiestable', CompaniesTable);
Vue.component('companiesfilter', CompaniesFilter);
/*********resignation component******/
Vue.component('modresignation', modalresignation);
Vue.component('resinationtab', resignationtable);
import Vue2Filters from 'vue2-filters'

Vue.config.productionTip = false

Vue.use(require('vue-moment'));

/*********TOAST AYARLARI******/
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);



Vue.use(Vue2Filters)
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
