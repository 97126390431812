<template>
  <!-- Page Header -->
  <div class="page-header">
    <div class="row align-items-center">
      <div class="col">
        <h3 class="page-title">{{$t("Holidays")}} 2023 </h3>
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/index">{{$t("Dashboard")}}</router-link>
          </li>
          <li class="breadcrumb-item active">{{$t("Holidays")}}</li>
        </ul>
        <h5 style="color:coral">({{$t("Tatil bilgilerini ekleme ve düzenleme sayfası")}})</h5>
      </div>
      <div class="col-auto float-end ms-auto">
        <a href="#" class="btn add-btn" data-bs-toggle="modal" @click="$emit('editHolidays', {})" data-bs-target="#add_holiday"><i class="fa fa-plus"></i>
          {{$t("Add Holiday")}}</a>
      </div>
    </div>
  </div>
  <!-- /Page Header -->
</template>

<script>
export default {
  methods:{
  
  },
  mounted:{

  }
 
 
}
</script>