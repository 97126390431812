<template>
  <div class="row">
    <div class="col-md-12">
      <div class="welcome-box">
        <div class="welcome-img">
          <img alt="" :src="GetCompany[0].Picture" />
        </div>
        <div class="welcome-det">
          <h3>Welcome, {{ GetCompany[0].ContactPerson}}</h3>
          <p>{{ this.fetchdat() }}  </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  methods: {
    ...mapActions(["fetchdata", "EditData", "Tanimlamalar", "sorgu"]),
    fetchdat() {
      function join(t, a, s) {
        function format(m) {
          let f = new Intl.DateTimeFormat("en", m);
          return f.format(t);
        }
        return a.map(format).join(s);
      }

      let a = [{ day: "numeric" }, { month: "long" }, { year: "numeric" }];
      let s = join(new Date(), a, "-");
     return s;
    },
    Logout() {
      localStorage.removeItem("storedData");

      this.$router.push({ name: "login", query: { redirect: "/path" } });
    },
  },
  computed: {
    ...mapGetters(["GetCompany"]),
  },
  mounted() {
    this.fetchdat();
    const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;

    //await  this.GetComp(){ query: ["CompanyUid", "==", uid] }
    //this.Tanimlamalar()
    //await this.Tanimlamalar()
  },
};
</script>
