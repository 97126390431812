<template>
  <div class="events">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
      <!-- Page Wrapper -->
      <div class="page-wrapper">
     <!-- {{$store.getters.GetDailySchedule}} -->
        <!-- Page Content -->
        <div class="content container-fluid">
      
               <eventheader />
          
          <div class="row">
            <div class="col-lg-12">
              <div class="card mb-0">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                    
                      <!-- Calendar -->
                      <FullCalendar :options="calendarOptions" :events="events"></FullCalendar>
                      <!-- /Calendar -->
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Page Content -->
      
        <eventmodal />
        
        </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
   
  import FullCalendar from "@fullcalendar/vue"; 
  import {
  CalendarOptions,
  EventApi,
  DateSelectArg,
  EventClickArg,
} from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';



  export default {
    components: {
    FullCalendar,
    },
    data() {
    return {
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        events: [],

        initialView: "dayGridMonth",
        editable: false,
        selectable: false,
        selectMirror: false,
        dayMaxEvents: true,
        weekends: true,
        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
      },
    }
  },

computed:{
  ...mapGetters(["GetDailySchedule"]),
},
methods:{
  ...mapActions([ "sorgu","Tanimlamalar","isTablosu"]),
  PersonelGetir(t) {
    //  console.log("FİRMA BİLGİSİ İSTENİYOR",this.$store.getters.Getclient)
      try {
        var data=this.$store.getters.GetEmployes.find((a) => a.id == t)
        return data.FirstName+ " "+ data.LastName;

       
      } catch (error) {
        return t;
      }
      
    },
    FirmaGetir(t) {
    //  console.log("FİRMA BİLGİSİ İSTENİYOR",this.$store.getters.Getclient)
      try {
        return this.$store.getters.Getclient.find((a) => a.id == t).ClientName;
      } catch (error) {
        return t;
      }
    },


  },
watch: {

  
  // tasks: function (task) { 
  //   this.cal.calendar.render();
  // }
 
},





  props: {
    msg: String,
  },
    mounted() {


      this.calendarOptions.events=this.GetDailySchedule.map((element) => {
        let arr=[];
        //arr.push({title:element.EmployeeID,firma:element.clientID,start:element.startDate,end:element.endDate,startTime:element.shiftTime.StartTime.toDate()   ,endTime:element.shiftTime.EndTime.toDate()  | moment("HH:mm")});
         arr.push({title:element.EmployeeID,firma:element.clientID,start:element.shiftTime.StartTime.toDate(),end:element.shiftTime.EndTime.toDate()});
    
       var myJsonString = JSON.stringify(arr);
       arr=myJsonString;
        
      
       this.calendarOptions.events= arr 
   
 
 
 
 
   // return {'title':this.PersonelGetir(element.EmployeeID),'firma':this.FirmaGetir(element.clientID),'start': element.startDate,'end':element.endDate}
    return {'title':this.PersonelGetir(element.EmployeeID),'firma':this.FirmaGetir(element.clientID),'start': element.shiftTime.StartTime.toDate(),'end':element.shiftTime.EndTime.toDate()}
   // return {'title':this.PersonelGetir(element.EmployeeID),'firma':this.FirmaGetir(element.clientID),'start':moment(String(element.shiftTime.StartTime.toDate())).format('YYYY-MM-DD'),'end':moment(String(element.shiftTime.EndTime.toDate())).format('YYYY-MM-DD'),'startTime':element.shiftTime.StartTime.toDate()   ,'endTime':element.shiftTime.EndTime.toDate()  | moment("HH:mm")}  
        });
        console.log("OLAYLAR",this.calendarOptions.events)
    },
    name: 'events'
  }

 
</script>

<style scoped>
.fc-v-event .fc-event-main-frame {
  text-align:justify;
}
</style>