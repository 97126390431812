<template>
  <!-- Add Leave Modal -->
  <div>
    <div id="add_leave" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{$t("Add")}} {{$t("Leave")}}</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="SendData">
              <input type="hidden" name="Status" value="0">
              <div class="form-group">
                <label>{{$t("Personal")}} <span class="text-danger">*</span></label>
                <Select2 id="select1" :options="$store.getters.GetEmployes.map((t)=>{ return {'text':t.FirstName +' '+t.LastName,'id':t.id}})" v-model="leaveData.Personel " />
              </div>
              <div class="form-group">
                <label>{{$t("Leave Type")}} <span class="text-danger">*</span></label>
                <Select2 id="select2" :options="addleave" name="LeaveType" v-model="leaveData.LeaveType" />
              </div>
              <div class="form-group">
                <label>{{$t("From")}} <span class="text-danger">*</span></label>
                <div>
                  <input type="date" id="select3" v-model="leaveData.startDate" @change="getDifferenceInDays()"  class="form-control" :editable="true" :clearable="false"  />
                </div>
              </div>
              <div class="form-group">
                <label>{{$t("To")}} <span class="text-danger">*</span></label>
                <div>
                  <input type="date" id="select4"  v-model="leaveData.endDate" @change="getDifferenceInDays()" name="EndDate" class="form-control" :editable="true"
                    :clearable="false" />
                </div>
              </div>
              <div class="form-group">
                <label>{{$t("Number of Days")}} <span class="text-danger">*</span></label>
               
                <input class="form-control" id="select5" v-model="Day" type="text">
              </div>
            <!--  <div class="form-group">
                <label>{{$t("Remaining Leaves")}} <span class="text-danger">*</span></label>
                <input class="form-control" id="select6" v-model="leaveData.RemainingLeaves" name="RemainintLeaves" readonly
                  value="12" type="text">
              </div>
               <div class="form-group">
                <label>Leave Reason <span class="text-danger">*</span></label>
                <textarea rows="4" id="select7" name="LeaveReason" v-model="leaveData.LeaveReason" class="form-control"></textarea>
              </div> -->
              <div class="form-group">
                <label>{{$t("Status")}} <span class="text-danger">*</span></label>
                <Select2 id="select8" :options="status" v-model="leaveData.status" />
              </div>
             
              <div class="submit-section">
                <button v-if="durum == 0" class="btn btn-primary submit-btn">
                  {{ $t("Save") }}
                </button>
                <div v-if="durum != 0" class="alert alert-danger alert-dismissible fade show" role="alert">
                  <strong>{{ durum }} iş var</strong>
                 
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Leave Modal -->

    <!-- Edit Leave Modal -->
    <div id="edit_leave" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{$t("Edit")}} {{$t("Leave")}}</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">


            <form @submit.prevent="leaveEdit">

              <div class="form-group">
                <label>{{$t("Personal")}} <span class="text-danger">*</span></label>
                <input class="form-control" :value="PersonelGetir(leaveData.Personel)" disabled />
              </div>
              <div class="form-group">
                <label>{{$t("Leave Type")}} <span class="text-danger">*</span></label>
                <Select2 :options="addleave" v-model="leaveData.LeaveType" />
              </div>
              <div class="form-group">
                <label>{{$t("From")}} <span class="text-danger">*</span></label>
                <div>
                  <input type="date" class="form-control"   name="startDate"
                    v-model="leaveData.startDate" :editable="true" :clearable="false" />
                </div>
              </div>
              <div class="form-group">
                <label>{{$t("To")}} <span class="text-danger">*</span></label>
                <div>
                  <input type="date" @change="getDifferenceInDays()" v-model="leaveData.endDate" name="enddate" class="form-control"
                    :editable="true" :clearable="false" />
                </div>
              </div>
              <div class="form-group">
                <label>{{$t("Number of Days")}} <span class="text-danger">*</span></label>
                <input class="form-control" v-model="Day" readonly type="text">
              </div>
             <!--  <div class="form-group">
                <label>{{$t("Remaining Leaves")}} <span class="text-danger">*</span></label>
                <input class="form-control" v-model="leaveData.RemainingLeaves" readonly type="text">
              </div>
              <div class="form-group">
                <label>{{$t("Leave Reason")}} <span class="text-danger">*</span></label>
                <textarea rows="4" name="LeaveReason" v-model="leaveData.LeaveReason"
                  class="form-control">Going to hospital</textarea>
              </div> -->
              <div class="form-group">
                <label>{{$t("Status")}}<span class="text-danger">*</span></label>
                <Select2 :options="status" v-model="leaveData.status" />
              </div>
             

              <div class="submit-section">
                <button v-if="durum == 0" class="btn btn-primary submit-btn">
                  {{ $t("Save") }}
                </button>
                <div v-if="durum != 0" class="alert alert-danger alert-dismissible fade show" role="alert">
                  <strong>{{ durum }} iş var</strong>
                
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Leave Modal -->

    <!-- Approve Leave Modal -->
    <div class="modal custom-modal fade" id="approve_leave" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>{{$t("Leave Approve")}}</h3>
              <p>{{$t("Are you sure want to approve for this leave")}}?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a href="javascript:void(0);" class="btn btn-primary continue-btn">{{$t("Approve")}}</a>
                </div>
                <div class="col-6">
                  <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-primary cancel-btn">{{$t("Decline")}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Approve Leave Modal -->

    <!-- Delete Leave Modal -->
    <div class="modal custom-modal fade" id="delete_approve" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>{{$t("Delete Leave")}}</h3>
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{{$t("Are you sure want to delete this leave")}}?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a href="javascript:void(0);" @click.prevent="leaveDelete"
                    class="btn btn-primary continue-btn">{{$t("Delete")}}</a>
                </div>
                <div class="col-6">
                  <a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-primary cancel-btn">{{$t("Cancel")}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Leave Modal -->
</template>
<script>
 /* eslint-disable */
import DatePicker from 'vue2-datepicker';
import moment from 'moment'
import 'vue2-datepicker/index.css';
import Select2 from 'v-select2-component'
import { mapActions, mapGetters } from 'vuex';


const currentDate = new Date()
const currentDate1 = new Date()
const currentDate2 = new Date()
const currentDate3 = new Date()
export default {
  props: {

    editLeave: {
      type: Object
    }
  },
  data() {
    return {
      addleave: ["Urlaubstyp Auswählen", "Freizeiturlaub 12 Tage","Täglicher Urlaub", "Krankenurlaub", "Lohnausfall"],
      editleavetype: ["Urlaubstyp Auswählen", "Freizeiturlaub 12 Tage"],
      status: ["Ausstehend", "Akzeptiert", "Zurückgegangen"],
      leaveData: {
      Day:0
      },
      startDate:new Date(),
      endDate:new Date(),
      Day:0,
      durum: 0

      // leaveData: {
      //   Personel: "",
      //   LeaveReason: "",
      //   RemainingLeaves: "",
      //   Enddate: "",
      //   Day: "",
      //   startdate: "",
      //   LeaveType: "",
      //   CompanyUid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,

      // },
    }
  },

  components: {
    Select2,
    DatePicker

  },
  methods: {
    ...mapActions(["AddData", "leavesGetir", "EditData", "DeleteData","Tanimlamalar"]),


    
    async SendData(e) {
      // const formData = new FormData(e.target);
      // const formProps = Object.fromEntries(formData);
      
      const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
      this.leaveData.Day=this.Day;
      this.leaveData.CompanyUid=uid
      this.leaveData.startDate=new Date(this.leaveData.startDate)
      this.leaveData.endDate=new Date(this.leaveData.endDate)
     //  console.log("EKLE", this.leaveData)
    await  this.AddData({ db: "Leaves",  veri: this.leaveData }).then(p => {
        $(".close").click();
        this.leaveData={}
        this.leavesGetir();
      })
    },
   
    PersonelGetir(t) {
    //  console.log("FİRMA BİLGİSİ İSTENİYOR",this.$store.getters.Getclient)
      try {
        var data=this.$store.getters.GetEmployes.find((a) => a.id == t)
        return data.FirstName+ " "+ data.LastName;
      } catch (error) {
        return t;
      }
    },

    leaveDelete() {

      this.DeleteData({ db: "Leaves", data: this.leaveData }).then(() => {
        this.leavesGetir();
        $(".close").click()

      });
    },
    getDifferenceInDays() {
  
  
      if (this.leaveData.startDate && this.leaveData.endDate) {
        this.$store.dispatch("CalisanIzinKontrol", this.leaveData).then(p => {

          if (p.length > 0) {
            this.durum = p.length;

            console.log("ÇALIŞAN SONUÇ", p.length);
          } else { this.durum = 0; }
        })
 }
      //   this.leaveData.Day = Math.abs(new Date(this.leaveData.startDate) - new Date(this.leaveData.endDate)) / (1000 * 60 * 60 * 24);

  //    let tempDay = Math.abs(new Date(this.leaveData.startDate) - new Date(this.leaveData.endDate)) / (1000 * 60 * 60 * 24) || "";

     // console.log("HESAPLANAN GÜN",  this.calcWorkingDays(new Date(this.leaveData.startDate), tempDay))


   
    
     this.Day = this.calcWorkingDays(this.leaveData.startDate, this.leaveData.endDate)
    
 
    },

    calcWorkingDays(fromDate,endDate){

      
 // ensure that the argument is a valid and past date
 if(!fromDate){return -1;}
 
 // clone date to avoid messing up original date and time
 var frD=new Date(fromDate),
     toD=new Date(endDate),
     numOfWorkingDays=1;
 
 // reset time portion
 frD.setHours(0,0,0,0);
 toD.setHours(0,0,0,0);
 
 while(frD<toD){
  
   
  frD.setDate(frD.getDate()+1);
  var day=frD.getDay();
  if(day!=0&&day!=6){numOfWorkingDays++;}
 }
 return numOfWorkingDays;
},
    async leaveEdit(e) {

      
     
      const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
      this.leaveData.CompanyUid=uid
      this.leaveData.Day=this.Day;
      this.leaveData.startDate=new Date(this.leaveData.startDate)
      this.leaveData.endDate=new Date(this.leaveData.endDate)
     
      await this.EditData({ db: "Leaves", data: this.editLeave, id: this.editLeave.id })
     this.Tanimlamalar();
      $(".close").click()
      this.leaveData={}
    },

  },
  watch: {
    editLeave(value) {
  

    this.leaveData = value;
    var start = document.getElementById('select3');
var end = document.getElementById('select4');

start[0].addEventListener('change', function () {
  if (start.value)
    end.min = start.value;
}, false);
end[0].addEventLiseter('change', function () {
  if (end.value)
    start.max = end.value;
}, false);
    

 // if(value.startDate && value.endDate) 
  

 

    
     
    try {
        this.Day = this.calcWorkingDays(value.startDate.toDate(), value.endDate.toDate())
      this.leaveData.startDate =moment(value.startDate.toDate()).format('YYYY-MM-DD') 
      this.leaveData.endDate = moment(value.endDate.toDate()).format('YYYY-MM-DD')
    } catch (error) {
      
    }

   // this.leaveData.Day = this.calcWorkingDays(this.leaveData.startDate, this.leaveData.endDate)
      
      
      
    },
//     getDifferenceInDays() {

     
// let tempDay= Math.abs(new Date(this.leaveData.startDate) - new Date(this.leaveData.endDate)) / (1000 * 60 * 60 * 24) || "";
// console.log("TEMPDAY",tempDay)

// console.log("HAFTA SONLARI SAYMA",this.calcWorkingDays(new Date(this.leaveData.startDate),tempDay))
// this.leaveData.Day=this.calcWorkingDays(new Date(this.leaveData.startDate),tempDay)+1
// },
    

  },
  computed: {
    ...mapGetters(["GetEmployes", "GetLeaves"]),
//     getDifferenceInDays() {

     
// this.leaveData.Day = Math.abs(new Date(this.leaveData.startDate) - new Date(this.leaveData.endDate)) / (1000 * 60 * 60 * 24)|| "";



// },
  },
  mounted() {
this.Tanimlamalar();

  }
}
</script>




<style scoped>
 
 
  
 
</style>