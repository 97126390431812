<template>
  <!-- Leave Statistics -->
  <div class="row">
    <div class="col-md-3">
      <div class="stats-info">
        <h6>{{$t("Today Presents")}}</h6>
        <h4>{{ acceptedLeaves }} / {{ todayTotal }}</h4>
      </div>
    </div>
    <div class="col-md-3">
      <div class="stats-info">
        <h6>{{$t("Planned Leaves")}}</h6>
        <h4>{{ plannedLeaves }} <span>Today</span></h4>
      </div>
    </div>
    <div class="col-md-3">
      <div class="stats-info">
        <h6>{{$t("Unplanned Leaves")}}</h6>
        <h4>{{ unplannedLeaves }} <span>Today</span></h4>
      </div>
    </div>
    <div class="col-md-3">
      <div class="stats-info">
        <h6>{{$t("Pending Requests")}}</h6>
        <h4>{{ pendingLeaves }}</h4>
      </div>
    </div>
  </div>
  <!-- /Leave Statistics -->
</template>
<script>
import { mapGetters } from 'vuex'


export default {
  methods: {
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters(["GetLeaves"]),
    todayTotal() {

      return this.GetLeaves.length


    },

    plannedLeaves() {

      return this.GetLeaves.filter(a => a.status == "Planned").length


    },
    acceptedLeaves() {

      return this.GetLeaves.filter(a => a.status == "Accepted").length


    },
    unplannedLeaves() {

      return this.GetLeaves.filter(a => a.status == "UnPlanned").length


    },
    pendingLeaves() {

      return this.GetLeaves.filter(a => a.status == "Pending").length


    }

  }

}
</script>


