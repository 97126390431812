<template>
  <div class="leads">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
       <!-- Page Wrapper -->
       <div class="page-wrapper">
      
        <!-- Page Content -->
        <div class="content container-fluid">
        
        <header-leads />
          
        <content-leads />
        
        </div>
        <!-- /Page Content -->
        
      </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
   import HeaderLeads from '@/views/pages/leads/headerleads.vue'
import ContentLeads from '@/views/pages/leads/contentleads.vue'
  export default {
    components: {
   HeaderLeads,
ContentLeads,

    },
    mounted() {

    },
    name: 'leads'
  }
</script>