
<template>
  <div class="shiftscheduling">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
      <!-- Page Wrapper -->
      <div class="page-wrapper">
        <!-- Page Content -->
        <div class="content container-fluid">
          <div class="col-auto float-end ms-auto">
            <!-- <a href="shift-list" class="btn add-btn m-r-5">Shifts</a> -->
            <router-link to="/shift-list"
              ><a class="btn add-btn m-r-5">{{ $t("Shifts") }}</a></router-link
            >
            <a
              href="#"
              @click="addShift()"
              class="btn add-btn m-r-5"
              data-bs-toggle="modal"
              data-bs-target="#add_schedule"
            >
              {{ $t("Assign") }} {{ $t("Shifts") }}</a
            >
          </div>
          <!-- Page Header -->
          <div class="page-header">
            <div class="row">
              <div class="col">
                <h3 class="page-title">{{ $t("Daily Scheduling") }}</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/index">{{ $t("Dashboard") }}</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/employees">{{
                      $t("Employees")
                    }}</router-link>
                  </li>
                  <li class="breadcrumb-item active">
                    {{ $t("Shift Scheduling") }}
                  </li>
                </ul>
                <h5 style="color: coral">
                  ({{ $t("Adding and editing staff overtime") }})
                </h5>
              </div>
            </div>
          </div>
          <!-- /Page Header -->
          <div id="add_schedule" class="modal custom-modal fade" role="dialog">
            <div
              class="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" style="color: brown">
                    {{ $t("Add") }} {{ $t("Schedule") }} -
                    {{ DailyScheduling.FirstName }}
                    {{ DailyScheduling.LastName }}
                  </h5>

                  <button
                    type="button"
                    class="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <form @submit.prevent="SendData">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="col-form-label"
                            >{{ $t("Client") }}
                            <span class="text-danger">*</span></label
                          >
                          <Select2
                            name="clientslistData"
                            @change="clientChange"
                            v-model="DailyScheduling.clientID"
                            :options="
                              $store.getters.Getclient.map((t) => {
                                return { text: t.ClientName, id: t.id };
                              })
                            "
                            placeholder="Please select company"
                          />
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label class="col-form-label">{{
                            $t("Start Date")
                          }}</label>

                          <div>
                            <input
                              type="date"
                              v-model="DailyScheduling.startDate"
                              @change="PersonelData()"
                              name="startdate"
                              class="picker"
                              placeholder="Please select start date"
                              :editable="true"
                              :clearable="false"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label class="col-form-label">{{
                            $t("End Date")
                          }}</label>
                          <div>
                            <input
                              type="date"
                              v-model="DailyScheduling.endDate"
                              @change="PersonelData()"
                              name="enddate"
                              class="picker"
                              placeholder="Please select start date"
                              :editable="true"
                              :clearable="false"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="col-form-label"
                            >{{ $t("Shifts Time") }}
                            <span class="text-danger">*</span></label
                          >

                          <Select2
                            v-model="DailyScheduling.shiftTime"
                            :options="
                              tempShiftsTime.map((t) => {
                                return {
                                  text:
                                    t.ShiftName +
                                    ' (' +
                                    t.StartTime +
                                    ' - ' +
                                    t.EndTime +
                                    ')',
                                  id: t.id,
                                };
                              })
                            "
                            placeholder="Please select company"
                          />
                        </div>
                      </div>
                      <!-- Department -->
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="col-form-label"
                            >{{ $t("Department") }}
                            <span class="text-danger">*</span></label
                          >

                          <Select2
                            name="departmnetlistData"
                            v-model="DailyScheduling.DepartmentID"
                            :options="
                              tempDepartment.map((t) => {
                                return { text: t.DepartmentName, id: t.id };
                              })
                            "
                            placeholder="Please select company"
                          />
                        </div>
                      </div>

                      <!-- Designations -->
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="col-form-label"
                            >{{ $t("Designations") }}
                            <span class="text-danger">*</span></label
                          >
                          <Select2
                            name="departmnetlistData"
                            @change="PersonelData()"
                            v-model="DailyScheduling.DesignationID"
                            :options="
                              compudetDesignation.map((t) => {
                                return { text: t.DesignationName, id: t.id };
                              })
                            "
                            placeholder="Please select company"
                          />
                        </div>
                      </div>

                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="col-form-label"
                            >{{ $t("Employee Name") }}
                            <span class="text-danger">*</span></label
                          >
                          <!-- <label class="col-form-label" > {{ DailyScheduling.FirstName }}  {{ DailyScheduling.LastName }}

                          </label> -->

                          <br />

                          <!--                           
                           <Select2 name="employeelistData" v-model="DailyScheduling.employeName" :options="getEmployeeData"
                            placeholder="Please select employee" :multiple="true" />   -->

                          <!-- <multiselect
                            v-model="DailyScheduling.employeID"
                            :options="
                              $store.getters.GetEmployes.map((t) => {
                                return {
                                  name: t.FirstName + ' ' + t.LastName,
                                  id: t.id,
                                };
                              })
                            "
                            :multiple="true"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            label="name"
                            placeholder="Pick some"
                          >  -->

                          <multiselect
                            v-model="DailyScheduling.EmployeeID"
                            :options="filterPersonel"
                            :searchable="false"
                            :close-on-select="false"
                            :show-labels="false"
                            :multiple="true"
                            :allow-empty="false"
                            :clear-on-select="false"
                            label="name"
                          >
                            <template
                              slot="selection"
                              slot-scope="{ values, isOpen }"
                              ><span
                                class="multiselect__single"
                                v-if="values.length && !isOpen"
                                >{{ values.length }} options selected</span
                              ></template
                            >
                          </multiselect>

                          <!-- <pre
                            class="language-json"
                          ><code>{{ DailyScheduling.employeName  }}</code></pre>   -->
                        </div>
                      </div>

                      <!-- 
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label class="col-form-label"
                            >{{$t("Start Time")}}
                            <span class="text-danger">*</span></label
                          >
                          <div class="input-group time timepicker">
                            <input
                              v-model="DailyScheduling.startTime"
                              class="form-control"
                            /><span class="input-group-text"
                              ><i class="fa fa-clock-o"></i
                            ></span>
                          </div>
                        </div>
                      </div>
            
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label class="col-form-label"
                            >{{$t("End Time")}} <span class="text-danger">*</span></label
                          >
                          <div class="input-group time timepicker">
                            <input
                              v-model="DailyScheduling.endTime"
                              class="form-control"
                            /><span class="input-group-text"
                              ><i class="fa fa-clock-o"></i
                            ></span>
                          </div>
                        </div>
                      </div>
                  
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label class="col-form-label"
                            >{{$t("Break Time")}}
                            <span class="text-danger">*</span></label
                          >
                          <input
                            class="form-control"
                            v-model="DailyScheduling.breakTime"
                            type="text"
                          />
                        </div>
                      </div> -->
                      <div class="custom-control form-check">
                        <input
                          type="checkbox"
                          v-model="DailyScheduling.checked"
                          :v-bind="DailyScheduling.checked"
                          class="form-check-input"
                          id="customCheck1"
                        />
                        <label class="form-check-label" for="customCheck1">
                          {{ $t("Does it include the weekend?") }}</label
                        >
                      </div>
                    </div>
                    <div class="submit-section">
                      <button class="btn btn-primary submit-btn">
                        {{ $t("Submit") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div id="edit_schedule" class="modal custom-modal fade" role="dialog">
            <div
              class="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">{{ $t("Edit Schedule") }}</h5>
                  <button
                    type="button"
                    class="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <form @submit.prevent="Edit">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="col-form-label"
                            >{{ $t("Client") }}
                            <span class="text-danger">*</span></label
                          >
                          <Select2
                            name="clientslistData"
                            @change="clientChange"
                            v-model="DailyScheduling.clientID"
                            :options="
                              $store.getters.Getclient.map((t) => {
                                return { text: t.ClientName, id: t.id };
                              })
                            "
                            placeholder="Please select company"
                          />
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label class="col-form-label">{{
                            $t("Start Date")
                          }}</label>
                          <div>
                            <input
                              type="date"
                              v-model="DailyScheduling.startDate"
                              name="startdate"
                              class="picker"
                              placeholder="Please select start date"
                              :editable="true"
                              :clearable="false"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label class="col-form-label">{{
                            $t("End Date")
                          }}</label>
                          <div>
                            <input
                              disabled
                              type="date"
                              v-model="DailyScheduling.endDate"
                              name="enddate"
                              class="picker"
                              placeholder="Please select start date"
                              :editable="true"
                              :clearable="false"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="col-form-label"
                            >{{ $t("Shifts Time") }}
                            <span class="text-danger">*</span></label
                          >
                          <Select2
                            v-model="DailyScheduling.shiftTime"
                            :options="
                              tempShiftsTime.map((t) => {
                                return {
                                  text:
                                    t.ShiftName +
                                    ' (' +
                                    t.StartTime +
                                    ' - ' +
                                    t.EndTime +
                                    ')',
                                  id: t.id,
                                };
                              })
                            "
                            placeholder="Please select company"
                          />
                        </div>
                      </div>
                      <!-- Department -->
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="col-form-label"
                            >{{ $t("Department") }}
                            <span class="text-danger">*</span></label
                          >
                          <Select2
                            name="departmnetlistData"
                            v-model="DailyScheduling.DepartmentID"
                            @change="departmentChange"
                            :options="
                              $store.getters.GetDepartment.map((t) => {
                                return { text: t.DepartmentName, id: t.id };
                              })
                            "
                            placeholder="Please select company"
                          />
                        </div>
                      </div>

                      <!-- Designations -->
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="col-form-label"
                            >{{ $t("Designations") }}
                            <span class="text-danger">*</span></label
                          >
                          <Select2
                            name="departmnetlistData"
                            v-model="DailyScheduling.DesignationID"
                            @change="PersonelData()"
                            :options="
                              compudetDesignation.map((t) => {
                                return { text: t.DesignationName, id: t.id };
                              })
                            "
                            placeholder="Please select company"
                          />
                        </div>
                      </div>

                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="col-form-label"
                            >{{ $t("Employee Name") }}
                            <span class="text-danger">*</span></label
                          >

                          <multiselect
                            v-model="DailyScheduling.EmployeeID"
                            :options="filterPersonel"
                            :searchable="false"
                            :close-on-select="false"
                            :show-labels="false"
                            :multiple="true"
                            :allow-empty="false"
                            :clear-on-select="false"
                            label="name"
                          >
                            <template
                              slot="selection"
                              slot-scope="{ values, isOpen }"
                              ><span
                                class="multiselect__single"
                                v-if="values.length && !isOpen"
                                >{{ values.length }} options selected</span
                              ></template
                            >
                          </multiselect>
                        </div>
                      </div>
                      <div class="custom-control form-check">
                        <input
                          type="checkbox"
                          v-model="DailyScheduling.checked"
                          :v-bind="DailyScheduling.checked"
                          class="form-check-input"
                          id="customCheck2"
                        />
                        <label class="form-check-label" for="customCheck1">
                          {{ $t("Does it include the weekend?") }}</label
                        >
                      </div>
                    </div>
                    <div>
                      <button
                        class="btn btn-danger submit-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#deleteSchedule"
                      >
                        {{ $t("Delete Schedule") }}
                      </button>
                      <button
                        style="float: right"
                        class="btn btn-primary submit-btn"
                      >
                        {{ $t("Submit") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal custom-modal fade"
            id="deleteSchedule"
            role="dialog"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-body">
                  <div class="form-header">
                    <h3>{{ $t("Delete") }} {{ $t("Schedule") }}</h3>
                    <button
                      type="button"
                      class="close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <p>{{ $t("Are you sure want to delete") }}?</p>
                  </div>
                  <div class="modal-btn delete-action">
                    <div class="row">
                      <div class="col-6" @click.prevent="delSchedule">
                        <a class="btn btn-primary continue-btn">{{
                          $t("Delete")
                        }}</a>
                      </div>
                      <div class="col-6">
                        <a
                          href="javascript:void(0);"
                          data-bs-dismiss="modal"
                          class="btn btn-primary cancel-btn"
                          >{{ $t("Cancel") }}</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Content Starts -->
          <!-- Search Filter -->
          <div class="row filter-row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group form-focus select-focus">
                <Select2
                  name="employeelistData"
                  v-model="searchDailyScheduling.EmployeeID"
                  :options="getEmployeeData"
                  placeholder="Please select employee"
                />

                <label class="focus-label">{{ $t("Employee") }}</label>
              </div>
            </div>

            <div class="col-sm-6 col-md-3">
              <div class="form-group form-focus select-focus">
                <Select2
                  v-model="searchDailyScheduling.clientID"
                  :options="getClientData"
                  placeholder="Please select company"
                />
                <label class="focus-label">{{ $t("Clients") }}</label>
              </div>
            </div>
            <div class="col-sm-6 col-md-2">
              <div class="form-group form-focus focused">
                <div>
                  <input
                    type="date"
                    v-model="searchDailyScheduling.startDate"
                    name="startdate"
                    class="picker"
                    placeholder="Please select end date"
                    :editable="true"
                    :clearable="false"
                  />
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-md-2">
              <a href="#" @click="searchBtn()" class="btn btn-success w-100"
                >{{ $t("Search") }}
              </a>
            </div>
            <div class="col-sm-6 col-md-2">
              <a href="#" @click="resetBtn()" class="btn btn-warning w-100"
                >{{ $t("Reset") }}
              </a>
            </div>
          </div>
          <!-- Search Filter -->

          <schedulingtable
            @editScheduling="editScheduling"
            @personData="personelGorev"
            :searchDailyScheduling="searchDailyScheduling"
            @itemGunler="itemGunler"
          />

          <!-- /Content End -->
          <div class="col-md-12">
            <div class="table-responsive"></div>
          </div>
        </div>
        <!-- /Page Content -->
      </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
import Select2 from "v-select2-component";
import { mapActions, mapGetters } from "vuex";
import DatePicker from "vue2-datepicker";
// import "vue2-datepicker/index.css";
import moment from "moment";
import datetimevuejs from "vuejs-datetimepicker";
//import { fnk } from "../../../../fonksiyonlar/fnk"

//* eslint-disable *//
// import { ref } from 'vue'
// const currentDate = ref(new Date())
// const currentDate1 = ref(new Date())
/* eslint-disable */
export default {
  props: {},
  //mixins: [fnk],

  data() {
    return {
      searchDailyScheduling: { id: "0" },
      allEmployee: false,
      tempShiftsTime: [],
      tempDepartment: [],
      tempDesignations: [],
      personelGetir: "",
      value: [],
      items: "",
      shiftTable: [],
      classList: {},
      selectedClient: "",
      selectedShiftTime: "",
      startdate: "currentDate",
      enddate: "currentDate1",
      clients: {
        CompanyUid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
        title: "",
      },
      department: {
        CompanyUid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
        DepartmentName: "",
      },
      // employees: {
      //  // CompanyUid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
      //  // title: ""
      // },

      filtreData: [],
      filterPersonel: [],
      tempPersonel: {},
      employees: [],
      DailyScheduling: {
        CompanyUid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
        clientID: "",
        shiftTimeID: "",
        DepartmentID: "",
        DesignationID: "",
        employeID: "",
        startDate: "",
        Day: "",
        Hours: "",
        Mounth: "",
        Year: "",
        endDate: "",
        startTime: "",
        endTime: "",
        breakTime: "",
        isGunu: "",
      },
    };
  },
  components: {
    Select2,
    DatePicker,
    Multiselect,
    datetimevuejs,
  },
  computed: {
    ...mapGetters([
      "Getclient",
      "GetShiftTable",
      "GetEmployes",
      "GetDepartment",
      "GetDailySchedule",
      "GetDesignation",
      "Getcompany",
    ]),

    getClientData() {
      let veri = this.Getclient.map((t) => {
        return { text: t.ClientName, id: t.id };
      });
      veri.unshift({ text: "All Client", id: "0" });
      return veri;
    },
    getEmployeeData() {
      // return this.$store.getters.GetEmployes.map((t) => {
      //   return { text: t.FirstName + " " + t.LastName, id: t.id };
      // });

      let veri = this.GetEmployes.map((t) => {
        return { text: t.FirstName + " " + t.LastName, id: t.id };
      });
      veri.unshift({ text: "All Employee", id: "0" });
      return veri;
    },
    getDepartmentData() {
      let arr = [];
      this.GetDepartment.forEach((e) => {
        arr.push(e.DepartmentName);
      });
      return [...new Set(arr)];
    },
    getShiftTableData() {
      let arr = [];
      this.GetShiftTable.forEach((e) => {
        arr.push(e.ShiftName);
      });
      return [...new Set(arr)];
    },

    compudetDesignation() {
      return this.GetDesignation.filter(
        (t) => t.DepartmentID == this.DailyScheduling.DepartmentID
      );
    },
  },
  methods: {
    ...mapActions([
      "AddData",
      "EditData",
      "DeleteData",
      "getClients",
      "fetchdata",
      "getSch",
      "sorgu",
      "Tanimlamalar",
      "isTablosu",
      "MusaitPersonel",
      "haftaGetir",
      "sorguTable",
      "sorguRef",
      "sorguDoc",
    ]),

    async addShift() {
      //TOPLU PERSONEL EKLEMESİ
      this.DailyScheduling = {};

      //TOPLU EKLEME OLDUĞUNU BELİRTİLİYORUZ
      this.allEmployee = true;

      //DEPARTMANLARI GETİRİYORUZ

      this.tempDepartment = [...this.GetDepartment]


      //FİLTRELENMİŞ PERSONELLERİ TEMİZLİYORUZ
      this.filterPersonel=[]


      //FİRMA VARDİYALARINI SIFIRLAMAMIZ GEREKİYOR
      this.tempShiftsTime=[]


    },

    async clientChange(e) {
      this.tempShiftsTime = await this.sorguTable({
        tablo: "ShiftTable",

        kosul: [{ query: ["clientID", "==", e] }],
      });

      this.tempShiftsTime = this.tempShiftsTime.map((t) => {
        return {
          ShiftName: t.ShiftName,
          StartTime: moment(t.StartTime.toDate()).format("HH:mm"),
          EndTime: moment(t.EndTime.toDate()).format("HH:mm"),
          id: t.id,
        };
      });
    },
    departmentChange(e) {
      console.log(
        "DEPARTMENT",
        this.tempDesignations.filter((t) => t["DepartmentID"] == e)
      );

      this.sorguTable({
        tablo: "Designation",

        kosul: [{ query: ["DepartmentID", "==", e] }],
      }).then((t) => {
        this.tempDesignations = t;
      });

      this.sorgu({
        tablo: "Designation",
        guncelle: "SetTempVeriDep",
        kosul: [{ query: ["DepartmentID", "==", e] }],
      });
    },
    async personelGorev(value) {
      //TOPLU EKLEME OLMADIĞINI BELİRTİLİYORUZ
      this.allEmployee = false;

      console.log("PERSONEL GÖREV1", value);
      this.tempDesignations = [];
      this.tempDepartment = [];

      try {
        value["DepartmentID"].forEach(async (t) => {
          this.tempDepartment.push(await this.sorguDoc(t));
        });
      } catch (error) {}

      try {
        value["DesignationID"].forEach(async (t) => {
          this.tempDesignations.push(await this.sorguDoc(t));
        });
      } catch (error) {}

      this.DailyScheduling = value;

      this.filterPersonel = [
        {
          name: value["FirstName"] + " " + value["LastName"],
          id: value.id,
          $isDisabled: true,
        },
      ];
      this.DailyScheduling.EmployeeID = this.filterPersonel;

      // console.log("PERSONEL GÖREV2", value);
    },
    itemGunler(value) {
      console.log("Gunler", value);
      this.DailyScheduling = value;
    },

    async editScheduling(veri) {
      console.log("DÜZENLENEN VERİ ÜST", veri);
      this.tempDesignations = [];
      this.tempDepartment = [];

      try {
        //DÜZENLEME TARİHLERİNİ ALIYORUZ
        veri["mesai"]["endDate"] = moment(
          veri["mesai"]["startDate"].toDate().setDate(veri["mesai"]["Day"])
        ).format("YYYY-MM-DD");
        veri["mesai"]["startDate"] = moment(
          veri["mesai"]["startDate"].toDate().setDate(veri["mesai"]["Day"])
        ).format("YYYY-MM-DD");
      } catch (error) {}

      await this.sorgu({
        tablo: "ShiftTable",
        guncelle: "SetTempVeri",
        kosul: [{ query: ["ClientId", "==", veri.mesai.clientID] }],
      });

      //DEĞİŞKENLER ATILIYOR
      this.DailyScheduling = veri.mesai;

      //PERSONEL BİLGİSİ GETİRİLİYOR
      var personel = await this.sorguDoc(
        await this.sorguRef({
          tablo: "Employee",
          id: this.DailyScheduling.EmployeeID,
        })
      );

      //SEÇİLİ PERSONEL GETİRİLİYOR
      this.filterPersonel = [
        {
          name: personel["FirstName"] + " " + personel["LastName"],
          id: this.DailyScheduling.EmployeeID,
          $isDisabled: true,
        },
      ];
      this.DailyScheduling.EmployeeID = this.filterPersonel;

      //VARDİYA OBJECTDEN ID OLARAK AYARLANIYOR
      this.DailyScheduling.shiftTime = veri.mesai["shiftTime"].id;
      this.tempShiftsTime = await this.sorguTable({
        tablo: "ShiftTable",
        kosul: [{ query: ["clientID", "==", this.DailyScheduling.clientID] }],
      }).then((z) => {
        return z.map((t) => {
          return {
            ShiftName: t.ShiftName,
            StartTime: moment(t.StartTime.toDate()).format("HH:mm"),
            EndTime: moment(t.EndTime.toDate()).format("HH:mm"),
            id: t.id,
          };
        });
      });

      console.log("DÜZENLENEN VERİ ALT", veri);
    },

    searchBtn() {
      if (this.searchDailyScheduling.startDate) {
        this.haftaGetir({
          tarih: new Date(this.searchDailyScheduling.startDate),
        });
      } else {
        this.haftaGetir({ tarih: new Date() });
      }

      this.isTablosu({ sorgu: this.searchDailyScheduling });
    },
    resetBtn() {
      this.searchDailyScheduling = {};
      this.haftaGetir({ tarih: new Date() });
      this.isTablosu();
    },
    async PersonelData() {
      if (
        this.DailyScheduling.startDate &&
        this.DailyScheduling.endDate &&
        this.DailyScheduling.DepartmentID &&
        this.DailyScheduling.DesignationID &&
        this.allEmployee
      )
        this.filterPersonel = await this.MusaitPersonel(this.DailyScheduling);

      //console.log("ZAMAN DEĞİŞTİ",this.filterPersonel)
    },

    async Edit() {
      console.log("EDİT", this.DailyScheduling);
      var tempTarih = new Date(this.DailyScheduling.startDate);
      this.DailyScheduling.Year = tempTarih.getFullYear();
      this.DailyScheduling.Month = tempTarih.getMonth() + 1;
      this.DailyScheduling.Day = tempTarih.getDate();

      //PERSONEL ID EKLENİYOR
      this.DailyScheduling.EmployeeID = this.DailyScheduling.EmployeeID[0].id;

      //VARDİYA TABLOSU ÇAĞRILIYOR
      this.DailyScheduling.shiftTime = await this.sorguDoc(
        await this.sorguRef({
          tablo: "ShiftTable",
          id: this.DailyScheduling.shiftTime,
        })
      );

      //TARİH DÖNÜŞÜMÜ YAPILIYOR
      this.DailyScheduling.startDate = new Date(this.DailyScheduling.startDate);
      this.DailyScheduling.endDate = new Date(this.DailyScheduling.endDate);

      await this.EditData({
        db: "DailyScheduling",
        data: this.DailyScheduling,
        id: this.DailyScheduling.id,
      });
      $(".close").click();

      // //TABLO GÜNCELLENİYOR
      const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;

      this.sorgu({
        tablo: "DailyScheduling",
        guncelle: "SetDailyScheduling",
        kosul: [{ query: ["CompanyUid", "==", uid] }],
      });

      //MESAİ TABLOSU GÜNCELLENİYOR
      await this.Tanimlamalar();

      setTimeout(() => {
        this.isTablosu();
      }, 1000);
    },

    async SendData() {
      //COMPANY UID EKLENİYOR
      this.DailyScheduling.CompanyUid = JSON.parse(
        localStorage.getItem("storedData")
      ).CompanyUid;

      var tempTarih = new Date(this.DailyScheduling.startDate);
      this.DailyScheduling.Year = tempTarih.getFullYear();
      this.DailyScheduling.Month = tempTarih.getMonth() + 1;
      this.DailyScheduling.Day = tempTarih.getDate();

      //TARİH İŞ GÜNÜ SAYISI EKLENİYOR
      var baslangicTarih = this.DailyScheduling.startDate;
      var bitisTarih = this.DailyScheduling.endDate;
      var date1 = new Date(baslangicTarih);
      var date2 = new Date(bitisTarih);
      var zamanFark = Math.abs(date2.getTime() - date1.getTime());
      var gunFark = Math.ceil(zamanFark / (1000 * 3600 * 24));
      this.DailyScheduling.isGunu = gunFark + 1;
      console.log("Tarih", gunFark);

      for (let item of this.DailyScheduling.EmployeeID) {
        // console.log("tempEmploye ic", item);

        var tempDailyScheduling = { ...this.DailyScheduling };
        //BAŞLANGIÇ TARİH BİR SONRAKİ PERSONEL İÇİN BAŞA ALINIYOR
        date1 = new Date(baslangicTarih);

        //TARİH DÖNÜŞÜMÜ YAPILIYOR
        tempDailyScheduling.startDate = new Date(
          this.DailyScheduling.startDate
        );
        tempDailyScheduling.endDate = new Date(this.DailyScheduling.endDate);

        //PERSONEL BİLGİSİ DEĞİŞTİRİLİYOR
        tempDailyScheduling.EmployeeID = item.id;

        //VARDİYA TABLOSU ÇAĞRILIYOR
        tempDailyScheduling.shiftTime = await this.sorguDoc(
          await this.sorguRef({
            tablo: "ShiftTable",
            id: this.DailyScheduling.shiftTime,
          })
        );

        for (let n = 0; n < this.DailyScheduling.isGunu; n++) {
          // tempDailyScheduling = { ...tempDailyScheduling }

          //  console.log("GÜNLER", date1.getDate());
          tempDailyScheduling.Day = date1.getDate();
          tempDailyScheduling.Month = date1.getMonth() + 1;
          tempDailyScheduling.Year = date1.getFullYear();

          if (!this.DailyScheduling.checked) {
            if (date1.getDay() != 0 && date1.getDay() != 6) {
              this.AddData({
                db: "DailyScheduling",
                veri: tempDailyScheduling,
              }).then(async (p) => {
                $(".close").click();
              });
            }
          }

          // console.log("İŞ GÜNLERİ", tempDailyScheduling);
          else {
            this.AddData({
              db: "DailyScheduling",
              veri: tempDailyScheduling,
            }).then(async (p) => {
              $(".close").click();
            });
          }

          date1.setDate(date1.getDate() + 1);

          tempDailyScheduling.startDate = date1;
        }
      }
      const uid = JSON.parse(
                localStorage.getItem("storedData")
              ).CompanyUid;
              this.sorgu({
                tablo: "DailyScheduling",
                guncelle: "SetDailyScheduling",
                kosul: [{ query: ["CompanyUid", "==", uid] }],
              });

              //MESAİ TABLOSU GÜNCELLENİYOR
              await this.Tanimlamalar();
              setTimeout(() => {
                this.isTablosu();
              }, 1000);
    },
    delSchedule() {
      this.DeleteData({
        db: "DailyScheduling",
        mt: "DeleteSch",
        data: this.DailyScheduling,
      }).then(async () => {
        const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
        //MESAİ TABLOSU GÜNCELLENİYOR
        await this.Tanimlamalar();
        await this.isTablosu();

        $(".close").click();
      });
      console.log("SİLİNEN", this.DeleteData);
    },
    DepartmanGetir(veri) {
      return this.$store.getters.GetDepartment.find((t) => t.id == veri)
        .DepartmentName;
      console.log(
        this.$store.getters.GetDepartment.find((t) => t.id == veri)
          .DepartmentName
      );
    },
    AtamaGetir(g) {
      try {
        return this.$store.getters.GetDesignation.find((m) => m.id == g)
          .DesignationName;
      } catch (error) {
        return g;
      }
    },

    FirmaGetir(t) {
      //  console.log("FİRMA BİLGİSİ İSTENİYOR",this.$store.getters.Getclient)
      try {
        return this.$store.getters.Getclient.find((a) => a.id == t).ClientName;
      } catch (error) {
        return t;
      }
    },
    PersonelGetir(t) {
      //  console.log("FİRMA BİLGİSİ İSTENİYOR",this.$store.getters.Getclient)
      try {
        var data = this.$store.getters.GetEmployes.find((a) => a.id == t);

        return data.FirstName + " " + data.LastName;
      } catch (error) {
        return t;
      }
    },

    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },
  },

  mounted() {
    //this.getSch();
    // this.sorgu({ tablo: "Client", guncelle: "SetClient" });
    //İLK AÇILIŞTA TEMPVERİYİ SIFIRLIYORUZ
    this.$store.commit("SetTempVeri", []);
    this.$store.commit("SetTempVeri", []);
  },
  watch: {},
  name: "shiftscheduling",
};
</Script>

<style scoped>
.input-container input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: 0.75rem;
  position: relative;
  width: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.personelAdi {
  border: ridge;
  height: 44px;
}

.mx-input {
  height: 50px;
}
</style>