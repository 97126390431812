<template>
 <!-- Page Header -->
          <div class="page-header">
            <div class="row">
              <div class="col">
                <h3 class="page-title">Client Report</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/index">Dashboard</router-link></li>
                  <li class="breadcrumb-item active">Client Report</li>
                </ul>
              </div>
              <div class="col-auto">
                
          
                <button class="btn btn-primary" @click="generatePdf">PDF</button> &nbsp;
                <!-- <button   class="btn btn-success" @click="generateExcel">EXCEL</button> -->
              </div>
            </div>
          </div>
          <!-- /Page Header -->
</template>

 

 <script>
 import html2pdf from 'html2pdf.js';
 import jsPDF from 'jspdf' 
 import { jsontoexcel } from "vue-table-to-excel";
import autoTable, { Column } from 'jspdf-autotable'
import { mapActions,mapGetters } from 'vuex';
/* eslint-disable */
 export default {
  data()
  {
    return{
      json_data: [
            {
                'name': 'Tony Peña',
                'city': 'New York',
                'country': 'United States',
                'birthdate': '1978-03-15',
                'phone': {
                    'mobile': '1-541-754-3010',
                    'landline': '(541) 754-3010'
                }
            },
            {
                'name': 'Thessaloniki',
                'city': 'Athens',
                'country': 'Greece',
                'birthdate': '1987-11-23',
                'phone': {
                    'mobile': '+1 855 275 5071',
                    'landline': '(2741) 2621-244'
                }
            }
        ],
      json: {
        datas: [
          { email: "000@gmail.com",name: "Tom", phone: "+86 01012"  },
          { email: "000@gmail.com" ,name: "Jack", phone: "+86 01012" },
          { email: "000@gmail.com",name: "Alice", phone: "+86 01012"  }
        ],
        head: ["email", "name", "phone"],
        fileName: "download.csv"
      },
     
    

    };
  },
  components:{
     
     
  },
  computed:{
     ...mapGetters(["GetCompany","Getclient"]),
GetComp(){
return this.GetCompany
},
GetClientData(){
 
return this.Getclient;
} 
  },
 
  methods:{
    async generatePdf(){
      const element = document.getElementById('clientreports');
      html2pdf().from(element).save('clientreports.pdf');
  //   const logo = this.GetComp[0].Picture 
  //   var imgLogo = new Image()
  //   imgLogo.src = logo        
  //   var doc = new jsPDF('p', 'pt');
  //   doc.setFontSize(12);
  //   doc.setTextColor(0);
  //   doc.addImage(imgLogo, 'PNG',   450, 5,100,80)
  //   const date = new Date();
  //   let day = date.getDate();
  //   let month = date.getMonth() + 1;
  //   let year = date.getFullYear();
  //   let tarih = `${day}.${month}.${year}`;  
  //   doc.text( tarih,40,25)
  //   doc.text('Client List Report', 240, 50);   
  //   var data =await this.getData(this.GetClientData.length);
  //   // data.sort(function (a, b) {
  //   //     return parseFloat(b.expenses) - parseFloat(a.expenses);
  //   // });
  //   doc.autoTable(this.getColumns(), data, {
  //     columnStyles: {
  //   0: {cellWidth: 21},
  //   1: {cellWidth: 150},
  //   2: {cellWidth: 120},
  //   3: {cellWidth: 120},
  //   4: {cellWidth: 104},
    
  // },
  //       theme: 'grid',
  //       startY: 90,
        
  //       drawRow: function (row, data) {
          
  //           doc.setFontSize(10);
  //           if (row.index === 0) {
  //               doc.setTextColor(199, 83, 0);
                
  //           }
	// 				// data fazlaysa sayfa ekle
  //           // if (row.index % 5 === 0) {
  //           //     var posY = row.y + row.height * 6 + data.settings.margin.bottom;
  //           //     if (posY > doc.internal.pageSize.height) {
  //           //         data.addPage();
  //           //     }
  //           // }
  //       },
        
  //   });
  //    doc.save('ClientListReport.pdf');


},

  async getData(rowCount) {
   console.log("row",rowCount)
   console.log(this.Getclient)
    var data = [];
    for (var j = 0; j <= rowCount-1; j++) {
      
     // await Promise.all(  
        data.push({
            id: j+1,
            name: this.Getclient[j].ClientName,
            
              contactPerson: this.Getclient[j].FirstName+" "+this.Getclient[j].LastName,
              email: this.Getclient[j].Email,
              mobile:this.Getclient[j].Phone,
            
        })
       // );

      
    }
    
    return data;
},

 
getColumns() {
    return [
        {title: "No  ", dataKey: "id"},
        {title: "Name", dataKey: "name"},
        {title: "Contact Person  ", dataKey: "contactPerson"},
        {title: "Email  ", dataKey: "email"},
        {title: "Mobile  ", dataKey: "mobile"},
       
    ];
},
        },
     
        generateExcel() {
      
          
          const datas=this.json.datas;
          const head=this.json.head;
          const fileName=this.json.fileName;
          
      jsontoexcel.getXlsx(datas, head, fileName);
    }
  }
  
 
 </script>