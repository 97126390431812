<template>
  <!-- Flag -->
  <li class="nav-item dropdown has-arrow flag-nav">
    <a v-if="lang=='de'" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button">
      <img src="../assets/img/flags/de.png" alt="" data-bs-toggle="dropdown" height="20"> <span class="me-1">{{
      $t("German") }}</span>
    </a>
    <a v-if="lang=='en'" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button">
      <img src="../assets/img/flags/us.png" alt="" data-bs-toggle="dropdown" height="20"> <span class="me-1">{{
      $t("English") }}</span>
    </a>
    <a v-if="lang=='tr'" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button">
      <img src="../assets/img/flags/tr.png" alt="" data-bs-toggle="dropdown" height="20"> <span class="me-1">{{
      $t("Turkish") }}</span>
    </a>
    <div class="dropdown-menu dropdown-menu-right">
      <a href="javascript:void(0);" @click="handleChange('en')" class="dropdown-item">
        <img src="../assets/img/flags/us.png" alt="" height="16"> {{ $t("English") }}
      </a>
      <!-- <a href="javascript:void(0);" class="dropdown-item">
                <img src="../assets/img/flags/fr.png" alt="" height="16"> French
              </a>
              <a href="javascript:void(0);" class="dropdown-item">
                <img src="../assets/img/flags/es.png" alt="" height="16"> Spanish
              </a> -->
      <a href="javascript:void(0);" @click="handleChange('de')" class="dropdown-item">
        <img src="../assets/img/flags/de.png" alt="" height="16"> {{ $t("German") }}
      </a>
      <a href="javascript:void(0);" @click="handleChange('tr')" class="dropdown-item">
        <img src="../assets/img/flags/tr.png" alt="" height="16"> {{ $t("Turkish") }}
      </a>
    </div>
  </li>
  <!-- /Flag -->
</template>
<script>
export default {

  methods: {
    handleChange(t) {
      localStorage.setItem('lang', t);
      window.location.reload()
      // console.log("LANGUAGE", t)
    }
  },
  data() {
    const lang = localStorage.getItem("lang") || "de"
    return {
      lang: lang
    }
  },

}
</script>

