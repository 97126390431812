<template>
  <!-- Add Client Modal -->
  <div>
    <div id="add_client" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{$t("Add")}} {{$t("Client")}}</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="SendData">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">{{$t("Company Name")}}</label>
                    <input
                      class="form-control"
                      v-model="employee.ClientName"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">{{$t("Job Title")}}</label>
                    <!-- <input
                      class="form-control"
                      v-model="employee.JobTitle"
                      type="text"
                    /> --> 
                    <multiselect v-model="employee.JobTitle" :options="$store.getters.GetDesignation.map(t=>{return {'name':t.DesignationName,'id':t.id}})" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" @select="designationSelect" placeholder="Select please" label="name" track-by="name" :preselect-first="true">
    <template slot="selection"  slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length>0 && !isOpen">{{ values.length }} options selected</span></template>
  </multiselect>
 
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >{{$t("First Name")}} <span class="text-danger">*</span></label
                    >
                    <input
                      class="form-control"
                      v-model="employee.FirstName"
                      type="text"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">{{$t("Last Name")}}</label>
                    <input
                      class="form-control"
                      v-model="employee.LastName"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >{{$t("Email")}} <span class="text-danger">*</span></label
                    >
                    <input
                      class="form-control floating"
                      v-model="employee.Email"
                      type="email"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">{{$t("Phone")}} </label>
                    <input
                      class="form-control"
                      v-model="employee.Phone"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="col-form-label">{{$t("Adress")}} </label>
                    <input
                      class="form-control"
                      v-model="employee.Adress"
                      type="textarea"
                    />
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="col-form-label">{{$t("Status")}}</label>
                    <Select2 v-model="employee.Status"  :options="addstatus" />
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button class="btn btn-primary submit-btn">{{$t("Submit")}}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Client Modal -->

    <!-- Edit Client Modal -->
    <div id="edit_client" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{$t("Edit")}} {{$t("Client")}}</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="EditDatas">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">{{$t("Company Name")}}</label>
                    <input
                      class="form-control"
                      type="text"
                      name="ClientName"
                      v-model="employee.ClientName"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">{{$t("Job Title")}}</label>
                   
                
                    <multiselect v-model="employee.JobTitle" :options="$store.getters.GetDesignation.map(t=>{return {'name':t.DesignationName,'id':t.id}})" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" @select="designationSelect" placeholder="Select please" label="name" track-by="name" :preselect-first="true">
    <template slot="selection"  slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length>0 && !isOpen">{{ values.length }} options selected</span></template>
  </multiselect>


                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >{{$t("First Name")}} <span class="text-danger">*</span></label
                    >
                    <input
                      class="form-control"
                      name="FirstName"
                      :v-model="employee.FirstName"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">{{$t("Last Name")}}</label>
                    <input
                      class="form-control"
                      name="LastName"
                      v-model="employee.LastName"
                      type="text"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >{{$t("Email")}} <span class="text-danger">*</span></label
                    >
                    <input
                      class="form-control floating"
                      name="Email"
                      v-model="employee.Email"
                      type="email"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">{{$t("Phone")}} </label>
                    <input
                      class="form-control"
                      name="Phone"
                      v-model="employee.Phone"
                      type="text"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="col-form-label">{{$t("Adress")}} </label>
                    <input
                      class="form-control"
                      v-model="employee.Adress"
                      type="textarea"
                    />
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="col-form-label">{{$t("Status")}}</label>
                    <Select2
                      name="Status"
                      v-model="employee.Status"
                      :options="addstatus"
                    />
                  </div>
                </div>
              </div>

              <div class="submit-section">
                <button class="btn btn-primary submit-btn">{{$t("Save")}}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Client Modal -->

    <!-- Delete Client Modal -->
    <div class="modal custom-modal fade" id="delete_client" role="dialog">
    
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>{{$t("Delete")}} {{$t("Client")}}</h3>
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"></button>
              <p>{{$t("Are you sure want to delete")}}?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a class="btn btn-primary continue-btn" @click.prevent="deleteVeri">{{$t("Delete")}} </a>
                </div>
                <div class="col-6">
                  <a
                     
                    data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn"
                    >{{$t("Cancel")}}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Client Modal -->
</template>
<script>
/* eslint-disable */
import "vue2-datepicker/index.css";
import Select2 from "v-select2-component";
import { mapActions, mapGetters } from "vuex";
import Multiselect from 'vue-multiselect'
const currentDate = new Date();
const currentDate1 = new Date();
export default {
  name: "clientslistData",
  props: ["datas"],
  components: {
    Select2,
    Multiselect,
  },
  data() {
    return {
      designation: {
        CompanyUid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
        DepartmentID: "",
        DesignationName: ""
      },
      name: "select",
      compid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
      
      addstatus: ["Active", "Passive"],
      startdate: currentDate,
      enddate: currentDate1,
      veri:{},
      employee: {
        ClientName: "",
        JobTitle: [],
        LastName: "",
        FirstName: "",
        Status: true,
        Adress: "",
        Email: "",
        Phone: "",
        CompanyUid: JSON.parse(localStorage.getItem("storedData")).CompanyUid,
      },
    };
  },
  
  
  methods: {
    ...mapActions(["AddData", "EditData", "DeleteData", "fetchdata","getClients","sorgu"]),
    SendData() { 
      this.AddData({ db: "Client", veri: this.employee }).then(p => {
       $(".close").click();
       this.employee={}
       const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
     
       this.sorgu({ tablo: "Client", guncelle: "SetClient", kosul: [{ query: ["CompanyUid", "==", uid] }] })
       this.$toast.open({
                message: this.$t('Kunde Erfolgreich Registriert'),
                type: 'info',
                
              
              
            });
      });
   
 
     
    },
    EditDatas(e) {
     
      this.EditData({ db: "Client", data: this.employee, id: this.datas.id }).then(t => {
        $(".close").click();
        this.employee={}
        const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
        this.sorgu({ tablo: "Client", guncelle: "SetClient", kosul: [{ query: ["CompanyUid", "==", uid] }] })
        this.$toast.open({
                message: this.$t('Kunde Erfolgreich Arrangiert'),
                type: 'warning',
                
              
              
            });
      })
     


    },
 
    deleteVeri(){
        
        this.DeleteData({ db: "Client", mt: "DeleteData", data: this.datas}).then(p => {
        $(".close").click()
        const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
        this.sorgu({ tablo: "Client", guncelle: "SetClient", kosul: [{ query: ["CompanyUid", "==", uid] }] })
        this.$toast.open({
                message: this.$t('Kunde Erfolgreich Gelöscht'),
                type: 'danger',
                
              
              
            });
      })
    },


  },
  computed: {},
  watch: {
      datas(value) { 
this.employee = value
}
  },
  mounted() {
    console.log(this.compid);
  },
};
</script>