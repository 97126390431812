<template>
<div class="card mb-0">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="profile-view">
                    <div class="profile-img-wrap">
                      <div class="profile-img">
                        <a href="">
                          <img src="../../../../assets/img/profiles/client.jpg" alt="">
                        </a>
                      </div>
                    </div>
                    <div class="profile-basic">
                      <div class="row">
                        <div class="col-md-5">
                          <div class="profile-info-left">
                            <h3 class="user-name m-t-0">{{data.ClientName}}</h3>
                            <h5 class="company-role m-t-0 mb-0"> {{$t("Represant Name")}} : {{data.FirstName +" "+data.LastName}}</h5>
                            <!-- <small class="text-muted"></small> -->
                            <div class="staff-id">{{$t("Job Title")}} : {{data.JobTitle}}</div>
                            <!-- <div class="staff-msg"><router-link to="/chat" class="btn btn-custom">Send Message</router-link></div> -->
                          </div>
                        </div>
                        <div class="col-md-7">
                          <ul class="personal-info">
                            <li>
                              <span class="title">{{$t("Phone")}}:</span>
                              <span class="text"><a href="">{{data.Phone}}</a></span>
                            </li>
                            <li>
                              <span class="title">{{$t("Email")}}:</span>
                              <span class="text"><a href="mailto:${data.Email}">{{data.Email}}</a></span>
                            </li>
                 
                            <li>
                              <span class="title">{{$t("Address")}}:</span>
                              <span class="text">{{data.Adress}}</span>
                            </li>
                            <li>
                              <span class="title"> {{$t("Status")}} :</span>
                              <span class="text">{{data.Status}}</span>
                            </li>
                            
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
</template>

<script>
export default {
  props: ["data"],
  mounted() {
    console.log(this.data);
  }

}
</script>
