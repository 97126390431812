<template>
  <div class="holidays">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
      <!-- Page Wrapper -->
      <div class="page-wrapper">
        <!-- Page Content -->
        <div class="content container-fluid">
          <headerholidays  @editHolidays="editHoliday" />

          <holidaycontent @Holday="holidays" @deleteHolidays="delHolidays" @editHolidays="editHoliday" />
        </div>
        <!-- /Page Content -->

        <modalholiday :editHolidays="editHolidays" :deleteHolidays="deleteHolidays" :holidays="holidays" />
      </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      holidays: [],
      deleteHolidays: {},
      editHolidays: {}
    };
  },
  components: {},
  methods: {

    ...mapActions(["sorgu"]),
    getHolidays(e) {
      this.holidays = e;
    
    },
    delHolidays(b) {
      this.deleteHolidays = b;
    },
    editHoliday(c) {

      this.editHolidays = c;

      var start = document.getElementById('start');
      var end = document.getElementById('end');

      start.addEventListener('change', function () {
        if (start.value)
          end.min = start.value;
      }, false);
      end.addEventLiseter('change', function () {
        if (end.value)
          start.max = end.value;
      }, false);
    }
  },
  computed: {



  },
  mounted() {
    var start = document.getElementById('starts');
var end = document.getElementById('ends');

start.addEventListener('change', function() {
    if (start.value)
        end.min = start.value;
}, false);
end.addEventLiseter('change', function() {
    if (end.value)
        start.max = end.value;
}, false);
    //   this.holidaysGetir()
    const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
    this.sorgu({ tablo: "Holidays", guncelle: "SetHolidays", kosul: [{ query: ["CompanyUid", "==", uid] }] })
    // setTimeout(() => {
    //   // this.GetTabloDinle[0].veri()
    //   delete this.GetTabloDinle[0]
    // }, 20000);

  },
  destroyed() {
    // console.log("TABLOGETİR", this.GetTabloDinle)
    // // this.GetTabloDinle[0].veri()
    // //delete this.GetTabloDinle[0]
    // console.log("TABLOSONU", this.GetTabloDinle)
  },
  name: "holidays",
};
</script>
